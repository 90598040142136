import { pxToRem } from 'utils/pxToRem'
import styled from 'styled-components/macro'
import { BrandColors } from 'style/BrandColors'
import { MenuPlacement, PopOver } from 'components/PopOver'
import { MoreOptions } from 'components/Icons'
import { Menu, MenuItemType } from 'components/Menu'

interface ActionMenuProps {
  MenuButton?: React.ReactElement<HTMLButtonElement>
  /** do not render the menu */
  hide?: boolean
  menuItems: MenuItemType[]
  menuPlacement?: MenuPlacement
  onMenuItemClick: (value: string) => void
}

const ToggleButton = styled.div<{ isOpen?: boolean }>`
  background: ${({ isOpen }) =>
    isOpen ? BrandColors.mountainMeadow : 'transparent'};
  color: ${({ isOpen }) =>
    isOpen ? BrandColors.white : BrandColors.spanishViolet};
  fill: currentcolor;
  border-radius: 50%;
  height: ${pxToRem(32)};
  width: ${pxToRem(32)};
  cursor: pointer;
`

export interface ActionMenuButtonProps {
  isOpen?: boolean
}
export const ActionMenuButton = (props: ActionMenuButtonProps) => (
  <ToggleButton aria-label={props.isOpen ? 'close' : 'open'} {...props}>
    <MoreOptions />
  </ToggleButton>
)

/**
 * A button with a PopOver menu which displays a set of options.
 *
 * Typically used to add a menu of options to list items.
 *
 * Can also be used to provide a menu in an area with limited space.
 */
export const ActionMenu = ({
  MenuButton,
  hide,
  menuItems,
  menuPlacement,
  onMenuItemClick,
}: ActionMenuProps) => {
  if (hide) return null

  return (
    <PopOver
      menuPlacement={menuPlacement}
      trigger={MenuButton || <ActionMenuButton />}
    >
      <Menu menuItems={menuItems} onMenuItemClick={onMenuItemClick} />
    </PopOver>
  )
}
