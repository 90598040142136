import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M15.0069333,11.1796037 C15.0069333,11.1796037 14.4344889,11.686311 13.8901926,11.1625305 C13.3515259,10.6445427 13.9595259,10.1018598 13.9595259,10.1018598 L16.3079704,7.68539634 C17.0078222,6.96496951 17.8878222,6.42015244 18.7218963,6.19057927 C19.8721185,5.87381098 20.9393778,6.14667683 21.7287111,6.95856707 C22.0178963,7.25612805 22.0178963,7.73875 21.7287111,8.03661585 C21.4392296,8.33417683 20.9704889,8.33417683 20.6810074,8.03631098 C20.2644148,7.60765244 19.7482667,7.48631098 19.1047111,7.66314024 C18.5230815,7.82320122 17.8691556,8.23448171 17.3553778,8.76314024 L15.0069333,11.1796037 Z" />
    <path d="M23.6814815,14.092439 L19.3564444,18.5427439 C19.0154074,18.460122 16.7736296,17.8454878 14.4491852,15.4540244 C12.4548148,13.4018902 11.7911111,11.1247561 11.6014815,10.269878 L15.7842963,5.96621951 C16.8456296,4.87445122 18.2391111,4.32841463 19.6331852,4.32841463 C21.0266667,4.32841463 22.4204444,4.87445122 23.4811852,5.96591463 L23.6814815,6.1720122 C24.7045926,7.2247561 25.2681481,8.63115854 25.2681481,10.1320732 C25.2681481,11.6329878 24.7045926,13.0393902 23.6814815,14.092439 L23.6814815,14.092439 Z M13.9700741,24.0854268 C11.8477037,26.2683537 8.39466667,26.2689634 6.2722963,24.0854268 L6.0722963,23.8796341 C5.04918519,22.8268902 4.48592593,21.4204878 4.48592593,19.9195732 C4.48592593,18.4186585 5.04918519,17.0122561 6.0722963,15.9595122 L10.3964444,11.5098171 C10.7952593,12.7793293 11.6435556,14.7232317 13.4017778,16.5317683 C15.2767407,18.4613415 17.1031111,19.3613415 18.173037,19.7607317 L13.9700741,24.0854268 Z M24.7294815,5.09426829 L24.5291852,4.88817073 C21.8296296,2.11042683 17.4364444,2.11042683 14.7368889,4.88847561 L5.02488889,14.8814634 C2.32503704,17.6595122 2.32503704,22.1796341 5.02488889,24.957378 L5.22488889,25.1631707 C6.57481481,26.5525 8.34755556,27.2467073 10.1208889,27.2467073 C11.8942222,27.2467073 13.6675556,26.5521951 15.0174815,25.1631707 L24.7288889,15.1704878 C26.0322963,13.8296341 26.7496296,12.0403049 26.7496296,10.1320732 C26.7496296,8.22384146 26.0322963,6.43481707 24.7294815,5.09426829 L24.7294815,5.09426829 Z" />
  </React.Fragment>,
  'Capsules'
)
