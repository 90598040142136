import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M21.3,12.219 C21.3,11.4354 21.9354,10.8 22.719,10.8 C23.5029,10.8 24.138,11.4354 24.138,12.219 C24.138,13.0026 23.5029,13.638 22.719,13.638 C21.9354,13.638 21.3,13.0026 21.3,12.219" />
    <path d="M25.5216,9.0645 L25.5216,23.7606 L4.4784,23.7606 L4.4784,9.0645 L10.2963,9.0645 L11.505,6.3318 C11.5425,6.2562 11.6256,6.0894 11.6925,5.9787 L18.3105,5.9787 C18.3567,6.0543 18.4173,6.1701 18.4902,6.3216 L19.7034,9.0645 L25.5216,9.0645 Z M25.5966,7.5861 L20.6661,7.5861 L19.8333,5.7036 C19.5192,5.0451 19.1973,4.5 18.4374,4.5 L11.5629,4.5 C10.7595,4.5 10.4445,5.139 10.1655,5.7054 L9.3336,7.5861 L4.4034,7.5861 C3.6294,7.5861 3,8.1405 3,8.8215 L3,24.0036 C3,24.6849 3.6294,25.2393 4.4034,25.2393 L25.5966,25.2393 C26.3703,25.2393 27,24.6849 27,24.0036 L27,8.8215 C27,8.1405 26.3703,7.5861 25.5966,7.5861 L25.5966,7.5861 Z" />
    <path d="M18.801,15.9897 C18.801,18.036 17.136,19.701 15.0897,19.701 C13.0434,19.701 11.3787,18.036 11.3787,15.9897 C11.3787,13.9434 13.0434,12.2787 15.0897,12.2787 C17.136,12.2787 18.801,13.9434 18.801,15.9897 M15.0897,10.8 C12.228,10.8 9.9,13.128 9.9,15.9897 C9.9,18.8514 12.228,21.1794 15.0897,21.1794 C17.9514,21.1794 20.2794,18.8514 20.2794,15.9897 C20.2794,13.128 17.9514,10.8 15.0897,10.8" />
  </React.Fragment>,
  'Camera'
)
