import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

const iconWidth = '3rem'

export const IconWrap = styled.div<{ hasValue?: boolean }>`
  color: ${({ hasValue }) =>
    hasValue ? BrandColors.middleRedPurple : BrandColors.graniteGray};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${iconWidth};
  fill: currentcolor;
  stroke: currentcolor;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Input = styled.input.attrs({
  type: 'search',
})`
  background: inherit;
  border: none;
  color: inherit;
  cursor: inherit;
  font-family: inherit;
  font-size: 1rem;
  height: 100%;
  letter-spacing: 0.025rem;
  outline: none;
  padding: 0 1rem 0 ${iconWidth};
  width: 100%;
  z-index: 2;
  appearance: textfield;

  &::placeholder {
    color: ${BrandColors.graniteGray};
    font-weight: normal;
    font-style: oblique;
  }
`

export const Wrap = styled.div<{
  disabled?: boolean
  hasFocus?: boolean
  isHovered?: boolean
}>`
  box-sizing: border-box;
  background: ${BrandColors.white};
  cursor: ${({ disabled, hasFocus }) =>
    disabled ? 'not-allowed' : hasFocus ? 'default' : 'pointer'};
  height: 3.2rem;
  overflow: hidden;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
  position: relative;
  font-weight: normal;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${({ hasFocus, isHovered }) =>
    `
    background: ${
      hasFocus
        ? BrandColors.white
        : isHovered
        ? BrandColors.duckEggGreen
        : BrandColors.white
    };
  `}
`
