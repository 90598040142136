import { Wrap } from './ManageLoaderStyles'
import { AvailColors, BongLoader } from 'components/FlowhubUIComponents'
import { MiniLoader } from './MiniLoader'
import { MediumLoader } from './MediumLoader'

interface ManageLoaderProps {
  color?: AvailColors
  loadingMessage?: string
  size?: number
  extraStyles?: string
}

const ManageLoader = ({
  color = 'green',
  loadingMessage = '',
  size = 24, // rems, fyi
  extraStyles = '',
}: ManageLoaderProps) => {
  return (
    <Wrap data-testid="manage-loader" extraStyles={extraStyles}>
      <BongLoader
        color={color}
        size={size}
        loadingMessage={loadingMessage}
        data-testid="bong-loader"
      />
    </Wrap>
  )
}

export { ManageLoader, MiniLoader, MediumLoader }
