import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M4.05,26.8476 C3.6357,26.8476 3.3,26.5119 3.3,26.0976 L3.3,15.15 C3.3,14.7357 3.6357,14.4 4.05,14.4 C4.4643,14.4 4.8,14.7357 4.8,15.15 L4.8,26.0976 C4.8,26.5119 4.4643,26.8476 4.05,26.8476" />
    <path d="M9.45,27.0906 C9.0357,27.0906 8.7,26.7549 8.7,26.3406 L8.7,20.55 C8.7,20.1357 9.0357,19.8 9.45,19.8 C9.8643,19.8 10.2,20.1357 10.2,20.55 L10.2,26.3406 C10.2,26.7549 9.8643,27.0906 9.45,27.0906" />
    <path d="M15.15,26.8596 C14.7357,26.8596 14.4,26.5239 14.4,26.1096 L14.4,17.85 C14.4,17.4357 14.7357,17.1 15.15,17.1 C15.5643,17.1 15.9,17.4357 15.9,17.85 L15.9,26.1096 C15.9,26.5239 15.5643,26.8596 15.15,26.8596" />
    <path d="M20.85,26.8686 C20.4357,26.8686 20.1,26.5329 20.1,26.1186 L20.1,11.85 C20.1,11.436 20.4357,11.1 20.85,11.1 C21.2643,11.1 21.6,11.436 21.6,11.85 L21.6,26.1186 C21.6,26.5329 21.2643,26.8686 20.85,26.8686" />
    <path d="M26.25,27.0225 C25.8357,27.0225 25.5,26.6868 25.5,26.2725 L25.5,3.75 C25.5,3.3357 25.8357,3 26.25,3 C26.6643,3 27,3.3357 27,3.75 L27,26.2725 C27,26.6868 26.6643,27.0225 26.25,27.0225" />
  </React.Fragment>,
  'InventoryLog'
)
