import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M14.87,10.86H27V6H8.58V9.07L7.3,10.32V5.75A1.07,1.07,0,0,1,8.37,4.69H27.24A1.06,1.06,0,0,1,28.3,5.75v5.32a1.06,1.06,0,0,1-1.06,1.07H25.75V24.49a1.07,1.07,0,0,1-1.07,1.07H10.92a1.07,1.07,0,0,1-1.07-1.07V19.73l1.28-1.09v5.64H24.47V12.14h-9.6V10.86Zm5.51,4.26a.64.64,0,0,1,.61.66.63.63,0,0,1-.61.62H15.24a.64.64,0,0,1,0-1.28Zm0,4.7a.64.64,0,0,1,0,1.27H15.24a.64.64,0,0,1,0-1.27Z" />
    <path d="M14.47,8.41,16.12,7l.62.71L15.21,9a.86.86,0,0,1,0,.28v6a.9.9,0,0,1-.26.62L9.39,21.46a1.08,1.08,0,0,1-.76.31,1.15,1.15,0,0,1-.79-.32L2.2,15.81a1.09,1.09,0,0,1,0-1.55L7.8,8.65a.85.85,0,0,1,.62-.26h6.05ZM10.84,14A1.14,1.14,0,1,0,9.7,12.81,1.14,1.14,0,0,0,10.84,14Z" />
  </React.Fragment>,
  'PackageTag'
)
