import createSvgIcon from './createSvgIcon'
import styled from 'styled-components/macro'

const ExclamationIcon = createSvgIcon(
  <>
    <path
      d="M2.05886 9.34653C2.04336 9.45279 1.99242 9.55497 1.90582 9.65303C1.81993 9.75087 1.71781 9.8 1.6 9.8C1.32539 9.8 1.17259 9.64868 1.14134 9.34653L0.223619 2.00785C0.207882 1.87771 0.2 1.69388 0.2 1.457C0.2 1.08955 0.321308 0.755104 0.564648 0.452712C0.807756 0.150832 1.15293 0 1.60003 0C2.06327 0 2.41195 0.154933 2.64715 0.4648C2.88237 0.767165 3 1.09778 3 1.45703C3 1.67753 2.99261 1.86111 2.97687 2.00787L2.05886 9.34653Z"
      fill="white"
    />
    <path
      d="M0.604835 11.5953C0.867141 11.332 1.19908 11.2001 1.60006 11.2001C1.99326 11.2001 2.3252 11.3361 2.59523 11.607C2.86526 11.8781 3.00003 12.2071 3.00003 12.5944C3.00003 12.9661 2.86526 13.2953 2.59523 13.5819C2.3252 13.8609 1.99323 14.0001 1.60006 14.0001C1.20683 14.0001 0.87535 13.8648 0.604835 13.5934C0.335238 13.3227 0.200031 12.9894 0.200031 12.5944C0.200031 12.1993 0.335238 11.8665 0.604835 11.5953Z"
      fill="white"
    />
  </>,
  'Exclamation'
)

export default styled(ExclamationIcon)`
  path {
    transform: translateX(10px) translateY(5px);
  }
`
