import styled from 'styled-components/macro'

/**
 * styled-component wrapper for an input group:
 *
 * - `<Label />`
 * - `<TextInput />`
 * - `<InfoMessage />`
 */
export const InputGroup = styled.div`
  display: inline-flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;
  width: inherit;
`
