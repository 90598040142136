import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M13.9,24.14a1.17,1.17,0,1,1,1.17,1.17A1.17,1.17,0,0,1,13.9,24.14Zm9.37,0a1.18,1.18,0,1,1,1.18,1.17A1.17,1.17,0,0,1,23.27,24.14Zm-8.48-8.6,11.77-.91V8.34H9.38l.75,3.09H8.8L7.35,5.48a.64.64,0,0,1,.48-.77h0a.64.64,0,0,1,.78.47l.45,1.88H27.2a.64.64,0,0,1,.65.64v7.55a.61.61,0,0,1,0,.13.51.51,0,0,1-.07.17.86.86,0,0,1-.07.1.39.39,0,0,1-.1.08l-.1.07a.86.86,0,0,1-.23.06l-12.46,1V15.54Zm-.93,4.27H27.32a.64.64,0,0,1,.64.64.64.64,0,0,1-.64.64H12.42l1.44-1.28Z" />
    <path d="M14.83,11l1.72-1.5.64.73L15.6,11.63a.86.86,0,0,1,0,.28v6.14a.88.88,0,0,1-.26.63L9.6,24.47a1.11,1.11,0,0,1-1.58,0L2.21,18.63a1.11,1.11,0,0,1,0-1.57L8,11.27A.86.86,0,0,1,8.61,11h6.22Zm-4.9,4.54a1.17,1.17,0,1,0,1.17-1.18A1.18,1.18,0,0,0,9.93,15.56Z" />
  </React.Fragment>,
  'CartDiscount'
)
