import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M17.9312889,23.8345481 C18.557363,24.6072889 19.6290667,25.5101037 21.2181037,25.5101037 C24.0382519,25.5101037 24.7004741,24.195437 24.8542519,23.6304 C25.0512889,22.9062519 24.9920296,22.5812148 24.9075852,22.4360296 C24.8856593,22.3978074 24.8264,22.2970667 24.5781037,22.233363 C24.1712889,22.1296593 23.9967704,22.2108444 23.3247704,22.5729185 C22.9191407,22.7918815 22.4142519,23.0641778 21.6895111,23.3483259 C20.6622519,23.7504 19.0782519,23.8345481 17.9324741,23.8345481 L17.9312889,23.8345481 Z M21.2181037,26.9915852 C17.5176593,26.9915852 15.9526222,23.4967704 15.8880296,23.3480296 C15.7846222,23.1109926 15.8127704,22.8366222 15.9621037,22.6250667 C16.111437,22.4135111 16.3635852,22.2979556 16.6186963,22.313363 C17.4800296,22.3735111 19.9727704,22.4295111 21.1490667,21.9687704 C21.7899556,21.7178074 22.2509926,21.4692148 22.621363,21.2695111 C23.3398815,20.881363 23.9612148,20.5465481 24.9446222,20.7981037 C25.6471407,20.977363 26.0086222,21.3826963 26.1878815,21.6908444 C26.6338074,22.4564741 26.4560296,23.3862519 26.2835852,24.019437 C26.0942519,24.7166222 25.1683259,26.9915852 21.2181037,26.9915852 L21.2181037,26.9915852 Z" />
    <path d="M23.5802963,10.5028148 C22.372,10.5028148 22.1512593,11.037037 21.8004444,13.1425185 C21.6656296,13.952 21.5124444,14.8693333 21.193037,15.8112593 C20.6905185,17.2927407 20.0819259,18.4468148 19.58,19.2524444 C22.7776296,18.712 24.2718519,16.493037 24.9687407,14.5925926 C25.3660741,13.5087407 25.4383704,11.9016296 24.8703704,11.0891852 C24.5951111,10.6945185 24.1728889,10.5028148 23.5802963,10.5028148 M18.0448889,20.8571852 C17.7515556,20.8571852 17.4860741,20.6841481 17.3672593,20.416 C17.2493333,20.1478519 17.2997037,19.8358519 17.4961481,19.618963 C17.5121481,19.6014815 18.8558519,18.0894815 19.7903704,15.3354074 C20.070963,14.5075556 20.2072593,13.6897778 20.3391111,12.898963 C20.6419259,11.0814815 20.985037,9.02133333 23.5802963,9.02133333 C24.9465185,9.02133333 25.6967407,9.68444444 26.0851852,10.2408889 C27.012,11.5677037 26.8771852,13.6912593 26.3598519,15.1025185 C24.998963,18.8133333 22.0460741,20.8571852 18.0448889,20.8571852" />
    <path d="M14.882963,4.99674074 C14.077037,5.73748148 12.4148148,7.63911111 12.4148148,10.722963 C12.4148148,14.5357037 14.2565926,17.0115556 14.882963,17.3997037 C15.5093333,17.0112593 17.3511111,14.5354074 17.3511111,10.722963 C17.3511111,7.6402963 15.6897778,5.73837037 14.882963,4.99674074 L14.882963,4.99674074 Z M14.9665185,17.4417778 L14.9697778,17.4417778 L14.9665185,17.4417778 Z M14.882963,18.9125926 C13.9564444,18.9125926 12.9499259,17.6482963 12.3164444,16.4651852 C11.4242963,14.7991111 10.9333333,12.7597037 10.9333333,10.722963 C10.9333333,5.94340741 14.3152593,3.52977778 14.4592593,3.42933333 C14.7134815,3.25185185 15.0524444,3.25185185 15.3066667,3.42933333 C15.4506667,3.52977778 18.8325926,5.94340741 18.8325926,10.722963 C18.8325926,12.7597037 18.3413333,14.7988148 17.4494815,16.4651852 C16.816,17.6482963 15.8091852,18.9125926 14.882963,18.9125926 L14.882963,18.9125926 Z" />
    <path d="M5.47354074,22.1916148 C5.38554074,22.1916148 5.29457778,22.2061333 5.18731852,22.2333926 C4.93872593,22.2968 4.87976296,22.3981333 4.85783704,22.4360593 C4.77339259,22.5812444 4.71413333,22.9062815 4.91117037,23.629837 C5.06494815,24.1954667 5.72687407,25.5101333 8.54731852,25.5101333 C10.1360593,25.5101333 11.2080593,24.6073185 11.8341333,23.8345778 L11.8329481,23.8345778 C10.6871704,23.8345778 9.10317037,23.7504296 8.07620741,23.3483556 C7.35087407,23.0642074 6.84598519,22.7919111 6.44035556,22.5729481 C5.94554074,22.3059852 5.72094815,22.1916148 5.47354074,22.1916148 M8.54731852,26.9916148 C4.5970963,26.9916148 3.67117037,24.7163556 3.48154074,24.0191704 C3.3090963,23.3865778 3.13161481,22.4565037 3.57754074,21.6908741 C3.7568,21.3827259 4.11828148,20.9773926 4.8208,20.7981333 C5.80539259,20.5462815 6.42524444,20.8816889 7.14405926,21.2692444 C7.51442963,21.4692444 7.97487407,21.7175407 8.61635556,21.9688 C9.79502222,22.4307259 12.2853926,22.3747259 13.1464296,22.3133926 C13.4039111,22.2968 13.6536889,22.4135407 13.8033185,22.6250963 C13.9526519,22.8366519 13.9810963,23.1110222 13.8773926,23.3480593 C13.8128,23.4968 12.247763,26.9916148 8.54731852,26.9916148" />
    <path d="M6.18506667,10.5028148 C5.59247407,10.5028148 5.17025185,10.6945185 4.89499259,11.0891852 C4.32699259,11.9016296 4.39928889,13.5084444 4.79662222,14.5922963 C5.49351111,16.493037 6.98773333,18.712 10.185363,19.2524444 C9.68343704,18.4468148 9.07484444,17.2927407 8.57232593,15.8115556 C8.25291852,14.8693333 8.09973333,13.952 7.96491852,13.1425185 C7.6141037,11.037037 7.39336296,10.5028148 6.18506667,10.5028148 M11.7204741,20.8571852 C7.71928889,20.8571852 4.7664,18.8133333 3.40551111,15.1022222 C2.88817778,13.6912593 2.75336296,11.5677037 3.68017778,10.2408889 C4.06862222,9.68444444 4.81884444,9.02133333 6.18506667,9.02133333 C8.78032593,9.02133333 9.12343704,11.0814815 9.42625185,12.898963 C9.5581037,13.6897778 9.6944,14.5075556 9.97499259,15.3354074 C10.9186963,18.1155556 12.2544,19.602963 12.2680296,19.6174815 C12.465363,19.8346667 12.5163259,20.1472593 12.3981037,20.416 C12.2792889,20.6841481 12.0138074,20.8571852 11.7204741,20.8571852" />
  </React.Fragment>,
  'BulkFlower'
)
