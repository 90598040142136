import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M10.396 0.0831043L8.03285 7.3142C7.82369 7.95378 7.82369 8.64337 8.03285 9.28295L10.396 16.5216C10.4041 16.5458 10.4197 16.5669 10.4405 16.5818C10.4612 16.5967 10.4861 16.6047 10.5117 16.6047C10.5373 16.6047 10.5622 16.5967 10.5829 16.5818C10.6037 16.5669 10.6193 16.5458 10.6274 16.5216L12.9906 9.28673C13.1997 8.64714 13.1997 7.95755 12.9906 7.31797L10.6267 0.0831043C10.6185 0.0588836 10.603 0.0378366 10.5822 0.0229279C10.5614 0.00801913 10.5365 0 10.511 0C10.4854 0 10.4605 0.00801913 10.4397 0.0229279C10.4189 0.0378366 10.4034 0.0588836 10.3952 0.0831043" />
    <path d="M18.3305 9.79804L20.352 3.84954C20.3598 3.82524 20.3597 3.79911 20.3518 3.77486C20.3439 3.75061 20.3285 3.72947 20.3079 3.71445C20.2873 3.69943 20.2624 3.69128 20.2369 3.69116C20.2114 3.69105 20.1865 3.69897 20.1658 3.71381L15.0082 7.36781C14.4567 7.75779 14.0424 8.31195 13.8244 8.95127L11.8029 14.899C11.7951 14.9233 11.7952 14.9494 11.8031 14.9737C11.811 14.9979 11.8264 15.0191 11.847 15.0341C11.8676 15.0491 11.8925 15.0573 11.918 15.0574C11.9435 15.0575 11.9684 15.0496 11.9891 15.0347L17.1497 11.3815C17.7012 10.9916 18.1155 10.4374 18.3335 9.79804" />
    <path d="M14.7888 13.8447L11.3942 16.2825C11.3732 16.2975 11.3577 16.319 11.3498 16.3436C11.342 16.3683 11.3424 16.3948 11.3509 16.4192C11.3593 16.4436 11.3755 16.4646 11.3969 16.4791C11.4183 16.4936 11.4438 16.5008 11.4696 16.4996L15.6658 16.486C16.3451 16.4841 17.007 16.2703 17.5591 15.8745L20.95 13.4368C20.971 13.4217 20.9865 13.4002 20.9943 13.3756C21.0021 13.351 21.0018 13.3245 20.9933 13.3C20.9848 13.2756 20.9687 13.2546 20.9473 13.2401C20.9259 13.2256 20.9004 13.2184 20.8746 13.2196L16.6822 13.2332C16.0028 13.2351 15.341 13.4488 14.7888 13.8447Z" />
    <path d="M4.32152 13.2332L0.125379 13.2196C0.0995602 13.2184 0.0740499 13.2256 0.0526511 13.2401C0.0312523 13.2546 0.0151109 13.2756 0.00663307 13.3C-0.00184481 13.3245 -0.0022052 13.351 0.00560563 13.3756C0.0134165 13.4002 0.0289802 13.4217 0.0499769 13.4368L3.44309 15.8745C3.99523 16.2703 4.65708 16.4841 5.33644 16.486L9.53259 16.4996C9.55841 16.5008 9.58392 16.4936 9.60532 16.4791C9.62671 16.4646 9.64286 16.4436 9.65133 16.4192C9.65981 16.3948 9.66017 16.3683 9.65236 16.3436C9.64455 16.319 9.62899 16.2975 9.60799 16.2825L6.21488 13.8447C5.66273 13.4489 5.00089 13.2351 4.32152 13.2332Z" />
    <path d="M6.5791 19.4894L8.36011 18.9615C8.52248 18.9134 8.66567 18.8156 8.76954 18.6818L9.90133 17.2182C9.91695 17.1979 9.92567 17.1731 9.92623 17.1474C9.92678 17.1218 9.91916 17.0966 9.90445 17.0756C9.88973 17.0546 9.86871 17.0388 9.84442 17.0306C9.82013 17.0223 9.79385 17.022 9.76938 17.0297L7.98837 17.5576C7.826 17.6057 7.68281 17.7035 7.57894 17.8373L6.4479 19.3009C6.43273 19.3212 6.42436 19.3458 6.42396 19.3711C6.42357 19.3965 6.43118 19.4213 6.4457 19.4421C6.46023 19.4629 6.48094 19.4786 6.50488 19.4869C6.52883 19.4953 6.5548 19.4959 6.5791 19.4886" />
    <path d="M11.1221 17.2182L12.2531 18.6787C12.357 18.8125 12.5002 18.9103 12.6626 18.9585L14.4436 19.4863C14.4679 19.4935 14.4938 19.493 14.5178 19.4846C14.5417 19.4763 14.5624 19.4606 14.577 19.4398C14.5915 19.419 14.5991 19.3942 14.5987 19.3688C14.5983 19.3434 14.5899 19.3189 14.5748 19.2985L13.4437 17.838C13.3397 17.7052 13.1969 17.6082 13.0351 17.5605L11.2533 17.0297C11.2289 17.0222 11.2027 17.0227 11.1786 17.031C11.1545 17.0394 11.1336 17.0552 11.119 17.0761C11.1045 17.0971 11.0969 17.1221 11.0974 17.1477C11.098 17.1732 11.1066 17.1979 11.1221 17.2182Z" />
    <path d="M9.19188 14.899L7.17034 8.95129C6.95244 8.31192 6.53812 7.75773 5.98652 7.36784L0.828243 3.71308C0.807492 3.69824 0.782594 3.69032 0.757084 3.69043C0.731574 3.69055 0.706748 3.69869 0.686131 3.71372C0.665514 3.72874 0.650154 3.74988 0.642232 3.77413C0.63431 3.79838 0.634229 3.82451 0.641999 3.8488L2.66354 9.79806C2.88154 10.4374 3.29584 10.9915 3.84736 11.3815L9.00488 15.0348C9.02563 15.0496 9.05053 15.0575 9.07604 15.0574C9.10155 15.0573 9.12638 15.0491 9.14699 15.0341C9.16761 15.0191 9.18297 14.998 9.19089 14.9737C9.19882 14.9495 9.1989 14.9233 9.19113 14.899" />
  </React.Fragment>,
  'FlowhubLeaf'
)
