/* eslint-disable react/jsx-key */
import * as React from 'react'
import { createSvgIcon } from './createSvgIcon'

export const Key = createSvgIcon(
  [
    <path
      d="M21.7503 17.5H18.4946C18.1408 18.5008 17.4445 19.3443 16.5289 19.8815C15.6133 20.4186 14.5372 20.6147 13.491 20.4352C12.4448 20.2557 11.4956 19.7121 10.8114 18.9005C10.1272 18.0889 9.75195 17.0615 9.75195 16C9.75195 14.9385 10.1272 13.9111 10.8114 13.0995C11.4956 12.2879 12.4448 11.7443 13.491 11.5648C14.5372 11.3853 15.6133 11.5814 16.5289 12.1186C17.4445 12.6557 18.1408 13.4992 18.4946 14.5H26.2503V17.5H24.7503V20.5H21.7503V17.5ZM14.2503 17.5C14.6482 17.5 15.0297 17.342 15.311 17.0607C15.5923 16.7794 15.7503 16.3978 15.7503 16C15.7503 15.6022 15.5923 15.2207 15.311 14.9393C15.0297 14.658 14.6482 14.5 14.2503 14.5C13.8525 14.5 13.471 14.658 13.1897 14.9393C12.9084 15.2207 12.7503 15.6022 12.7503 16C12.7503 16.3978 12.9084 16.7794 13.1897 17.0607C13.471 17.342 13.8525 17.5 14.2503 17.5Z"
      transform="translate(-6, -4) scale(1.2)"
    />,
  ],
  'Key'
)

export default Key
