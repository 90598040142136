import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M15,26.75A11.75,11.75,0,1,1,26.75,15,11.75,11.75,0,0,1,15,26.75Zm0-1.5a10.25,10.25,0,0,0,0-20.5h0a10.25,10.25,0,0,0,0,20.5Z" />
    <path d="M17.3,8.5l1.7.85L12.56,21.5l-1.69-.85ZM11,15.72A2.89,2.89,0,0,1,10,15.54a2.46,2.46,0,0,1-.79-.48,2.09,2.09,0,0,1-.49-.72,2.31,2.31,0,0,1-.17-.86v-2a2.32,2.32,0,0,1,.17-.87,2.06,2.06,0,0,1,.49-.71A2.35,2.35,0,0,1,10,9.4,2.89,2.89,0,0,1,11,9.23a2.87,2.87,0,0,1,1,.17,2.4,2.4,0,0,1,.78.49,2.23,2.23,0,0,1,.5.71,2.14,2.14,0,0,1,.17.87v2a2.1,2.1,0,0,1-.17.86,2.39,2.39,0,0,1-.5.72,2.56,2.56,0,0,1-.78.48A3.12,3.12,0,0,1,11,15.72Zm.77-4.26a.88.88,0,0,0-.21-.58.76.76,0,0,0-1.13,0,.88.88,0,0,0-.21.58v2a.86.86,0,0,0,.21.58.76.76,0,0,0,1.13,0,.88.88,0,0,0,.21-.58v-2Zm9.78,7.09a2.11,2.11,0,0,1-.18.86,2,2,0,0,1-.5.72,2.19,2.19,0,0,1-.78.48,2.62,2.62,0,0,1-1,.18A3,3,0,0,1,18,20.61a2.17,2.17,0,0,1-.78-.48,1.91,1.91,0,0,1-.5-.72,2.09,2.09,0,0,1-.16-.86v-2a2.27,2.27,0,0,1,.16-.86,2,2,0,0,1,.5-.72,2.34,2.34,0,0,1,.78-.48,3,3,0,0,1,1.05-.18,2.8,2.8,0,0,1,1,.18,2.34,2.34,0,0,1,.78.48,2.15,2.15,0,0,1,.68,1.58v2Zm-3.27,0a.89.89,0,0,0,.21.59.76.76,0,0,0,1.13,0,.94.94,0,0,0,.21-.59v-2a.94.94,0,0,0-.21-.59.76.76,0,0,0-1.13,0,.84.84,0,0,0-.21.59v2Z" />
  </React.Fragment>,
  'Percentage'
)
