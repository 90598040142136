import { animated, useSpring } from 'react-spring'
import { BrandColors } from 'style/BrandColors'

export interface CheckboxSvgProps {
  /** displays checked style if true,  empty box with outline if false */
  checked?: boolean
  /** if extended with styled-components: styled(Checkbox), attaches className */
  className?: string
}

/**
 * this is only a checkbox svg with two states:
 *
 * - checked
 * - unchecked
 *
 * If you want a clickable checkbox, you will need to compose a new component with this svg.
 */

export const CheckboxSvg = ({
  checked = false,
  className,
}: CheckboxSvgProps) => {
  const checkStyle = useSpring({
    fill: checked ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)',
  })

  return (
    <svg
      className={className}
      data-testid={`checked-${checked}`}
      focusable="false"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <rect
        fill={checked ? BrandColors.mountainMeadow : BrandColors.white}
        height="17"
        rx="3.96"
        stroke={checked ? BrandColors.mountainMeadow : BrandColors.pastelBlue}
        strokeWidth="1px"
        width="17"
        x=".5"
        y=".5"
      />
      <animated.path
        id="checkmark"
        {...checkStyle}
        d="M14,6.09296417 C14,5.48956629 13.4835141,5 12.8458955,5 C12.4847865,5 12.1627439,5.15742598 11.950934,5.40321928 L11.9504996,5.40280786 L7.79119122,10.2407037 L6.00175706,8.40548779 L6.00088817,8.40630959 C5.78995605,8.19010534 5.48916889,8.0540504 5.15410415,8.0540504 C4.51651937,8.05363898 4,8.54276438 4,9.14659367 C4,9.43267733 4.11718822,9.69203404 4.30685379,9.88686724 L4.30598569,9.88768904 L6.99819244,12.6485626 L6.99906022,12.6477408 C7.20999234,12.8639451 7.5107795,13 7.84584424,13 C8.20695328,13 8.52899587,12.842574 8.74080577,12.5967807 L8.74124021,12.5971921 L13.7412115,6.78271116 L13.740777,6.78229973 C13.902665,6.59445342 13.9998867,6.35441589 13.9998867,6.09299679 L14,6.09296417 Z"
      />
    </svg>
  )
}
