import { usePopper } from 'react-popper'
import { animated, useSpring } from 'react-spring'

import { PortalWithState } from 'react-portal'
import { MoreOptions } from 'components/Icons'
import { cloneElement, useState } from 'react'
import { Placement } from '@popperjs/core'

interface AnimatedContentProps {
  children: React.ReactNode
  onClick: () => void
}
const AnimatedContent = (props: AnimatedContentProps) => {
  const springStyle = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })
  return <animated.div {...props} style={springStyle} />
}

export type MenuPlacement =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-start'
  | 'bottom'
  | 'bottom-end'
  | 'left-start'
  | 'left'
  | 'left-end'

export interface PopOverProps {
  /** popover content */
  children: React.ReactNode
  /** popover menu placement - react-popper prop */
  menuPlacement?: MenuPlacement
  /** optionally specify an item that triggers the popover toggle, if the default 3 dot menu does not suit your fancy */
  trigger: React.ReactElement
}

/** PopOver component that renders a [react-popper](https://popper.js.org/react-popper/) `Popper` in a React.Portal when the trigger is pressed
 *
 *  Automatically closes if user presses `Esc` or clicks outside of PopOver content, similar to a modal.
 */
export const PopOver = ({
  children,
  menuPlacement = 'bottom-end',
  trigger = <MoreOptions />,
}: {
  /** popover content */
  children: React.ReactNode
  /** popover menu placement - react-popper prop */
  menuPlacement?: Placement
  /** optionally specify an item that triggers the popover toggle, if the default 3 dot menu does not suit your fancy */
  trigger: React.ReactElement
}) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: menuPlacement,
  })

  const renderTrigger = (isOpen: boolean) => {
    return cloneElement(trigger, { isOpen })
  }

  return (
    <PortalWithState closeOnEsc closeOnOutsideClick>
      {({ closePortal, isOpen, openPortal, portal }) => {
        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          e.preventDefault()
          if (e.key === 'Enter' || e.key === ' ') {
            isOpen ? closePortal() : openPortal()
          }
        }
        return (
          <>
            <div
              aria-expanded={isOpen}
              aria-haspopup={true}
              onClick={openPortal}
              onKeyDown={handleKeyDown}
              // @ts-ignore
              ref={setReferenceElement}
              role="button"
              tabIndex={0}
              style={{ outline: 'none' }}
            >
              {renderTrigger(isOpen)}
            </div>

            {portal(
              <div
                // @ts-ignore
                ref={setPopperElement}
                style={{ ...styles.popper, zIndex: 1000 }}
                {...attributes}
              >
                <AnimatedContent onClick={closePortal}>
                  {children}
                </AnimatedContent>
              </div>
            )}
          </>
        )
      }}
    </PortalWithState>
  )
}
