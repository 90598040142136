import { animated, useSpring } from 'react-spring'
import styled from 'styled-components/macro'

const Svg = styled.svg<{ size: string }>`
  fill: currentcolor;
  outline: none;
  user-select: none;
  transition: fill 255ms ease;
  height: ${({ size }) => size};
  width: ${({ size }) => size};

  path {
    stroke: currentcolor;
  }
`

export interface SearchIconProps {
  addStroke?: boolean
  /** ie: 1.25rem */
  size?: string
  /**
   * Provides a human-readable title for the element that contains it.
   * https://www.w3.org/TR/SVG-access/#Equivalent
   */
  titleAccess?: string
}

export const SearchIcon = ({
  addStroke,
  size = '1.25rem',
  titleAccess = 'Search',
}: SearchIconProps) => {
  const springProps = useSpring({
    strokeWidth: addStroke ? '2.5px' : '0.5px',
  })
  return (
    <Svg
      aria-hidden={titleAccess ? 'false' : 'true'}
      focusable="false"
      size={size}
      viewBox="0 0 30 30"
    >
      {titleAccess ? <title>{titleAccess}</title> : null}
      <animated.path
        {...springProps}
        d="M22.2483,13.24491 C22.2483,18.06201 18.3933,21.98091 13.6551,21.98091 C8.9172,21.98091 5.0628,18.06201 5.0628,13.24491 C5.0628,8.42721 8.9172,4.50831 13.6551,4.50831 C18.3933,4.50831 22.2483,8.42721 22.2483,13.24491 M25.9629,25.85031 L20.7354,20.52981 L21.0783,20.14761 C22.776,18.25401 23.7111,15.80241 23.7111,13.24491 C23.7111,7.59591 19.2,3.00021 13.6551,3.00021 C8.1108,3.00021 3.6,7.59591 3.6,13.24491 C3.6,18.89361 8.1108,23.48901 13.6551,23.48901 C15.6498,23.48901 17.5791,22.89231 19.2336,21.76371 L19.6119,21.50541 L24.9351,26.92371 C25.0731,27.06381 25.2555,27.14121 25.449,27.14121 C25.6422,27.14121 25.8249,27.06381 25.9629,26.92401 C26.2536,26.62761 26.2536,26.14641 25.9629,25.85031"
      />
    </Svg>
  )
}
