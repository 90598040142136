import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      style={{ fill: 'currentColor' }}
      d="M14.94,4.61a4,4,0,0,0-3.84,4v.8h7.8v-.8a4,4,0,0,0-3.84-3.95ZM7.23,25.39H22.77V11H20.51V13.2a.81.81,0,0,1-1.61,0V11H11.1V13.2a.81.81,0,0,1-1.61,0V11H7.23ZM6.42,27a.81.81,0,0,1-.81-.81v-16a.84.84,0,0,1,.81-.85H9.49v-.8A5.58,5.58,0,0,1,14.94,3h.12a5.58,5.58,0,0,1,5.45,5.56v.8h3.07a.86.86,0,0,1,.57.26.84.84,0,0,1,.24.58v16a.81.81,0,0,1-.81.81Z"
    />
    <path
      d="M13.67,22.22a.32.32,0,0,1-.21-.08L10.32,19a.29.29,0,0,1-.09-.21.31.31,0,0,1,.31-.3.27.27,0,0,1,.21.09l2.93,2.91L19,16.23a.3.3,0,0,1,.21-.08.28.28,0,0,1,.3.3.32.32,0,0,1-.08.21l-5.57,5.48a.33.33,0,0,1-.22.08Z"
      style={{
        fill: 'currentColor',
        stroke: 'currentColor',
        strokeWidth: '0.9357688054860115px',
        fillRule: 'evenodd',
      }}
    />
  </React.Fragment>,

  'OrderReady'
)
