import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M26.4867,11.7054 L25.4745,10.9221 L25.4745,10.305 L26.4867,9.5211 C26.9745,9.2004 27.1479,8.5593 26.8602,8.0592 L25.7625,6.1491 C25.5714,5.8191 25.2135,5.6145 24.8253,5.6145 C24.6495,5.6145 24.4764,5.6559 24.3183,5.7351 L23.1414,6.2046 L22.6014,5.8929 L22.4202,4.6425 C22.3863,4.0593 21.9135,3.6 21.3369,3.6 L19.1334,3.6 C18.5568,3.6 18.0837,4.0593 18.0498,4.6425 L17.8689,5.8947 L17.3283,6.2058 L16.1511,5.7357 C15.993,5.6565 15.8187,5.6148 15.6429,5.6148 C15.2544,5.6148 14.8944,5.8197 14.7033,6.1503 L14.1,7.1961 C14.5398,7.4319 14.9583,7.7106 15.3522,8.0277 L15.8193,7.2177 L17.4477,7.8678 L19.2495,6.8322 L19.5,5.1 L20.9703,5.1 L21.2211,6.8322 L23.0223,7.8696 L24.6513,7.2186 L25.3698,8.4918 L23.9745,9.5766 L23.9745,11.6505 L25.3698,12.7356 L24.6429,14.0091 L23.0181,13.359 L21.219,14.4315 L20.9703,16.2 L19.5,16.2 L19.2495,14.4312 L18.4038,13.9269 C18.4137,14.0928 18.4203,14.2518 18.4203,14.4198 C18.4203,15.2289 18.303,16.0482 18.0771,16.8024 C18.1866,17.2989 18.6174,17.7 19.1334,17.7 L21.3369,17.7 C21.9135,17.7 22.3863,17.2038 22.4202,16.6206 L22.6017,15.3504 L23.1426,15.03 L24.3189,15.4956 C24.4773,15.5748 24.6516,15.6144 24.8271,15.6144 C25.2159,15.6144 25.5759,15.408 25.7676,15.0771 L26.8602,13.1679 C27.1479,12.6684 26.9748,12.0264 26.4867,11.7054" />
    <path d="M20.157,11.5146 C19.6293,11.5146 19.2,11.085 19.2,10.5573 C19.2,10.0293 19.6293,9.6 20.157,9.6 C20.6853,9.6 21.1146,10.0293 21.1146,10.5573 C21.1146,11.085 20.6853,11.5146 20.157,11.5146 M20.157,8.1 C18.8022,8.1 17.7,9.2022 17.7,10.5573 C17.7,11.9121 18.8022,13.0146 20.157,13.0146 C21.5121,13.0146 22.6146,11.9121 22.6146,10.5573 C22.6146,9.2022 21.5121,8.1 20.157,8.1" />
    <path d="M10.2453,12.3 C9.2277,12.3 8.4,13.128 8.4,14.1459 C8.4,15.1635 9.2277,15.9915 10.2453,15.9915 C11.2629,15.9915 12.0906,15.1635 12.0906,14.1459 C12.0906,13.128 11.2629,12.3 10.2453,12.3" />
    <path d="M10.1088,8.9418 C11.5455,8.9418 12.8946,9.5007 13.9074,10.5159 C14.9226,11.5299 15.4815,12.8778 15.4815,14.3115 C15.4815,15.7455 14.9289,17.0874 13.9263,18.09 C13.7601,18.2538 12.2619,19.7592 10.7868,22.0905 L10.1076,23.1645 L9.4308,22.089 C7.9536,19.74 6.48,18.2679 6.3171,18.108 C5.3013,17.0952 4.7418,15.747 4.7418,14.3115 C4.7418,12.8775 5.3007,11.5293 6.3156,10.5156 C7.3584,9.4713 8.6346,8.9418 10.1088,8.9418 M14.9277,9.4962 C13.6407,8.2089 11.9292,7.5 10.1088,7.5 C8.2917,7.5 6.5826,8.2092 5.2959,9.4968 C4.0089,10.7832 3.3,12.4932 3.3,14.3118 C3.3,16.1307 4.0065,17.8389 5.2905,19.1238 C5.4165,19.2468 8.3949,22.1799 9.9693,26.2878 L10.1094,26.6535 L10.2495,26.2878 C11.8212,22.1862 14.8005,19.2522 14.9277,19.128 C16.2144,17.8419 16.923,16.1316 16.923,14.3124 C16.923,12.4932 16.2147,10.7826 14.9277,9.4962" />
  </React.Fragment>,
  'LocationSettings'
)
