import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      stroke="currentColor"
      strokeWidth=".05px"
      d="M15.9944421,14.93895 L23.4984266,7.43025 C23.7912417,7.13745 23.7912417,6.66255 23.4981266,6.36945 C23.2050116,6.07695 22.7300873,6.07665 22.4372723,6.36975 L14.9338877,13.87785 L7.43050314,6.36975 C7.13768813,6.07665 6.66276379,6.07695 6.36964877,6.36945 C6.07683376,6.66255 6.07683376,7.13745 6.36934876,7.43025 L13.8733333,14.93895 L6.36934876,22.44765 C6.07683376,22.74075 6.07683376,23.21535 6.36964877,23.50845 C6.51605628,23.65455 6.70806612,23.72775 6.90007596,23.72775 C7.09178578,23.72775 7.28409564,23.65455 7.43050314,23.50815 L14.9338877,16.00005 L22.4372723,23.50815 C22.5836798,23.65455 22.7759896,23.72775 22.9676994,23.72775 C23.1597093,23.72775 23.3517191,23.65455 23.4981266,23.50845 C23.7912417,23.21535 23.7912417,22.74075 23.4984266,22.44765 L15.9944421,14.93895 Z"
    />
  </React.Fragment>,
  'Cancel'
)
