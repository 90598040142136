import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M14.8848,3 C8.3313,3 3,8.3313 3,14.8848 C3,21.4383 8.3313,26.7696 14.8848,26.7696 C21.4383,26.7696 26.7696,21.4383 26.7696,14.8848 C26.7696,8.3313 21.4383,3 14.8848,3" />
    <path
      fill="rgba(255,255,255,0.9)"
      d="M15.7608,9.0222 C16.1985,9.0222 16.6272,8.844 16.9368,8.5335 C17.2449,8.2254 17.4216,7.7982 17.4216,7.3614 C17.4216,6.9219 17.2449,6.4932 16.9368,6.1854 C16.6287,5.877 16.2,5.7 15.7608,5.7 C15.3231,5.7 14.8953,5.8773 14.5869,6.1866 C14.2779,6.4926 14.1,6.9213 14.1,7.3614 C14.1,7.7994 14.2782,8.2269 14.589,8.5347 C14.898,8.844 15.3255,9.0222 15.7608,9.0222"
    />
    <path
      fill="rgba(255,255,255,0.9)"
      d="M18.1936134,20.7342 C18.0097134,20.3118 17.5696134,20.028 17.0980134,20.028 C16.9384134,20.028 16.7836134,20.0598 16.6387134,20.1225 L15.4162134,20.655 L17.0209134,12.5079 C17.1103134,12.0573 16.9273134,11.5911 16.5544134,11.3214 C16.3564134,11.1786 16.1098134,11.1 15.8593134,11.1 C15.6541134,11.1 15.4540134,11.1519 15.2806134,11.25 L12.3025134,12.9276 C12.0277134,13.0824 11.8291134,13.335 11.7439134,13.6389 C11.6593134,13.944 11.6977134,14.2629 11.8525134,14.538 C12.0619134,14.9094 12.4570134,15.1401 12.8833134,15.1401 C13.0852134,15.1401 13.2853134,15.0873 13.4626134,14.9874 L14.2051134,14.5692 L12.6607134,22.4112 C12.5752134,22.8444 12.7357134,23.2851 13.0798134,23.5611 C13.2895134,23.7294 13.5526134,23.8221 13.8205134,23.8221 C13.9843134,23.8221 14.1430134,23.7888 14.2930134,23.7231 L17.5822134,22.2903 C17.8714134,22.1643 18.0943134,21.933 18.2098134,21.6387 C18.3253134,21.3444 18.3196134,21.0234 18.1936134,20.7342"
    />
  </React.Fragment>,
  'MoreInfo'
)
