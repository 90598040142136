import { darken, lighten } from 'polished'
import { BrandColors } from 'style/BrandColors'
import styled, { css, keyframes } from 'styled-components/macro'

export interface BoxShadowType {
  disabled?: boolean
}
const boxShadow = ({ disabled }: BoxShadowType) => {
  const shadow = '0 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 0 rgba(0, 0, 0, 0.08)'
  return css`
    box-shadow: ${!disabled ? shadow : 'none'};
  `
}

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

export type StatusType = 'bad' | 'good' | null

export interface TabColorType {
  on?: string
  status: StatusType
}

const tabColor = ({ on, status }: TabColorType) => {
  const statusColors = {
    bad: BrandColors.ruby,
    good: BrandColors.mountainMeadow,
  }
  const statusColor = status ? statusColors[status] : undefined
  const fontColor = !!on && !!status ? statusColor : 'currentColor'
  return css`
    color: ${fontColor};
  `
}

export const Indicator = styled.div<{
  disabled?: boolean
  offset: number
  selected: null | number
  tabWidth: number
}>`
  animation: ${fadeIn} 150ms ease-in;
  background-color: ${BrandColors.white};
  border: 1px solid transparent;
  border-color: ${({ disabled }) =>
    disabled ? lighten(0.01, BrandColors.taupeGray) : 'transparent'};
  border-radius: 2px;
  ${boxShadow};
  box-sizing: inherit;
  height: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  position: absolute;
  transform: translateX(${({ offset }) => offset}px);
  transition: transform 0.2s ease, background-color 0.2s ease,
    box-shadow 200ms ease, border-color 100ms ease-in, width 0ms ease 0ms;
  width: ${({ tabWidth }) => tabWidth}px;
  z-index: 1;

  ${({ selected }) => selected === null && `display: none;`};
`

export const SlideWrap = styled.div<{ disabled?: boolean }>`
  background-color: ${darken(0.05, BrandColors.isabelline)};
  border: 1px solid ${darken(0.1, BrandColors.isabelline)};
  border-radius: 2px;
  box-sizing: border-box;
  color: ${BrandColors.graniteGray};
  cursor: default;
  height: 2.25rem;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.8)};
  width: 100%;
  z-index: 0;
`

export const Slide = styled.div`
  align-items: center;
  box-sizing: inherit;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
`

export const Tab = styled.div<{
  disabled?: boolean
  on?: string
  status: StatusType
  tabWidth: number
}>`
  align-items: center;
  border-radius: 2px;
  box-sizing: inherit;
  display: inline-flex;
  flex: 0 0 ${({ tabWidth }) => tabWidth}px;
  font-size: 0.8rem;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  padding: 0 1rem;
  ${tabColor};
  text-transform: uppercase;
  transition: color 0.2s ease;
  user-select: none;
  width: ${({ tabWidth }) => tabWidth}px;
  z-index: 2;

  > span {
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
