import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      fillRule="evenodd"
      d="M25.2924,17.85 L4.5,17.85 L4.5,11.7381 L12.8469,11.7381 L11.0166,13.5279 C10.7205,13.8177 10.7151,14.2926 11.0046,14.5887 C11.1516,14.7387 11.3463,14.8143 11.541,14.8143 C11.73,14.8143 11.9193,14.7432 12.0654,14.6004 L14.9403,11.7888 L17.7156,14.6262 C17.8623,14.7765 18.057,14.8518 18.2517,14.8518 C18.441,14.8518 18.6303,14.7807 18.7761,14.6379 C19.0725,14.3484 19.0776,13.8735 18.7878,13.5774 L16.989,11.7381 L25.2924,11.7381 L25.2924,17.85 Z M4.5,23.5773 L25.2924,23.5773 L25.2924,20.55 L4.5,20.55 L4.5,23.5773 Z M12.1716,6.3 C13.1757,6.3 13.9926,7.1169 13.9926,8.121 C13.9926,9.1251 13.1757,9.9423 12.1716,9.9423 C11.1675,9.9423 10.3506,9.1251 10.3506,8.121 C10.3506,7.1169 11.1675,6.3 12.1716,6.3 L12.1716,6.3 Z M17.5101,6.3 C18.5142,6.3 19.3311,7.1169 19.3311,8.121 C19.3311,9.1251 18.5142,9.9423 17.5101,9.9423 C16.506,9.9423 15.6891,9.1251 15.6891,8.121 C15.6891,7.1169 16.506,6.3 17.5101,6.3 L17.5101,6.3 Z M25.4424,10.2381 L20.0667,10.2381 C20.5437,9.663 20.8311,8.925 20.8311,8.121 C20.8311,6.2898 19.3413,4.8 17.5101,4.8 C16.4157,4.8 15.4461,5.3343 14.8407,6.1536 C14.2356,5.3343 13.266,4.8 12.1716,4.8 C10.3404,4.8 8.8506,6.2898 8.8506,8.121 C8.8506,8.925 9.138,9.663 9.615,10.2381 L4.35,10.2381 C3.6054,10.2381 3,10.8438 3,11.5881 L3,23.7273 C3,24.4716 3.6054,25.0773 4.35,25.0773 L25.4424,25.0773 C26.1867,25.0773 26.7924,24.4716 26.7924,23.7273 L26.7924,11.5881 C26.7924,10.8438 26.1867,10.2381 25.4424,10.2381 L25.4424,10.2381 Z"
    />
  </React.Fragment>,
  'GiftCard'
)
