import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

export const Label = styled.label`
  color: ${BrandColors.middleRedPurple};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 2rem;
`

export const Wrap = styled.div`
  margin: 0;
  min-width: 30rem;
  position: relative;
`
