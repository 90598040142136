import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M1.496 12.54V5.086h3.676v-3.72h5.159V12.54H1.496zM10.816.594H4.44L.72 4.349v8.674c0 .16.13.29.29.29h9.806c.16 0 .29-.13.29-.29V.883a.29.29 0 0 0-.29-.29z" />
    <path d="M12.99 4.18v11.156H4v-2.39h-.8v3.187h10.59V3.383h-3.417v.797z" />
  </React.Fragment>,
  'Clipboard'
)
