import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M11.2824,10.2321 L11.2359,10.1412 L11.2242,10.1451 C11.2227,10.1187 11.2218,10.0905 11.2218,10.0608 C11.2218,9.7977 11.2758,9.462 11.3187,9.279 L18.7218,9.279 C18.7647,9.4614 18.8181,9.7971 18.8181,10.0608 C18.8181,10.1247 18.8148,10.1823 18.8079,10.2321 L17.673,10.2321 L17.673,15.8178 C17.673,16.6272 18.2661,17.7837 19.1583,19.2702 C18.9003,19.8234 18.3375,20.1039 17.4846,20.1039 C16.2333,20.1039 15.399,19.035 14.6628,18.0918 C14.0487,17.3052 13.4685,16.5621 12.738,16.5621 C12.5667,16.5621 12.3888,16.6035 12.1971,16.6881 C12.3117,16.3482 12.3675,16.0623 12.3675,15.8178 L12.3675,10.2321 L11.2824,10.2321 Z M21.1722,19.7286 L21.1266,19.7535 L21.1707,19.7262 C20.2716,18.2691 19.1517,16.4562 19.1517,15.8178 L19.1517,11.7054 C19.3776,11.6772 19.5891,11.5851 19.7667,11.4378 C20.1189,11.1471 20.2974,10.6839 20.2974,10.0608 C20.2974,8.7495 19.4871,7.8 18.9411,7.8 L11.0994,7.8 C10.8138,7.8 10.4718,8.0478 10.2066,8.4474 C9.9075,8.8974 9.7428,9.4707 9.7428,10.0608 C9.7428,10.6836 9.9213,11.1468 10.2732,11.4378 C10.4937,11.6205 10.722,11.6856 10.8882,11.7066 L10.8882,15.8178 C10.8882,16.4559 9.7686,18.2697 8.8683,19.7283 C7.7016,21.6171 6.6,23.4018 6.6,24.4821 C6.6,26.4699 7.8246,27.0261 8.4738,27.0426 L21.5739,27.0426 C22.2192,27.0261 23.4399,26.4699 23.4399,24.4821 C23.4399,23.4024 22.3386,21.6177 21.1722,19.7286 L21.1722,19.7286 Z" />
    <path d="M13.5984,6.7968 C13.9836,6.7968 14.2968,6.4836 14.2968,6.0984 C14.2968,5.7132 13.9836,5.4 13.5984,5.4 C13.2132,5.4 12.9,5.7132 12.9,6.0984 C12.9,6.4836 13.2132,6.7968 13.5984,6.7968" />
    <path d="M16.6977,6.3963 C17.3034,6.3963 17.7957,5.9037 17.7957,5.2983 C17.7957,4.6926 17.3034,4.2 16.6977,4.2 C16.0923,4.2 15.6,4.6926 15.6,5.2983 C15.6,5.9037 16.0923,6.3963 16.6977,6.3963" />
    <path d="M14.3808,4.7613 C14.8662,4.7613 15.2613,4.3662 15.2613,3.8808 C15.2613,3.3951 14.8662,3 14.3808,3 C13.8951,3 13.5,3.3951 13.5,3.8808 C13.5,4.3662 13.8951,4.7613 14.3808,4.7613" />
  </React.Fragment>,
  'InfusedProduct'
)
