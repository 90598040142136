import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M3,14.95926 C3,8.35446 8.3544,3.00006 14.9592,3.00006 C21.5643,3.00006 26.9187,8.35446 26.9187,14.95926 C26.9187,21.56406 21.5643,26.91846 14.9592,26.91846 C8.3544,26.91846 3,21.56406 3,14.95926" />
    <path
      d="M16.00122,14.96748 L20.50242,10.46328 C20.80242,10.16238 20.80242,9.67368 20.50152,9.37278 C20.20032,9.07248 19.71132,9.07248 19.41132,9.37308 L14.91102,13.87608 L10.41042,9.37308 C10.10982,9.07248 9.62112,9.07248 9.31992,9.37308 C9.01962,9.67368 9.01962,10.16268 9.31992,10.46328 L13.82052,14.96748 L9.31992,19.47138 C9.01962,19.77228 9.01992,20.26128 9.31992,20.56158 C9.61122,20.85288 10.11942,20.85288 10.41042,20.56158 L14.91102,16.05858 L19.41132,20.56158 C19.55712,20.70738 19.75062,20.78748 19.95672,20.78748 C20.16252,20.78748 20.35632,20.70738 20.50182,20.56158 C20.80212,20.26128 20.80242,19.77258 20.50212,19.47138 L16.00122,14.96748 Z"
      fill="#FFFFFF"
    />
  </React.Fragment>,
  'Failure'
)
