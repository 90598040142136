import styled from 'styled-components/macro'
import { Spinner } from 'components/FlowhubUIComponents'

const StyledMiniLoader = styled.div<{ extraStyles: string }>`
  // eat up the parent space:
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  & > * {
    margin: auto;
  }

  // overrides:
  ${({ extraStyles }) => extraStyles}
`

export const MiniLoader = ({ extraStyles = '' }) => (
  <StyledMiniLoader extraStyles={extraStyles}>
    <Spinner thickness={2} />
  </StyledMiniLoader>
)
