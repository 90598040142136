import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M12.93096,16.33896 C12.24066,15.14706 12.64716,13.62126 13.83906,12.93096 C15.03126,12.24066 16.55706,12.64716 17.24736,13.83906 C17.93766,15.03096 17.53086,16.55676 16.33896,17.24706 C15.14706,17.93766 13.62126,17.53086 12.93096,16.33896" />
    <path d="M15.81534,22.87971 L15.81534,21.11601 C15.81534,20.70201 15.47964,20.36601 15.06534,20.36601 C14.65104,20.36601 14.31534,20.70201 14.31534,21.11601 L14.31534,22.87611 C10.59294,22.51071 7.62474,19.53891 7.26444,15.81531 L9.06204,15.81531 C9.47634,15.81531 9.81204,15.47931 9.81204,15.06531 C9.81204,14.65101 9.47634,14.31531 9.06204,14.31531 L7.26234,14.31531 C7.61304,10.56351 10.61004,7.57011 14.36304,7.22451 L14.36304,9.06201 C14.36304,9.47631 14.69874,9.81201 15.11304,9.81201 C15.52734,9.81201 15.86304,9.47631 15.86304,9.06201 L15.86304,7.22811 C19.60974,7.59621 22.59144,10.60491 22.91964,14.36301 L21.11634,14.36301 C20.70204,14.36301 20.36634,14.69871 20.36634,15.11301 C20.36634,15.52731 20.70204,15.86301 21.11634,15.86301 L22.90974,15.86301 C22.52724,19.58031 19.54404,22.53651 15.81534,22.87971 M26.12814,14.36301 L24.42354,14.36301 C24.08904,9.77061 20.44404,6.09951 15.86304,5.72421 L15.86304,4.04991 C15.86304,3.63561 15.52734,3.29991 15.11304,3.29991 C14.69874,3.29991 14.36304,3.63561 14.36304,4.04991 L14.36304,5.72061 C9.77544,6.07281 6.11604,9.72921 5.75844,14.31531 L4.04994,14.31531 C3.63564,14.31531 3.29994,14.65101 3.29994,15.06531 C3.29994,15.47931 3.63564,15.81531 4.04994,15.81531 L5.76024,15.81531 C6.12834,20.37321 9.75894,24.00711 14.31534,24.38001 L14.31534,26.12811 C14.31534,26.54241 14.65104,26.87811 15.06534,26.87811 C15.47964,26.87811 15.81534,26.54241 15.81534,26.12811 L15.81534,24.38391 C20.37804,24.03321 24.02364,20.41431 24.41424,15.86301 L26.12814,15.86301 C26.54244,15.86301 26.87814,15.52731 26.87814,15.11301 C26.87814,14.69871 26.54244,14.36301 26.12814,14.36301" />
  </React.Fragment>,
  'ConcentratedProducts'
)
