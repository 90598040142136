import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M25.773,20.538 C25.773,20.8329 25.5294,21.0729 25.23,21.0729 L23.7438,21.0729 L23.7438,19.8924 L23.7438,19.8906 C23.7438,18.8337 22.9401,17.9613 21.9129,17.8566 C21.8445,17.8494 21.7752,17.8458 21.7047,17.8458 L8.7009,17.8458 C8.6307,17.8458 8.5611,17.8494 8.4927,17.8566 C7.4658,17.9613 6.6618,18.8337 6.6618,19.8906 L6.6618,19.8924 L6.6618,21.0729 L5.3424,21.0729 C5.0433,21.0729 4.8,20.8329 4.8,20.538 L4.8,11.4951 C4.8,10.4853 5.6202,9.6639 6.6285,9.6639 L23.9442,9.6639 C24.9528,9.6639 25.773,10.4853 25.773,11.4951 L25.773,20.538 Z M22.2438,25.5207 L8.1618,25.5207 L8.1618,21.8229 L8.1618,19.8924 C8.1618,19.5912 8.4036,19.3458 8.7009,19.3458 L21.7047,19.3458 C22.0023,19.3458 22.2438,19.5912 22.2438,19.8924 L22.2438,21.8229 L22.2438,25.5207 Z M7.5618,6.3318 C7.5618,5.3217 8.385,4.5 9.3972,4.5 L21.0084,4.5 C22.0206,4.5 22.8438,5.3217 22.8438,6.3318 L22.8438,8.1639 L7.5618,8.1639 L7.5618,6.3318 Z M24.3438,8.1903 L24.3438,6.3318 C24.3438,4.4946 22.8477,3 21.0084,3 L9.3972,3 C7.5579,3 6.0618,4.4946 6.0618,6.3318 L6.0618,8.2158 C4.4961,8.4864 3.3,9.852 3.3,11.4951 L3.3,20.538 C3.3,21.66 4.2165,22.5729 5.3424,22.5729 L6.6618,22.5729 L6.6618,26.2707 C6.6618,26.685 6.9975,27.0207 7.4118,27.0207 L22.9938,27.0207 C23.4081,27.0207 23.7438,26.685 23.7438,26.2707 L23.7438,22.5729 L25.23,22.5729 C26.3565,22.5729 27.273,21.66 27.273,20.538 L27.273,11.4951 C27.273,9.7938 25.9914,8.3889 24.3438,8.1903 L24.3438,8.1903 Z" />
    <path d="M22.2909,13.9818 C23.0037,13.9818 23.5818,13.4037 23.5818,12.6909 C23.5818,11.9778 23.0037,11.4 22.2909,11.4 C21.5778,11.4 21,11.9778 21,12.6909 C21,13.4037 21.5778,13.9818 22.2909,13.9818" />
  </React.Fragment>,
  'Print'
)
