import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export const MoreOptions = createSvgIcon(
  <React.Fragment>
    <path d="M13.2,22.3265577 C13.2,21.4022197 13.95648,20.6530648 14.889856,20.6530648 C15.822976,20.6530648 16.579712,21.4022197 16.579712,22.3265577 C16.579712,23.2508958 15.822976,24.0000507 14.889856,24.0000507 C13.95648,24.0000507 13.2,23.2508958 13.2,22.3265577" />
    <path d="M13.2,15.0283944 C13.2,14.1040563 13.95648,13.3549014 14.889856,13.3549014 C15.822976,13.3549014 16.579712,14.1040563 16.579712,15.0283944 C16.579712,15.9527324 15.822976,16.7018873 14.889856,16.7018873 C13.95648,16.7018873 13.2,15.9527324 13.2,15.0283944" />
    <path d="M13.2,7.73028169 C13.2,6.80594366 13.95648,6.05678873 14.889856,6.05678873 C15.822976,6.05678873 16.579712,6.80594366 16.579712,7.73028169 C16.579712,8.65461972 15.822976,9.40377465 14.889856,9.40377465 C13.95648,9.40377465 13.2,8.65461972 13.2,7.73028169" />
  </React.Fragment>,
  'MoreOptions'
)

export default MoreOptions
