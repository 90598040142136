import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M15.26817,12.5640889 C15.10557,12.5640889 14.94177,12.512237 14.80437,12.4049778 C14.47917,12.1516444 14.42337,11.6858667 14.67987,11.3646815 C14.73387,11.2971259 16.01367,9.69712593 17.11617,8.60853333 C18.24387,7.49475556 19.33737,6.62897778 22.24827,6.45238519 C22.67067,6.42482963 23.01747,6.73831111 23.04267,7.14631111 C23.06817,7.55460741 22.75347,7.90601481 22.34007,7.9309037 C19.93857,8.07668148 19.16517,8.67994074 18.17667,9.65594074 C17.13777,10.6823111 15.87027,12.2663111 15.85767,12.2820148 C15.70977,12.4672 15.48987,12.5640889 15.26817,12.5640889" />
    <path d="M4.75116,21.1063407 L6.96696,23.2944889 L6.96696,23.2947852 L8.89296,25.1967111 C11.30316,23.3247111 21.24216,15.6050074 22.07316,14.9344889 C23.08116,14.1214519 25.42536,11.7036741 25.42536,6.82337778 C25.42536,5.73626667 25.16466,5.25300741 25.08426,5.13034074 C24.96126,5.05626667 24.43146,4.77774815 23.35416,4.77774815 C18.41256,4.77774815 15.96456,7.09300741 15.14166,8.08856296 C14.46246,8.9093037 6.64626,18.7256 4.75116,21.1063407 M8.83326,26.9261926 C8.64036,26.9261926 8.44836,26.8530074 8.30286,26.7090074 L3.21996,21.6888593 C2.95116,21.4236741 2.92566,21.0023407 3.16026,20.7075259 C3.57006,20.1928593 13.20186,8.09211852 13.97976,7.15137778 C14.93826,5.99226667 17.77026,3.29626667 23.35416,3.29626667 C25.07826,3.29626667 25.88436,3.85508148 26.08356,4.01893333 C26.10036,4.03226667 26.11686,4.04648889 26.13246,4.0618963 C26.26506,4.18841481 26.92536,4.9173037 26.92536,6.82337778 C26.92536,12.3380444 24.19536,15.1353778 23.02176,16.0820444 C22.06956,16.8503407 9.81786,26.3632296 9.29646,26.7676741 C9.15966,26.8737481 8.99616,26.9261926 8.83326,26.9261926" />
  </React.Fragment>,
  'Suppositories'
)
