import styled from 'styled-components/macro'
import { BongLoader } from 'components/FlowhubUIComponents'

const StyledMediumLoader = styled.div<{ extraStyles: string }>`
  ${({ extraStyles }) => extraStyles}
`

export const MediumLoader = ({ extraStyles = '' }) => (
  <StyledMediumLoader extraStyles={extraStyles}>
    <BongLoader size={10} data-testid="bong-loader" />
  </StyledMediumLoader>
)
