import { ButtonFrameless } from 'components/ButtonFrameless'
import { ButtonFramelessProps } from 'components/ButtonFrameless/types'
import { Cancel } from 'components/Icons'
import styled from 'styled-components/macro'

const Button = styled(ButtonFrameless)`
  min-width: auto; /* overwrites component */
  padding: 0.5rem; /* overwrites component */
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`

export const CancelButton = (props: ButtonFramelessProps) => (
  <Button
    aria-label="close modal"
    icon={<Cancel />}
    data-testid="close_modal_button"
    size="small"
    {...props}
  />
)
