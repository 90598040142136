import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <g transform="matrix(0.46153846,0,0,0.46153846,2.8327901,2.244133)">
      <path d="M 35.766,22.633 A 4.722,4.722 0 0 1 31.05,17.916 c 0,-2.6 2.116,-4.716 4.716,-4.716 2.601,0 4.717,2.116 4.717,4.716 0,2.601 -2.116,4.717 -4.717,4.717" />
      <path d="M 42.344,33.668 48.769,27.243 48.758,4.937 26.431,4.907 20.007,11.331 17.6,8.922 24.522,2 c 0.32,-0.322 0.75,-0.499 1.204,-0.499 l 24.736,0.033 a 1.706,1.706 0 0 1 1.702,1.703 l 0.01,24.712 c 0,0.456 -0.176,0.883 -0.498,1.205 l -6.923,6.923 -2.409,-2.408 z m -4.95,4.95 2.41,2.408 -11.416,11.415 a 2.514,2.514 0 0 1 -1.79,0.735 2.7,2.7 0 0 1 -1.893,-0.792 L 1.29,28.968 C 0.26,27.938 0.234,26.288 1.233,25.288 L 12.649,13.872 15.057,16.28 4.237,27.1 26.574,49.437 37.394,38.617 Z M 4.44,2.56 A 1.5,1.5 0 0 1 6.561,0.439 l 45.937,45.938 a 1.5,1.5 0 0 1 -2.121,2.121 L 4.439,2.561 Z" />
    </g>
  </React.Fragment>,
  'NoSpecial'
)
