import { ButtonFrameless } from 'components/ButtonFrameless'
import { ButtonFramelessProps } from 'components/ButtonFrameless/types'
import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

export const Page = styled(ButtonFrameless)<
  ButtonFramelessProps & {
    isCurrentPage?: boolean
    disabled?: boolean
    onClick: (page: number) => void
  }
>`
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  padding: 0;
  ${({ isCurrentPage }) =>
    isCurrentPage &&
    `
    &, &:active, &:focus, &:hover, &:focus:not(:active):not(:hover) {
      background-color: ${BrandColors.mountainMeadow};
    }
  `}
  &:disabled {
    color: inherit;
  }
`

export const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  & > * {
    margin: 0 0.4rem;
  }
`

export const Nav = styled(ButtonFrameless)<ButtonFramelessProps>`
  height: 2.5rem;
  min-height: 2.5rem;
  padding: 0 1rem;
`
