import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export const PrimaryMember = createSvgIcon(
  <React.Fragment>
    <path d="M13.2291,18.7803 L11.5533,18.7803 L11.5533,16.6053 L13.2291,16.6053 C13.602,16.6053 13.9056,16.3017 13.9056,15.9288 L13.9056,14.253 L16.0806,14.253 L16.0806,15.9288 C16.0806,16.3017 16.3842,16.6053 16.7574,16.6053 L18.4329,16.6053 L18.4329,18.7803 L16.7574,18.7803 C16.3842,18.7803 16.0806,19.0839 16.0806,19.4571 L16.0806,21.1329 L13.9056,21.1329 L13.9056,19.4571 C13.9056,19.0839 13.602,18.7803 13.2291,18.7803 M18.9186,15.2523 L17.4342,15.2523 L17.4342,13.7676 C17.4342,13.2891 17.0451,12.9 16.5666,12.9 L13.4199,12.9 C12.9414,12.9 12.5523,13.2891 12.5523,13.7676 L12.5523,15.2523 L11.0676,15.2523 C10.5891,15.2523 10.2,15.6414 10.2,16.1199 L10.2,19.2663 C10.2,19.7448 10.5891,20.1339 11.0676,20.1339 L12.5523,20.1339 L12.5523,21.6186 C12.5523,22.0971 12.9414,22.4862 13.4199,22.4862 L16.5666,22.4862 C17.0451,22.4862 17.4342,22.0971 17.4342,21.6186 L17.4342,20.1339 L18.9186,20.1339 C19.3971,20.1339 19.7862,19.7448 19.7862,19.2663 L19.7862,16.1199 C19.7862,15.6414 19.3971,15.2523 18.9186,15.2523" />
    <path d="M6.6447,25.6032 L6.6447,13.2033 L15.0375,4.8105 L23.4312,13.2042 L23.4312,25.6032 L6.6447,25.6032 Z M24.1812,27.1032 C24.5955,27.1032 24.9312,26.7675 24.9312,26.3532 L24.9312,14.7042 L25.7949,15.5679 C25.9413,15.7143 26.1333,15.7875 26.3253,15.7875 C26.517,15.7875 26.709,15.7143 26.8554,15.5679 C27.1485,15.2748 27.1485,14.8002 26.8554,14.5074 L15.5679,3.2199 C15.4215,3.0732 15.2295,3 15.0375,3 C14.8458,3 14.6538,3.0732 14.5071,3.2199 L3.2196,14.5074 C2.9268,14.8002 2.9268,15.2748 3.2196,15.5679 C3.3663,15.7143 3.558,15.7875 3.75,15.7875 C3.942,15.7875 4.1337,15.7143 4.2804,15.5679 L5.1447,14.7033 L5.1447,26.3532 C5.1447,26.7675 5.4804,27.1032 5.8947,27.1032 L24.1812,27.1032 Z" />
  </React.Fragment>,
  'PrimaryMember'
)

export default PrimaryMember
