import createSvgIcon from './createSvgIcon'
import styled from 'styled-components/macro'

const PencilIcon = createSvgIcon(
  <path
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M20 15.7777V20H15.7777L3.55556 7.66667L7.77777 3.44444L20 15.7777ZM0.333334 4.44444C-0.111111 4 -0.111111 3.33333 0.333334 2.88889L2.88889 0.333333C3.33333 -0.111111 4 -0.111111 4.44444 0.333333L6.44444 2.33333L2.22223 6.55556L0.333334 4.44444Z"
    fill="#2E1679"
  />,
  'Pencil'
)

export default styled(PencilIcon)`
  path {
    transform: translateX(5.5px) translateY(5.5px);
  }
`
