import styled, { css } from 'styled-components/macro'
import { BrandColors } from 'style/BrandColors'
import { darken, lighten } from 'polished'

const btnSize = 20

interface ColorSchemeProps {
  disabled?: boolean
  on?: string
}
const colorScheme = ({ disabled, on }: ColorSchemeProps) => {
  const thisColor = on ? BrandColors.hanBlue : BrandColors.taupeGray
  const borderColor = darken(0.15, thisColor)

  return css`
    background-color: ${disabled ? lighten(0.16, thisColor) : thisColor};
    border-color: ${disabled ? lighten(0.15, borderColor) : borderColor};
  `
}

const contentText = (props: ColorSchemeProps) =>
  props.on
    ? css`
        content: 'on';
      `
    : css`
        content: 'off';
        transform: translateX(${btnSize}px);
      `

export const Label = styled.label`
  display: block;
`

export const ToggleBtn = styled.span<{
  btnSize?: number
  disabled?: boolean
  on?: string
}>`
  border: 1px solid transparent;
  border-radius: 1rem;
  cursor: inherit;
  display: inline-flex;
  height: 26px;
  outline: 0;
  position: relative;
  transition: all 0.4s ease;
  user-select: none;
  width: 56px;
  ${colorScheme};

  &:after {
    background: ${BrandColors.white};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${btnSize}px;
    left: ${({ on }) => (on ? 'calc(100% - ' + (btnSize + 2) + 'px)' : '2px')};
    position: relative;
    top: 2px;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      padding 0.3s ease, margin 0.3s ease;
    width: ${btnSize}px;
    ${({ disabled }) =>
      !disabled &&
      css`
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
      `};
  }

  &:before {
    align-items: center;
    color: ${BrandColors.white};
    display: flex;
    font-size: 0.6rem;
    font-weight: 100;
    height: 100%;
    justify-content: center;
    left: 0px;
    letter-spacing: 0.075rem;
    padding: 0 2px;
    position: absolute;
    text-align: center;

    text-transform: uppercase;
    width: calc(100% - ${btnSize + 2}px);
    ${contentText};
    ${({ disabled }) =>
      !disabled &&
      css`
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
      `};
  }
`

export const ToggleInput = styled.input`
  /* visually hidden but still accessible */
  border: 0;
  clip: rect(0 0 0 0);
  cursor: inherit;
  height: 1px;
  margin: -1px;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
`

export const Wrap = styled.div<{ disabled?: boolean; hasFocus?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  outline: none;

  ${({ hasFocus }) =>
    hasFocus &&
    css`
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: 4px;
    `};
`
