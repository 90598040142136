import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M9.88224574,7.6068 L12.2000457,7.6068 C12.6083457,7.6068 12.9404457,7.2747 12.9404457,6.8661 C12.9404457,5.5506 13.7933457,4.4808 14.8415457,4.4808 L15.3401457,4.4808 C16.3883457,4.4808 17.2412457,5.5506 17.2412457,6.8661 C17.2412457,7.2747 17.5736457,7.6068 17.9819457,7.6068 L20.3000457,7.6068 C20.3045457,8.5029 20.1689457,9.1881 20.0303457,9.3528 L10.1510457,9.3528 C10.0127457,9.1881 9.87714574,8.5029 9.88224574,7.6068 M10.1063457,10.8342 L20.0756457,10.8342 C20.4716457,10.8342 20.8265457,10.6746 21.1016457,10.3722 C21.9425457,9.4503 21.7838457,7.233 21.7433457,6.798 C21.7079457,6.4146 21.3908457,6.1257 21.0059457,6.1257 L18.6605457,6.1257 C18.3515457,4.3107 16.9643457,3 15.3401457,3 L14.8415457,3 C13.2170457,3 11.8298457,4.3107 11.5214457,6.1257 L9.17604574,6.1257 C8.79084574,6.1257 8.47404574,6.4146 8.43864574,6.798 C8.39814574,7.233 8.23914574,9.4503 9.08004574,10.3722 C9.35514574,10.6746 9.71034574,10.8342 10.1063457,10.8342" />
    <path d="M25.1502306,8.0219519 C24.4299306,7.3973519 23.5344306,7.5017519 23.4342306,7.5158519 C23.0322306,7.5734519 22.7583306,7.9364519 22.8105306,8.3423519 C22.8618306,8.7407519 23.2329306,9.0311519 23.6337306,8.9834519 C23.6364306,8.9840519 23.9667306,8.9501519 24.1863306,9.1466519 C24.4269306,9.3617519 24.4773306,9.7964519 24.4773306,10.1234519 L24.4773306,24.3278519 C24.4782306,24.4448519 24.4212306,24.9782519 24.1440306,25.2566519 C24.0030306,25.3988519 23.8053306,25.4678519 23.5395306,25.4678519 L6.6189306,25.4678519 C5.7573306,25.4678519 5.6832306,24.5915519 5.6811306,24.3236519 L5.6811306,10.1234519 C5.6811306,9.7964519 5.7315306,9.3617519 5.9718306,9.1466519 C6.1785306,8.9618519 6.4824306,8.9792519 6.5139306,8.9822519 C6.9183306,9.0380519 7.2939306,8.7563519 7.3515306,8.3540519 C7.3800306,8.1581519 7.3302306,7.9634519 7.2114306,7.8047519 C7.0929306,7.6466519 6.9195306,7.5440519 6.7239306,7.5158519 C6.6240306,7.5014519 5.7324306,7.3937519 5.0079306,8.0219519 C4.4718306,8.4866519 4.2000306,9.1937519 4.2000306,10.1234519 L4.2000306,24.3191519 C4.1991306,24.4424519 4.2129306,25.5464519 4.9635306,26.3009519 C5.3850306,26.7248519 5.9574306,26.9489519 6.6189306,26.9489519 L23.5395306,26.9489519 C24.2007306,26.9489519 24.7731306,26.7248519 25.1943306,26.3012519 C25.9428306,25.5491519 25.9587306,24.4463519 25.9581306,24.3236519 L25.9581306,10.1234519 C25.9581306,9.1937519 25.6863306,8.4869519 25.1502306,8.0219519" />
    <path d="M7.50587996,18.6588768 C7.48127996,18.8550768 7.53437996,19.0494768 7.65557996,19.2054768 L9.92417996,22.1283768 C10.06548,22.3104768 10.27878,22.4148768 10.50888,22.4148768 C10.52988,22.4148768 10.55058,22.4142768 10.57248,22.4124768 C10.82358,22.3905768 11.04408,22.2447768 11.16258,22.0221768 L15.59838,13.6881768 C15.69108,13.5135768 15.71058,13.3134768 15.65268,13.1241768 C15.59478,12.9348768 15.46668,12.7794768 15.29208,12.6867768 C14.93208,12.4947768 14.48328,12.6321768 14.29068,12.9924768 L10.39278,20.3163768 L8.82557996,18.2970768 C8.70437996,18.1407768 8.52947996,18.0411768 8.33327996,18.0165768 C8.13737996,17.9910768 7.94267996,18.0450768 7.78667996,18.1662768 C7.63037996,18.2877768 7.53077996,18.4626768 7.50587996,18.6588768" />
    <path d="M16.8,15.7404 C16.8,16.1487 17.1321,16.4811 17.5404,16.4811 L22.1661,16.4811 C22.5744,16.4811 22.9065,16.1487 22.9065,15.7404 C22.9065,15.3324 22.5744,15 22.1661,15 L17.5404,15 C17.1321,15 16.8,15.3324 16.8,15.7404" />
    <path d="M22.4286,20.4 L15.1407,20.4 C14.7324,20.4 14.4,20.7321 14.4,21.1404 C14.4,21.5487 14.7324,21.8811 15.1407,21.8811 L22.4286,21.8811 C22.8369,21.8811 23.1693,21.5487 23.1693,21.1404 C23.1693,20.7321 22.8369,20.4 22.4286,20.4" />
  </React.Fragment>,
  'Inventory'
)
