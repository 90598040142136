import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

export const StyledToolTip = styled.div`
  .customTheme {
    max-width: 300px;
    background-color: ${BrandColors.middleRedPurple};
    border-color: ${BrandColors.middleRedPurple};
    opacity: 0;
    padding: 0.5rem 0.6875rem;
    margin: 0;
    visibility: unset;
    transition: margin 350ms ease, opacity 450ms ease;
    border-radius: 0.1875rem;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: inherit;

    color: ${BrandColors.white};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    text-align: left;
    &.place-left:after {
      border-left: 0.4375rem solid ${BrandColors.middleRedPurple};
    }
    &.place-right:after {
      border-right: 0.4375rem solid ${BrandColors.middleRedPurple};
    }
    &.place-top:after {
      border-top: 0.4375rem solid ${BrandColors.middleRedPurple};
    }
    &.place-bottom:after {
      border-bottom: 0.4375rem solid ${BrandColors.middleRedPurple};
    }
  }
  .show {
    opacity: 0.9;
    &.place-left {
      margin-left: -10px;
    }
    &.place-right {
      margin-left: 10px;
    }
    &.place-top {
      margin-top: -10px;
    }
    &.place-bottom {
      margin-top: 10px;
    }
  }
`
