import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M9.5001,4.4001 C9.0516,4.4001 8.7,4.7514 8.7,5.1999 L8.7,8.1999 C8.7,8.6484 9.0516,9 9.5001,9 C9.9489,9 10.3002,8.6484 10.3002,8.1999 L10.3002,5.1999 C10.3002,4.7514 9.9489,4.4001 9.5001,4.4001 L9.5001,4.4001 Z M20.5002,4.4001 C20.0514,4.4001 19.7001,4.7514 19.7001,5.1999 L19.7001,8.1999 C19.7001,8.6484 20.0514,9 20.5002,9 C20.9487,9 21.3,8.6484 21.3,8.1999 L21.3,5.1999 C21.3,4.7514 20.9487,4.4001 20.5002,4.4001 L20.5002,4.4001 Z M5.7,7.4001 C4.9833,7.4001 4.4001,7.983 4.4001,8.7 L4.4001,24.3 C4.4001,25.017 4.9833,25.5999 5.7,25.5999 L24.3003,25.5999 C25.017,25.5999 25.6002,25.017 25.6002,24.3 L25.6002,8.9106 C25.6002,8.9001 25.6023,8.8899 25.6038,8.8794 L25.6128,8.8053 L25.6038,8.7315 C25.6023,8.7207 25.6002,8.7105 25.6002,8.6997 C25.6002,7.983 25.017,7.4001 24.3003,7.4001 L22.7004,7.4001 L22.7004,8.1999 C22.7004,9.4131 21.7131,10.4001 20.5002,10.4001 C19.2873,10.4001 18.3,9.4131 18.3,8.1999 L18.3,7.4001 L11.7,7.4001 L11.7,8.1999 C11.7,9.4131 10.7133,10.4001 9.5001,10.4001 C8.2872,10.4001 7.3002,9.4131 7.3002,8.1999 L7.3002,7.4001 L5.7,7.4001 Z M5.7,27 C4.2114,27 3,25.7889 3,24.3 L3,8.7 C3,7.2111 4.2114,6 5.7,6 L7.3002,6 L7.3002,5.1999 C7.3002,3.987 8.2872,3 9.5001,3 C10.7133,3 11.7,3.987 11.7,5.1999 L11.7,6 L18.3,6 L18.3,5.1999 C18.3,3.987 19.2873,3 20.5002,3 C21.7131,3 22.7004,3.987 22.7004,5.1999 L22.7004,6 L24.3003,6 C25.7889,6 27.0003,7.2111 27.0003,8.7 C27,8.7102 26.988,8.8053 26.988,8.8053 C26.988,8.8053 27,8.9004 27.0003,8.9109 L27.0003,24.3 C27.0003,25.7889 25.7889,27 24.3003,27 L5.7,27 Z" />
    <path d="M11.7,19.2 L18.3,19.2 L18.3,14.6001 L11.7,14.6001 L11.7,19.2 Z M18.9999,23.6001 C18.6141,23.6001 18.3,23.286 18.3,22.9002 L18.3,20.6001 L11.7,20.6001 L11.7,22.9002 C11.7,23.286 11.3859,23.6001 10.9998,23.6001 C10.614,23.6001 10.2999,23.286 10.2999,22.9002 L10.2999,20.6001 L6.9999,20.6001 C6.6141,20.6001 6.3,20.286 6.3,19.9002 C6.3,19.5141 6.6141,19.2 6.9999,19.2 L10.2999,19.2 L10.2999,14.6001 L6.9999,14.6001 C6.6141,14.6001 6.3,14.286 6.3,13.9002 C6.3,13.5141 6.6141,13.2 6.9999,13.2 L23.0001,13.2 C23.3859,13.2 23.7,13.5141 23.7,13.9002 C23.7,14.286 23.3859,14.6001 23.0001,14.6001 L19.7001,14.6001 L19.7001,19.2 L23.0001,19.2 C23.3859,19.2 23.7,19.5141 23.7,19.9002 C23.7,20.286 23.3859,20.6001 23.0001,20.6001 L19.7001,20.6001 L19.7001,22.9002 C19.7001,23.286 19.386,23.6001 18.9999,23.6001 L18.9999,23.6001 Z" />
  </React.Fragment>,
  'Calendar'
)
