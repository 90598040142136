import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M14.9268,13.6536 C13.7265,13.6536 12.75,12.6771 12.75,11.4768 C12.75,10.2765 13.7265,9.3 14.9268,9.3 C16.1271,9.3 17.1036,10.2765 17.1036,11.4768 C17.1036,12.6771 16.1271,13.6536 14.9268,13.6536" />
    <path d="M14.997,4.4658 C13.0527,4.4658 11.3682,5.1645 9.9909,6.5424 C8.6526,7.8804 7.9152,9.6594 7.9152,11.5518 C7.9152,13.4457 8.6532,15.2244 9.9933,16.5612 C10.0755,16.641 12.018,18.5448 13.9641,21.6381 L14.9952,23.2773 L16.0305,21.6405 C17.9193,18.6558 19.8222,16.7469 20.0331,16.5387 C21.3576,15.2154 22.0869,13.4445 22.0869,11.5518 C22.0869,9.6588 21.3495,7.8801 20.01,6.543 C18.6732,5.2035 16.8927,4.4658 14.997,4.4658 M14.9976,26.8572 C12.9717,21.5691 9.1107,17.7513 8.9466,17.5908 C7.3368,15.9813 6.45,13.8363 6.45,11.5521 C6.45,9.2676 7.3398,7.1205 8.9547,5.5065 C10.5708,3.8901 12.7164,3 14.997,3 C17.2824,3 19.4307,3.8898 21.0468,5.5059 C22.6626,7.1211 23.5524,9.2685 23.5524,11.5527 C23.5524,13.8366 22.6626,15.9843 21.0471,17.5989 C20.883,17.7597 17.0223,21.5751 14.9976,26.8572" />
  </React.Fragment>,
  'Location'
)
