import { animated, useSpring } from 'react-spring'
import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

type FontWeight = 'bold' | 'normal' | '200' | '300' | '500'

const Wrap = styled(animated.div)<{
  fontWeight: FontWeight
  fontSize: string
}>`
  /** flexbox */
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  /** font */
  color: ${BrandColors.pastelBlue};
  font-family: inherit;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  height: 100%;
  margin: auto;
  opacity: 0.4;
  padding: 3rem;
  text-align: center;

  > span {
    display: block;
  }
`

export interface CenteredMessageProps {
  /** text content of your message */
  children: React.ReactChild
  /** css string value, ie: '2rem' */
  fontSize?: string
  fontWeight?: FontWeight
}

/**
 *  Use this to display a message in the center of a content area component.
 *  Content has a fade in transition
 *  Can be used for error message or general feedback.
 */
export const CenteredMessage = ({
  fontSize = '1.5rem',
  fontWeight = 'bold',
  ...rest
}: CenteredMessageProps) => {
  const style = useSpring({
    opacity: 1,
    transform: 'translateY(0rem)',
    from: {
      opacity: 0,
      transform: 'translateY(0.5rem)',
    },
  })
  return (
    <Wrap fontSize={fontSize} fontWeight={fontWeight} {...rest} style={style} />
  )
}
