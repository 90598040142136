import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

export const StyledRadioButton = styled.input`
  appearance: none;
  border-radius: 50%;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  font-family: inherit;
  font-weight: normal;

  border: 1px solid ${BrandColors.azureishWhite};
  transition: 0.2s all linear;
  outline: none;
  margin-right: 1rem;

  &:checked {
    border: 5px solid ${BrandColors.mountainMeadow};
    background-color: white;
  }
`
export const RadioButtonLabel = styled.label`
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.95rem;
  min-height: 3.5rem;
  color: ${BrandColors.middleRedPurple};
`

export const RadioButtonWrap = styled.div`
  padding: 0 1rem;
  &:active,
  &:hover,
  &:focus,
  &:focus-within {
    background-color: ${BrandColors.duckEggGreen};
    cursor: pointer;
  }
`
