import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M4.5,25.5863964 L25.6704,25.5863964 L25.6704,20.0468964 C25.3128,19.4729964 22.4286,18.5747964 19.3188,18.0575964 L15.6576,22.3838964 C15.3729,22.7207964 14.7975,22.7207964 14.5128,22.3838964 L10.8516,18.0575964 C7.7418,18.5753964 4.8573,19.4735964 4.5,20.0471964 L4.5,25.5863964 Z M26.4204,27.0863964 L3.75,27.0863964 C3.3357,27.0863964 3,26.7506964 3,26.3363964 L3,19.9982964 C3,18.1385964 7.3701,17.0741964 11.0364,16.5089964 C11.2947,16.4684964 11.5548,16.5665964 11.7231,16.7657964 L15.0852,20.7383964 L18.4473,16.7657964 C18.6159,16.5668964 18.8754,16.4693964 19.134,16.5089964 C22.8003,17.0741964 27.1704,18.1385964 27.1704,19.9982964 L27.1704,26.3363964 C27.1704,26.7506964 26.8347,27.0863964 26.4204,27.0863964 L26.4204,27.0863964 Z" />
    <path d="M15.0543,4.8 C12.8739,4.8 11.1,6.5739 11.1,8.7543 C11.1,10.9344 12.8739,12.7083 15.0543,12.7083 C17.2347,12.7083 19.0086,10.9344 19.0086,8.7543 C19.0086,6.5739 17.2347,4.8 15.0543,4.8 M15.0543,14.2083 C12.0471,14.2083 9.6,11.7615 9.6,8.7543 C9.6,5.7468 12.0471,3.3 15.0543,3.3 C18.0615,3.3 20.5086,5.7468 20.5086,8.7543 C20.5086,11.7615 18.0615,14.2083 15.0543,14.2083" />
  </React.Fragment>,
  'Employees'
)
