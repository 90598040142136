import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M14.93319,5.15384146 C12.42759,8.24560976 7.50009,15.0410366 7.50009,18.4980488 C7.50009,22.7145122 10.62519,25.774878 14.93049,25.774878 C19.30539,25.774878 22.36119,22.7825 22.36119,18.4980488 C22.36119,14.9742683 17.43879,8.22091463 14.93319,5.15384146 M14.93049,27.2992683 C9.75579,27.2992683 6.00009,23.5980488 6.00009,18.4980488 C6.00009,13.4270122 14.02029,3.87884146 14.36169,3.47487805 L14.92779,2.8047561 L15.49719,3.47213415 C15.83889,3.8727439 23.86119,13.3367683 23.86119,18.4980488 C23.86119,23.5980488 20.10519,27.2992683 14.93049,27.2992683" />
    <path d="M10.95702,17.7980793 C10.87212,17.7980793 10.78602,17.7834451 10.70142,17.7523476 C10.31232,17.6090549 10.11072,17.1718598 10.25172,16.776128 C10.27182,16.7203354 10.74732,15.3935061 11.59572,13.6166768 C12.46002,11.8053963 13.93692,9.84807927 13.99932,9.76545732 C14.25192,9.43222561 14.72262,9.36911585 15.05082,9.62643293 C15.37902,9.88314024 15.44052,10.3614939 15.18822,10.6953354 C15.17382,10.7142378 13.74132,12.613628 12.94512,14.2819207 C12.13062,15.9880183 11.66682,17.2825305 11.66232,17.2953354 C11.55162,17.6053963 11.26362,17.7980793 10.95702,17.7980793" />
  </React.Fragment>,
  'Tinctures'
)
