import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M11.763,18.1506 L11.7639,18.2631 C16.9413,18.2799 18.4191,19.6992 18.7491,20.1342 L18.7659,20.1564 L18.7659,24.1452 L4.7628,24.1452 L4.7628,20.1525 L4.7793,20.1303 C5.1,19.6965 6.5517,18.2799 11.7633,18.2631 L11.763,18.1506 Z M20.184,19.7181 C20.1093,19.5144 19.7733,18.7977 18.5769,18.1221 C17.0619,17.2668 14.8122,16.8225 11.9127,16.8051 L11.8896,16.8 L11.6481,16.8021 C8.7195,16.8225 6.4665,17.2668 4.9518,18.1221 C3.7527,18.7989 3.4182,19.5153 3.3444,19.7181 C3.315,19.7976 3.3,19.8819 3.3,19.9686 L3.3,24.8763 C3.3,25.2795 3.6282,25.6074 4.0317,25.6074 L19.497,25.6074 C19.9002,25.6074 20.2284,25.2795 20.2284,24.8763 L20.2284,19.9686 C20.2284,19.8825 20.2134,19.7982 20.184,19.7181 L20.184,19.7181 Z" />
    <path d="M8.6628,10.7691 C8.6628,9.1116 10.0116,7.7628 11.6691,7.7628 C13.3269,7.7628 14.6757,9.1116 14.6757,10.7691 C14.6757,12.4266 13.3269,13.7754 11.6691,13.7754 C10.0116,13.7754 8.6628,12.4266 8.6628,10.7691 M11.6691,15.2379 C14.1336,15.2379 16.1385,13.2333 16.1385,10.7691 C16.1385,8.3046 14.1336,6.3 11.6691,6.3 C9.2049,6.3 7.2,8.3046 7.2,10.7691 C7.2,13.2333 9.2049,15.2379 11.6691,15.2379" />
    <path d="M26.652,17.6181 C26.5773,17.4144 26.2413,16.698 25.0449,16.0224 C23.5299,15.1668 21.2802,14.7228 18.3807,14.7051 L18.3576,14.7 L18.1161,14.7021 C17.7762,14.7045 17.4462,14.7132 17.1243,14.727 C16.9284,14.9211 16.7208,15.1032 16.5,15.2691 C17.7744,15.4704 18.8799,15.7875 19.8105,16.2174 C23.7186,16.4817 24.9234,17.6472 25.2171,18.0345 L25.2336,18.0564 L25.2336,22.0455 L22.8156,22.0455 L22.8156,23.5077 L25.965,23.5077 C26.3682,23.5077 26.6961,23.1795 26.6961,22.7763 L26.6961,17.8686 C26.6961,17.7828 26.6814,17.6985 26.652,17.6181" />
    <path d="M16.6056,6.0189 C17.028,5.7921 17.5101,5.6628 18.0219,5.6628 C19.6797,5.6628 21.0285,7.0116 21.0285,8.6694 C21.0285,10.1661 19.9275,11.4075 18.4935,11.6349 C18.3879,12.1638 18.2127,12.6675 17.9766,13.1361 C17.9919,13.1361 18.0066,13.1382 18.0219,13.1382 C20.4864,13.1382 22.4913,11.1336 22.4913,8.6694 C22.4913,6.2049 20.4864,4.2 18.0219,4.2 C16.9971,4.2 16.0548,4.5504 15.3,5.1327 C15.7749,5.3703 16.2129,5.6688 16.6056,6.0189" />
  </React.Fragment>,
  'CustomerGroups'
)
