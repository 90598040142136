import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M12.42975,25.84434 C12.30675,25.84434 12.18225,25.81404 12.06735,25.75074 L11.32695,25.34094 L8.34885,25.73754 C8.10915,25.76874 7.87035,25.68414 7.70475,25.50894 C7.53915,25.33374 7.46805,25.08924 7.51335,24.85254 L9.10695,16.53984 C9.18495,16.13304 9.57855,15.86754 9.98475,15.94464 C10.39155,16.02264 10.65795,16.41534 10.57995,16.82244 L9.18255,24.11304 L11.37435,23.82144 C11.53575,23.80044 11.69595,23.83104 11.83635,23.90874 L12.79335,24.43824 C13.15575,24.63834 13.28685,25.09494 13.08645,25.45734 C12.94965,25.70484 12.69375,25.84434 12.42975,25.84434" />
    <path d="M15.58239,26.88906 C15.47409,26.88906 15.36519,26.86566 15.26409,26.81826 C15.04659,26.71626 14.89089,26.51616 14.84589,26.28036 L13.42869,18.88656 C13.35099,18.47976 13.61739,18.08676 14.02419,18.00876 C14.43159,17.93166 14.82399,18.19716 14.90169,18.60426 L16.12449,24.98226 L18.06489,23.90856 C18.20529,23.83086 18.36549,23.79966 18.52689,23.82126 L20.71869,24.11316 L19.32129,16.82226 C19.24329,16.41546 19.50969,16.02246 19.91649,15.94476 C20.32419,15.86766 20.71629,16.13286 20.79429,16.53996 L22.38789,24.85266 C22.43319,25.08936 22.36209,25.33356 22.19649,25.50876 C22.03089,25.68396 21.79149,25.76856 21.55209,25.73736 L18.57459,25.34106 L15.94539,26.79546 C15.83259,26.85786 15.70779,26.88906 15.58239,26.88906" />
    <path d="M10.79733,12.82245 L12.96783,13.02615 C13.14663,13.04265 13.31373,13.12335 13.43823,13.25265 L14.95083,14.82285 L16.46343,13.25265 C16.58793,13.12335 16.75473,13.04265 16.93353,13.02615 L19.10403,12.82245 L18.81963,10.66095 C18.79623,10.48275 18.83763,10.30245 18.93573,10.15245 L20.13003,8.32845 L18.26283,7.20315 C18.10893,7.11015 17.99343,6.96555 17.93733,6.79485 L17.25543,4.72395 L15.21183,5.48235 C15.04293,5.54505 14.85813,5.54505 14.69013,5.48235 L12.64563,4.72395 L11.96433,6.79485 C11.90793,6.96555 11.79243,7.11015 11.63883,7.20315 L9.77133,8.32845 L10.96593,10.15245 C11.06403,10.30245 11.10513,10.48275 11.08173,10.66095 L10.79733,12.82245 Z M14.95083,16.65375 C14.74683,16.65375 14.55213,16.57095 14.41053,16.42425 L12.55083,14.49345 L9.88203,14.24325 C9.67893,14.22435 9.49263,14.12355 9.36603,13.96425 C9.23883,13.80465 9.18183,13.60095 9.20853,13.39875 L9.55833,10.74135 L8.09013,8.49855 C7.97853,8.32815 7.94103,8.11965 7.98633,7.92075 C8.03163,7.72215 8.15583,7.55055 8.33043,7.44525 L10.62633,6.06195 L11.46423,3.51585 C11.52813,3.32205 11.66763,3.16275 11.85123,3.07455 C12.03453,2.98605 12.24693,2.97585 12.43743,3.04695 L14.95083,3.97905 L17.46363,3.04695 C17.65443,2.97585 17.86653,2.98605 18.05013,3.07425 C18.23343,3.16275 18.37323,3.32205 18.43713,3.51585 L19.27533,6.06195 L21.57093,7.44525 C21.74553,7.55055 21.86973,7.72215 21.91533,7.92075 C21.96063,8.11965 21.92313,8.32815 21.81153,8.49855 L20.34303,10.74135 L20.69283,13.39875 C20.71953,13.60095 20.66283,13.80465 20.53563,13.96425 C20.40873,14.12355 20.22243,14.22435 20.01933,14.24325 L17.35083,14.49345 L15.49113,16.42425 C15.34953,16.57095 15.15453,16.65375 14.95083,16.65375 L14.95083,16.65375 Z" />
  </React.Fragment>,
  'LoyaltyBadge'
)
