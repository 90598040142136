import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M22.5501,18.3606 C22.0533,18.1404 21.6426,18.0075 21.6426,18.0075 C23.0121,17.076 23.8002,15.9099 23.8002,14.8026 C23.8002,12.4179 20.145,9.7608 14.9001,9.7608 C9.1563,9.7608 4.5,6.7338 4.5,3 L6,3 C6,5.3847 9.6552,8.0421 14.9001,8.0421 C20.6439,8.0421 25.3002,11.0688 25.3002,14.8026 C25.3002,16.2969 24.5991,17.6571 23.367,18.7617 C23.367,18.7617 23.0469,18.5808 22.5501,18.3606" />
    <path d="M7.1187,11.4705 C7.4748,11.6298 8.0739,11.8245 8.0739,11.8245 C6.756,12.7464 6,13.8873 6,14.9718 C6,17.3565 9.6552,20.0139 14.8998,20.0139 L14.8998,20.0208 C20.6436,20.0208 25.2999,23.0475 25.2999,26.7813 L23.7999,26.7813 C23.7999,24.3966 20.145,21.7395 14.8998,21.7395 L14.8998,21.7326 C9.1563,21.7326 4.5,18.7056 4.5,14.9718 C4.5,13.5318 5.1927,12.1968 6.3732,11.1 C6.3732,11.1 6.7626,11.3112 7.1187,11.4705" />
    <path d="M23.1771,7.131 C24.5355,5.9886 25.3437,4.5558 25.3437,3 L23.8437,3 C23.8437,4.1304 23.0226,5.322 21.6,6.2628 C21.6,6.2628 21.9375,6.4263 22.2279,6.567 C22.5186,6.7074 23.1771,7.131 23.1771,7.131" />
    <path d="M7.1973,23.1912 C7.5573,23.3556 8.1165,23.5434 8.1165,23.5434 C6.7458,24.4782 6,25.6293 6,26.7429 L4.5,26.7429 C4.5,25.3371 5.2635,23.9178 6.4263,22.8 C6.4263,22.8 6.8373,23.0268 7.1973,23.1912" />
  </React.Fragment>,
  'Strains'
)
