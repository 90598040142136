import styled from 'styled-components/macro'

export const Wrap = styled.div<{ extraStyles?: string }>`
  z-index: 5;
  background-color: rgba(244, 247, 251, 0.5);
  backdrop-filter: blur(10px);
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // overrides:
  ${({ extraStyles }) => extraStyles}
`
