import { BrandColors } from 'style/BrandColors'
import { AvailColors } from '.'
import styled, { keyframes } from 'styled-components/macro'

const colorSpectrum = {
  blue: `${BrandColors.hanBlue}`,
  green: `${BrandColors.crayola}`,
  gray: `${BrandColors.taupeGray}`,
  ruby: `${BrandColors.ruby}`,
}

const getColor = ({ color }: { color: AvailColors }): string => {
  return colorSpectrum[color]
}

export const Wrap = styled.div`
  position: relative;
  min-width: 5rem;
`

export const StyledMessage = styled.div`
  font-weight: 200;
  color: ${getColor};
  margin-top: 1rem;
  text-align: center;
`
export const stemAnimation = keyframes`
  0%   { transform: translate(0,0) }
  30%  { transform: translate(0, 0)}
  45%  { transform: translate(22px, -22px)}
  50%  { transform: translate(22px, -22px)}
  70%  { transform: translate(0, 0)}
  100% { transform: translate(0, 0) }
`

export const scaleSmoke = keyframes`
  0% { opacity: 0; transform: scaleX(0.2) translate(0,0); }
  10% { opacity: 0.5; transform: scaleX(0.5) translate(0, -9px); }
  20% { opacity: 0.6; transform: scaleX(0.6) translate(0, -10px); }
  30% { opacity: 0.7; transform: scaleX(0.7) translate(0, -11px); }
  40% { opacity: 1; transform: scaleX(0.8) translate(0, -12px); }
  50% { opacity: 0; transform: scaleX(0.8) translate(0, -30px); }
  90% { opacity: 0; transform: scaleX(0) translate(0, 0); }
  100% { opacity: 0; transform: scaleX(0) translate(0, 0); }
`

export const AnimatedStem = styled.g`
  animation: ${stemAnimation} 6s infinite;
`

export const waterAnimation = keyframes`
  0%   { transform: translate(0,0) }
  50%  { transform: translate(11px, 0)}
  100% { transform: translate(0, 0) }
`

export const AnimatedWater = styled.g`
  animation: ${waterAnimation} 3s ease infinite 0s;
`
export const sideWays = keyframes`
  0% { margin-left:0px; }
  100% { margin-left:50px; }
`

const animateDaBubbles = (y1: string, y2: string) => {
  return keyframes`
    0%, 20%, 40%, 60%, 80%, 100% {  
      transform: translate3d(0, ${y1}, 0);
    }
    10%, 30%, 50%, 70%, 90% {   
      transform: translate3d(1px, ${y2}, 0);
    }
  `
}

export const AnimatedBubbles = styled.g`
  #bub-1 {
    animation: ${animateDaBubbles('-2px', '-10px')} 4s infinite;
  }
  #bub-2 {
    animation: ${animateDaBubbles('-8px', '-14px')} 5s infinite;
  }
  #bub-3 {
    animation: ${animateDaBubbles('0px', '-8px')} 6s infinite;
  }
  #bub-4 {
    animation: ${animateDaBubbles('-13px', '-28px')} 5s infinite;
  }
  #bub-5 {
    animation: ${animateDaBubbles('0px', '-5px')} 5s infinite;
  }
  #bub-6 {
    animation: ${animateDaBubbles('-12px', '-18px')} 6s infinite;
  }
  #bub-7 {
    animation: ${animateDaBubbles('-10px', '-25px')} 4s infinite;
  }
  #bub-8 {
    animation: ${animateDaBubbles('-12px', '-20px')} 6s infinite;
  }
  #bub-9 {
    animation: ${animateDaBubbles('-10px', '-15px')} 5s infinite;
  }
  #bub-10 {
    animation: ${animateDaBubbles('0px', '-10px')} 4s infinite;
  }
  #bub-11 {
    animation: ${animateDaBubbles('-5px', '-10px')} 6s infinite;
  }
  #bub-12 {
    animation: ${animateDaBubbles('-10px', '-25px')} 5s infinite;
  }
`

export const AnimatedSmoke = styled.g`
  animation: ${scaleSmoke} 6s linear infinite;
  transform-origin: center;
`

export const StyledBongLoader = styled.svg`
  .cls-1 {
    fill: ${getColor};
  }
  .cls-2 {
    opacity: 0.5;
  }
  .cls-3,
  .cls-4 {
    fill: rgba(255, 255, 255, 0.65);
  }
  .cls-3 {
    opacity: 0.3;
  }
  .cls-5 {
    fill: ${getColor};
  }
  .cls-6 {
    fill: #f8fcfe;
  }
  .cls-7 {
    fill: ${BrandColors.white};
    opacity: 0.6;
  }
`
