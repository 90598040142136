import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M19.2768,12.7536 C18.0765,12.7536 17.1,11.7771 17.1,10.5768 C17.1,9.3765 18.0765,8.4 19.2768,8.4 C20.4771,8.4 21.4536,9.3765 21.4536,10.5768 C21.4536,11.7771 20.4771,12.7536 19.2768,12.7536" />
    <path d="M25.2778827,14.8815 L15.0343827,25.125 L4.72518274,14.8152 L14.9680827,4.5726 L25.2727827,4.5864 L25.2778827,14.8815 Z M26.8447827,3.801 C26.8444827,3.3684 26.4922827,3.0159 26.0593827,3.0153 L14.6428827,3 C14.4328827,3 14.2351827,3.0819 14.0869827,3.2304 L3.33828274,13.9788 C2.87718274,14.4402 2.88888274,15.2022 3.36468274,15.6777 L14.1715827,26.4849 C14.4034827,26.7168 14.7217827,26.8503 15.0454827,26.8503 C15.3592827,26.8503 15.6526827,26.7297 15.8713827,26.5113 L26.6197827,15.7629 C26.7682827,15.6144 26.8498827,15.417 26.8498827,15.2067 L26.8447827,3.801 Z" />
  </React.Fragment>,
  'Specials'
)
