import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

export const StyledListItem = styled.li<{ isActive?: boolean }>`
  display: flex;
  align-items: center;

  background: ${({ isActive }) =>
    !isActive ? BrandColors.white : BrandColors.isabelline};
  color: ${BrandColors.middleRedPurple};
  cursor: pointer;
  fill: ${BrandColors.pictonBlue};

  font-weight: normal;
  font-size: 0.85rem;
  line-height: 2rem;

  padding: 0.5rem 1rem;
  position: relative;
  height: auto;
  z-index: 1;

  span {
    margin-right: 0.5rem;
  }
`
