import { BrandColors } from 'style/BrandColors'
import { MenuItem } from './MenuItem'
import styled, { css } from 'styled-components/macro'

const cardShadow = css`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`

const verticalScroll = css`
  overflow-x: hidden;
  overflow-y: scroll;
  /** allows smooth scrolling on tablets */
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 3px;
    height: 0px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${BrandColors.middleRedPurple};
  }
`

const Wrap = styled.menu`
  background: ${BrandColors.white};
  ${cardShadow};
  padding: 1rem;
  max-height: 20rem;
  ${verticalScroll};
`

export interface MenuItemType {
  display: string
  value: string
}

export interface MenuProps {
  /** menu options - array of objects: { display: string, value: string } */
  menuItems: MenuItemType[]
  /** hook which returns the selected menuItem.value */
  onMenuItemClick: (value: string) => void
}

/**
 *  Used to display a menu with a list of actions
 */
export const Menu = ({ menuItems, onMenuItemClick }: MenuProps) => {
  return (
    <Wrap role="menu">
      {menuItems.map((x) => {
        const display = x instanceof Object ? x.display : x
        const value = x instanceof Object ? x.value : x
        return (
          <MenuItem key={value} onClick={() => onMenuItemClick(value)}>
            {display}
          </MenuItem>
        )
      })}
    </Wrap>
  )
}
