import { css } from 'styled-components/macro'
import { BrandColors } from './BrandColors'

//ToDo: update with brand colors and styles
interface BorderColorProps {
  error?: boolean
  valid?: boolean
}

type ValueOf<T> = T[keyof T]

export const baseBorderColor = (
  props: BorderColorProps
): ValueOf<typeof BrandColors> => {
  const { error, valid } = props

  return error
    ? `${BrandColors.ruby}`
    : valid
    ? `${BrandColors.mountainMeadow}`
    : `${BrandColors.azureishWhite}`
}

export const inputBaseStyle = css`
  background-color: ${BrandColors.white};
  border: 1px solid ${baseBorderColor};
  border-radius: 3px;
  color: ${BrandColors.middleRedPurple};
  font-family: inherit;
  font-weight: 400;
  font-size: 1rem;
  font-variant-numeric: tabular-nums;
  outline: none;
  padding: 1rem;
  transition: border 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ::placeholder {
    color: ${BrandColors.graniteGray};
    font-family: inherit;
    font-weight: 300;
    font-style: oblique;
    user-select: none;
  }

  :disabled {
    background-color: ${BrandColors.whiteSmoke};
    border-color: ${BrandColors.isabelline};
    color: ${BrandColors.pastelBlue};
    user-select: none;

    ::placeholder {
      color: currentcolor;
    }
  }

  :focus {
    border-color: ${BrandColors.mountainMeadow};
  }
`
