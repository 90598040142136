import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export const Med = createSvgIcon(
  <React.Fragment>
    <path d="M4.9602,18.8547 L10.353,18.8547 C10.8105,18.8547 11.1828,19.2273 11.1828,19.6854 L11.1828,25.0776 L18.8553,25.0776 L18.8553,19.6854 C18.8553,19.2273 19.2282,18.8547 19.686,18.8547 L25.0779,18.8547 L25.0779,11.1819 L19.686,11.1819 C19.2282,11.1819 18.8553,10.8099 18.8553,10.3524 L18.8553,4.9596 L11.1828,4.9596 L11.1828,10.3524 C11.1828,10.8099 10.8105,11.1819 10.353,11.1819 L4.9602,11.1819 L4.9602,18.8547 Z M10.8576,26.7378 C10.1214,26.7378 9.5226,26.139 9.5226,25.4028 L9.5226,20.5155 L4.635,20.5155 C3.8988,20.5155 3.3,19.9167 3.3,19.1808 L3.3,10.8576 C3.3,10.1214 3.8988,9.5226 4.635,9.5226 L9.5226,9.5226 L9.5226,4.6353 C9.5226,3.8988 10.1214,3.3 10.8576,3.3 L19.1814,3.3 C19.9176,3.3 20.5167,3.8988 20.5167,4.6353 L20.5167,9.5226 L25.4031,9.5226 C26.1399,9.5226 26.739,10.1214 26.739,10.8576 L26.739,19.1808 C26.739,19.9167 26.1399,20.5155 25.4031,20.5155 L20.5167,20.5155 L20.5167,25.4028 C20.5167,26.139 19.9176,26.7378 19.1814,26.7378 L10.8576,26.7378 Z" />
  </React.Fragment>,
  'Med'
)

export default Med
