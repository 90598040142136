import { animated } from 'react-spring'
import { TabColorType } from '.'
import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

export const TabList = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  padding: 0 2rem 0px 1rem;
`

export const Tab = styled.button.attrs({
  role: 'tab',
  type: 'button',
})<{ selected: boolean }>`
  background: none;
  border: none;
  border-bottom: 3px solid ${BrandColors.isabelline};
  cursor: pointer;
  flex: 1;
  font-family: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  height: inherit;
  outline: none;
  padding: 1rem 0.5rem;
  text-align: center;
  position: relative;
  user-select: none;
  letter-spacing: 0.025rem;
  text-transform: uppercase;

  transition: border-color 155ms linear;

  ${({ selected }) =>
    selected &&
    `
    border-color: ${BrandColors.ruby};
  `};
`

export const StyledTab = styled(animated.div)<{
  active?: string
}>`
  color: ${BrandColors.middleRedPurple};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  display: flex;
  font-family: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.05rem;
  margin: 0 0.75rem;
  outline: none;
  padding: 1rem 0;
  position: relative;
  text-transform: uppercase;
  user-select: none;
  flex-shrink: 0;

  ${'' /* prevents flash of color on touch devices */};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

export const IndicatorWrap = styled.div`
  border-radius: 2px;
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  transform: translateY(2.5px);
`

export const Line = styled(animated.div)<{
  focus?: string
  color: TabColorType
}>`
  background-color: ${({ focus, color }) =>
    !!focus
      ? BrandColors.azureishWhite
      : color === 'secondary'
      ? BrandColors.hanBlue
      : BrandColors.ruby};
  border-radius: inherit;
  position: inherit;
  height: inherit;
  width: inherit;
  transition: background-color 255ms ease-in;
`
