import createSvgIcon from './createSvgIcon'
import styled from 'styled-components/macro'

const SparklesIcon = createSvgIcon(
  <>
    <path
      d="M8.375 4.1875C8.375 4.34616 8.31197 4.49831 8.19979 4.6105C8.0876 4.72269 7.93544 4.78571 7.77679 4.78571C6.9835 4.78571 6.22271 5.10084 5.66178 5.66178C5.10084 6.22271 4.78571 6.9835 4.78571 7.77679C4.78571 7.93544 4.72269 8.0876 4.6105 8.19979C4.49831 8.31197 4.34616 8.375 4.1875 8.375C4.02884 8.375 3.87669 8.31197 3.7645 8.19979C3.65231 8.0876 3.58929 7.93544 3.58929 7.77679C3.58929 6.9835 3.27416 6.22271 2.71322 5.66178C2.15229 5.10084 1.3915 4.78571 0.598214 4.78571C0.439558 4.78571 0.2874 4.72269 0.175213 4.6105C0.0630261 4.49831 0 4.34616 0 4.1875C0 4.02884 0.0630261 3.87669 0.175213 3.7645C0.2874 3.65231 0.439558 3.58929 0.598214 3.58929C1.3915 3.58929 2.15229 3.27416 2.71322 2.71322C3.27416 2.15229 3.58929 1.3915 3.58929 0.598214C3.58929 0.439558 3.65231 0.2874 3.7645 0.175213C3.87669 0.0630261 4.02884 0 4.1875 0C4.34616 0 4.49831 0.0630261 4.6105 0.175213C4.72269 0.2874 4.78571 0.439558 4.78571 0.598214C4.78571 1.3915 5.10084 2.15229 5.66178 2.71322C6.22271 3.27416 6.9835 3.58929 7.77679 3.58929C7.93544 3.58929 8.0876 3.65231 8.19979 3.7645C8.31197 3.87669 8.375 4.02884 8.375 4.1875Z"
      fill="#FFC04F"
    />
    <path
      d="M8.1875 12.75C8.1875 12.866 8.14141 12.9773 8.05936 13.0594C7.97731 13.1414 7.86603 13.1875 7.75 13.1875C7.51794 13.1875 7.29538 13.2797 7.13128 13.4438C6.96719 13.6079 6.875 13.8304 6.875 14.0625C6.875 14.1785 6.82891 14.2898 6.74686 14.3719C6.66481 14.4539 6.55353 14.5 6.4375 14.5C6.32147 14.5 6.21019 14.4539 6.12814 14.3719C6.04609 14.2898 6 14.1785 6 14.0625C6 13.8304 5.90781 13.6079 5.74372 13.4438C5.57962 13.2797 5.35706 13.1875 5.125 13.1875C5.00897 13.1875 4.89769 13.1414 4.81564 13.0594C4.73359 12.9773 4.6875 12.866 4.6875 12.75C4.6875 12.634 4.73359 12.5227 4.81564 12.4406C4.89769 12.3586 5.00897 12.3125 5.125 12.3125C5.35706 12.3125 5.57962 12.2203 5.74372 12.0562C5.90781 11.8921 6 11.6696 6 11.4375C6 11.3215 6.04609 11.2102 6.12814 11.1281C6.21019 11.0461 6.32147 11 6.4375 11C6.55353 11 6.66481 11.0461 6.74686 11.1281C6.82891 11.2102 6.875 11.3215 6.875 11.4375C6.875 11.6696 6.96719 11.8921 7.13128 12.0562C7.29538 12.2203 7.51794 12.3125 7.75 12.3125C7.86603 12.3125 7.97731 12.3586 8.05936 12.4406C8.14141 12.5227 8.1875 12.634 8.1875 12.75Z"
      fill="#FFC04F"
    />
    <path
      d="M15.5 7.0625C15.5 7.21168 15.4407 7.35476 15.3352 7.46025C15.2298 7.56574 15.0867 7.625 14.9375 7.625C14.3408 7.625 13.7685 7.86205 13.3465 8.28401C12.9246 8.70597 12.6875 9.27826 12.6875 9.875C12.6875 10.0242 12.6282 10.1673 12.5227 10.2727C12.4173 10.3782 12.2742 10.4375 12.125 10.4375C11.9758 10.4375 11.8327 10.3782 11.7273 10.2727C11.6218 10.1673 11.5625 10.0242 11.5625 9.875C11.5625 9.27826 11.3254 8.70597 10.9035 8.28401C10.4815 7.86205 9.90924 7.625 9.3125 7.625C9.16332 7.625 9.02024 7.56574 8.91475 7.46025C8.80926 7.35476 8.75 7.21168 8.75 7.0625C8.75 6.91332 8.80926 6.77024 8.91475 6.66475C9.02024 6.55926 9.16332 6.5 9.3125 6.5C9.90924 6.5 10.4815 6.26295 10.9035 5.84099C11.3254 5.41903 11.5625 4.84674 11.5625 4.25C11.5625 4.10082 11.6218 3.95774 11.7273 3.85225C11.8327 3.74676 11.9758 3.6875 12.125 3.6875C12.2742 3.6875 12.4173 3.74676 12.5227 3.85225C12.6282 3.95774 12.6875 4.10082 12.6875 4.25C12.6875 4.84674 12.9246 5.41903 13.3465 5.84099C13.7685 6.26295 14.3408 6.5 14.9375 6.5C15.0867 6.5 15.2298 6.55926 15.3352 6.66475C15.4407 6.77024 15.5 6.91332 15.5 7.0625Z"
      fill="#FFC04F"
    />
  </>,
  'Sparkles'
)

export default styled(SparklesIcon)`
  path {
    transform: translateX(4px) translateY(4px);
  }
`
