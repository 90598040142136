import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M14.5215,26.0367 C14.5347,26.0127 14.5341,25.9836 14.5191,25.9605 L15.2043,24.6726 C15.1905,24.651 15.1668,24.6378 15.141,24.6378 L4.5207,24.6378 C4.4832,24.6378 4.4643,24.6186 4.4643,24.5814 L4.4643,5.4204 C4.4643,5.3832 4.4832,5.3643 4.5207,5.3643 L15.141,5.3643 C15.1671,5.3643 15.1911,5.3508 15.2049,5.3289 L14.5197,4.0146 C14.5338,3.9915 14.5344,3.9621 14.5215,3.9384 C14.5083,3.9147 14.4831,3.9 14.4558,3.9 L4.5207,3.9 C3.6963,3.9 3,4.5963 3,5.4204 L3,24.5553 C3,25.3794 3.6963,26.0757 4.5207,26.0757 L14.4558,26.0757 C14.4831,26.0757 14.5083,26.0607 14.5215,26.0367" />
    <path d="M18.0399,3.939 C18.0264,3.963 18.0273,3.9921 18.0423,4.0152 L24.5403,14.2824 L8.775,14.2824 C8.7339,14.2824 8.7,14.316 8.7,14.3574 L8.7,15.6711 C8.7,15.7125 8.7339,15.7461 8.775,15.7461 L24.5403,15.7461 L18.0423,26.0133 C18.0273,26.0367 18.0264,26.0658 18.0399,26.0895 C18.0531,26.1135 18.0783,26.1285 18.1056,26.1285 L19.9191,26.1285 C19.9449,26.1285 19.9686,26.1153 19.9824,26.0937 L26.9739,15.0543 C26.982,15.042 26.9856,15.0282 26.9856,15.0141 C26.9856,15 26.982,14.9862 26.9739,14.9739 L19.9824,3.9348 C19.9686,3.9132 19.9449,3.9 19.9191,3.9 L18.1056,3.9 C18.0783,3.9 18.0531,3.915 18.0399,3.939" />
  </React.Fragment>,
  'LogOut'
)
