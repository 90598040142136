import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M19.9395,20.4 L10.3275,20.4 C9.9264,20.4 9.6,20.7261 9.6,21.1272 C9.6,21.5283 9.9264,21.8547 10.3275,21.8547 L19.9395,21.8547 C20.3406,21.8547 20.667,21.5283 20.667,21.1272 C20.667,20.7261 20.3406,20.4 19.9395,20.4" />
    <path d="M19.9395,15.3 L10.3275,15.3 C9.9264,15.3 9.6,15.6264 9.6,16.0275 C9.6,16.4286 9.9264,16.755 10.3275,16.755 L19.9395,16.755 C20.3406,16.755 20.667,16.4286 20.667,16.0275 C20.667,15.6264 20.3406,15.3 19.9395,15.3" />
    <path d="M19.9395,9.9 L16.9275,9.9 C16.5264,9.9 16.2,10.2261 16.2,10.6272 C16.2,11.0283 16.5264,11.3547 16.9275,11.3547 L19.9395,11.3547 C20.3406,11.3547 20.667,11.0283 20.667,10.6272 C20.667,10.2261 20.3406,9.9 19.9395,9.9" />
    <path d="M6.5547,25.4871 L6.5547,11.4552 L13.4469,11.4552 L13.4469,4.455 L23.1207,4.455 L23.1207,25.4871 L6.5547,25.4871 Z M24.0306,3 L12.0753,3 L5.1,10.0695 L5.1,26.397 C5.1,26.6976 5.3445,26.9421 5.6448,26.9421 L24.0306,26.9421 C24.3309,26.9421 24.5751,26.6976 24.5751,26.397 L24.5751,3.5448 C24.5751,3.2442 24.3309,3 24.0306,3 L24.0306,3 Z" />
  </React.Fragment>,
  'Reports'
)
