import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M6.76,14.25l6.8-6.8a.76.76,0,0,0,0-1.06.78.78,0,0,0-1,0L4.42,14.47a.75.75,0,0,0,0,1.06h0l8.07,8.08a.75.75,0,0,0,1.06,0h0a.75.75,0,0,0,0-1.06h0l-6.8-6.8v-1.5Z" />
    <path d="M15.66,15.82q-1.87,0-10.17-.07a.75.75,0,0,1,0-1.5c5.52.05,8.91.07,10.15.07,9.42,0,9.42-2.89,9.42-8.15a.75.75,0,0,1,1.5,0C26.58,12.16,26.05,15.82,15.66,15.82Z" />
  </React.Fragment>,
  'ArrowBackCurved'
)
