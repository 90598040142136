import { BrandColors } from 'style/BrandColors'
import styled, { css } from 'styled-components/macro'

export const AnimatedTriangleWrap = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
`

export const Placeholder = styled.span`
  color: ${BrandColors.graniteGray};
  font-weight: 300;
  font-style: oblique;
  text-transform: capitalize;
`

export const Wrap = styled.button<{ hasFocus?: boolean; isOpen?: boolean }>`
  align-items: center;
  background: ${BrandColors.white};
  border: 1px solid transparent;
  border-color: ${({ hasFocus, isOpen }) =>
    hasFocus || isOpen ? BrandColors.mountainMeadow : BrandColors.isabelline};
  border-radius: 3px;
  box-sizing: border-box;
  color: ${BrandColors.middleRedPurple};
  display: flex;
  flex: 1;
  flex-direction: row;
  font-family: inherit;
  font-weight: 300;
  font-size: 1rem;
  height: 3.25rem;
  outline: none;
  overflow: hidden;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  position: relative;
  width: 100%;

  span {
    margin-right: 0.5rem;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`
