import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M21.20658,26.5109926 C20.80608,26.5109926 20.48028,26.1892148 20.48028,25.7936593 C20.48028,25.3981037 20.80608,25.0763259 21.20658,25.0763259 L24.42108,25.0763259 L18.21228,18.9438815 C18.07518,18.8087704 18.00018,18.6289185 18.00018,18.4372148 C18.00018,18.2455111 18.07518,18.0656593 18.21228,17.9305481 C18.34908,17.795437 18.53148,17.7210667 18.72558,17.7210667 C18.91998,17.7210667 19.10208,17.795437 19.23858,17.9305481 L25.44738,24.0626963 L25.44738,20.8878815 C25.44738,20.4923259 25.77348,20.1702519 26.17398,20.1702519 C26.57448,20.1702519 26.90028,20.4923259 26.90028,20.8878815 L26.90028,25.7936593 C26.90028,26.1892148 26.57448,26.5109926 26.17398,26.5109926 L21.20658,26.5109926 Z" />
    <path d="M3.82152,26.5109926 C3.42102,26.5109926 3.09522,26.1892148 3.09522,25.7936593 L3.09522,20.8878815 C3.09522,20.4923259 3.42102,20.1702519 3.82152,20.1702519 C4.22202,20.1702519 4.54812,20.4923259 4.54812,20.8878815 L4.54812,24.0579556 L10.75662,17.9255111 C10.89462,17.7909926 11.07672,17.7169185 11.26932,17.7169185 C11.46282,17.7169185 11.64462,17.7921778 11.78142,17.9287704 C11.91882,18.0644741 11.99502,18.2452148 11.99532,18.4369185 C11.99562,18.6286222 11.92032,18.8087704 11.78322,18.9438815 L5.57442,25.0763259 L8.78892,25.0763259 C9.18942,25.0763259 9.51522,25.3981037 9.51522,25.7936593 C9.51522,26.1892148 9.18942,26.5109926 8.78892,26.5109926 L3.82152,26.5109926 Z" />
    <path d="M18.72573,11.7899259 C18.53163,11.7899259 18.34893,11.7155556 18.21213,11.5804444 C18.07533,11.4453333 18.00003,11.2651852 18.00003,11.0737778 C18.00003,10.8820741 18.07533,10.7022222 18.21213,10.5671111 L24.42093,4.43466667 L21.20673,4.43466667 C20.80623,4.43466667 20.48043,4.11288889 20.48043,3.71733333 C20.48043,3.32177778 20.80623,3 21.20673,3 L26.17383,3 C26.57433,3 26.90013,3.32177778 26.90013,3.71733333 L26.90013,8.62311111 C26.90013,9.01866667 26.57433,9.34074074 26.17383,9.34074074 C25.77333,9.34074074 25.44723,9.01866667 25.44723,8.62311111 L25.44723,5.4482963 L19.23873,11.5804444 C19.10223,11.7155556 18.91983,11.7899259 18.72573,11.7899259" />
    <path d="M11.26995,11.7899259 C11.07555,11.7899259 10.89315,11.7155556 10.75665,11.5804444 L4.54815,5.4482963 L4.54815,8.62311111 C4.54815,9.01866667 4.22205,9.34074074 3.82155,9.34074074 C3.42105,9.34074074 3.09525,9.01866667 3.09525,8.62311111 L3.09525,3.71733333 C3.09525,3.32177778 3.42105,3 3.82155,3 L8.78865,3 C9.18915,3 9.51495,3.32177778 9.51495,3.71733333 C9.51495,4.11288889 9.18915,4.43466667 8.78865,4.43466667 L5.57445,4.43466667 L11.78325,10.5671111 C11.92005,10.7022222 11.99565,10.8820741 11.99565,11.0737778 C11.99565,11.2651852 11.92005,11.4453333 11.78325,11.5804444 C11.64645,11.7155556 11.46375,11.7899259 11.26995,11.7899259" />
  </React.Fragment>,
  'Expand'
)
