import * as React from 'react'
import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

const Wrap = styled.div`
  background-color: ${BrandColors.middleRedPurple};
  height: 3rem;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  z-index: 2;
`

const Navigation = styled.nav`
  align-items: center;
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
`

export interface AppBarProps {
  navigation: React.ReactNode
  rightMenu?: React.ReactNode
}

/**
 * AppBar is used to contain the main navigation and app global menus.
 *
 * Should always be in view of user
 *
 * Has a fixed height of 3rem
 *
 * Nothing should overlap the AppBar, besides a modal
 *
 */
export const AppBar = ({ navigation, ...rest }: AppBarProps) => (
  <Wrap data-testid="wrap" {...rest}>
    <Navigation data-testid="navigation">{navigation}</Navigation>
  </Wrap>
)
