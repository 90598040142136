import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M10.4287899,19.8559273 C10.2137013,19.8559273 10.0141063,19.7773688 9.86585316,19.635187 C9.71820759,19.4936026 9.63709367,19.3060182 9.63709367,19.1070831 C9.63709367,18.9078494 9.71820759,18.7202649 9.86554937,18.5792779 L18.7734987,10.0797325 C18.9229671,9.93725195 19.122562,9.85869351 19.3352203,9.85869351 C19.5493975,9.85869351 19.7489924,9.93755065 19.8978532,10.0806286 C20.0458025,10.2219143 20.1269165,10.4089013 20.1269165,10.6081351 C20.1269165,10.8073688 20.0458025,10.9943558 19.8984608,11.1350442 L10.9905114,19.6345896 C10.841043,19.7770701 10.6414481,19.8559273 10.4287899,19.8559273" />
    <path d="M7.4876962,26.2147104 C6.30956962,26.2147104 5.08951899,25.7490351 4.30420253,24.9995935 C3.51189873,24.2435805 3,23.0135286 3,21.8656195 C3,21.2718013 3.13701266,20.4330481 3.78956962,19.8102558 L8.63635443,15.1848662 C9.29012658,14.5623727 10.1598987,14.2197623 11.0858734,14.2197623 C11.2930633,14.2197623 11.4996456,14.237087 11.7031899,14.2717364 L4.85103797,20.8076195 C4.58339241,21.0648013 4.44243038,21.4298143 4.44303797,21.8647234 C4.44394937,22.6521 4.91362025,23.5706065 5.36718987,24.0037234 C5.87453165,24.4882169 6.77346835,24.8394896 7.50440506,24.8394896 C7.96860759,24.8394896 8.36020253,24.7005935 8.63635443,24.437139 L15.5021772,17.8848273 C15.7023797,18.9473078 15.3539241,20.0396584 14.5491646,20.8076195 L9.70237975,25.4330091 C9.17407595,25.9369182 8.3875443,26.2147104 7.4876962,26.2147104" />
    <path d="M18.6783494,15.4964714 C18.4711595,15.4964714 18.264881,15.4788481 18.0610329,15.4444974 L24.9116658,8.90831558 C25.1793114,8.65113377 25.3202734,8.28552338 25.3196658,7.85061429 C25.3184506,7.06323766 24.8487797,6.14473117 24.3952101,5.71221169 C23.8875646,5.22771818 22.9886278,4.87614675 22.2582987,4.87614675 C21.7937924,4.87614675 21.4025013,5.01564026 21.1263494,5.27909481 L14.2620456,11.8311078 C14.0615392,10.7683286 14.4102987,9.67627662 15.2147544,8.90831558 L20.061843,4.28143247 C20.589843,3.77752338 21.3763747,3.50002987 22.2765266,3.50002987 C23.454957,3.50002987 24.6747038,3.96540649 25.4597165,4.71484805 C26.2523241,5.47115974 26.763919,6.70091299 26.763919,7.84852338 C26.7642228,8.44264026 26.6272101,9.28139351 25.9746532,9.90418571 L21.1278684,14.5313675 C20.4740962,15.153861 19.6043241,15.4964714 18.6783494,15.4964714" />
  </React.Fragment>,
  'Link'
)
