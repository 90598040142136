import styled, { css } from 'styled-components/macro'
import { BrandColors as colors } from 'style/BrandColors'

const labelFocus = css`
  z-index: 0;
  outline: 5px auto ${colors.mountainMeadow};
  outline-offset: 1px;
`

export const Wrap = styled.div`
  display: flex;
`

export const Input = styled.input`
  position: relative;
  display: none;
  &:disabled + label {
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${colors.lightGray40};
  }

  &:checked + label {
    background-color: ${colors.mountainMeadow};
  }
  &:focus + label {
    ${labelFocus}
  }
`

export const Label = styled.label<{ htmlFor: string }>`
  display: flex;
  height: 100%;
  padding: 1rem;
  cursor: pointer;
  background-color: ${colors.white};
  &:hover {
    background-color: ${colors.whiteSmoke};
  }
  transition: all 255ms ease;
  span {
    align-self: center;
    text-transform: uppercase;
  }
`

export const InputWrap = styled.div<{ disabled?: boolean }>`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.0625rem;
  text-align: center;
  display: flex;
  align-items: center;
  border: 0.0625rem solid ${colors.azureishWhite};
  &:not(:last-of-type) {
    border-right: 0;
  }
  &:first-of-type,
  &:first-of-type label {
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem;
  }
  &:last-of-type,
  &:last-of-type label {
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
  }

  :disabled {
    background: ${colors.lightGray40};
    border-color: currentcolor;
    color: ${colors.darkGray20};
    cursor: not-allowed;
    pointer-events: none;
  }
`
