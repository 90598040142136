import { name as serviceName } from '../package.json'
import { environment, serverUrl } from './constants/server'
import { GIT_SHA } from './constants/version'
import { APM_ENABLED } from './constants/apm'
import { ApmBase, init } from '@elastic/apm-rum'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

export let apm: ApmBase | null = null

let ALREADY_INITIALIZED = false

export const initializeApm = () => {
  if (!APM_ENABLED) {
    console.log('APM disabled')
    return
  }

  if (ALREADY_INITIALIZED) {
    console.log('APM already initialized')
    return
  }

  ALREADY_INITIALIZED = true
  console.log('Initializing APM')

  // https://www.elastic.co/guide/en/apm/agent/rum-js/4.x/custom-transaction-name.html
  const parts = window.location.pathname.split('/')
  let pageLoadTransactionName = window.location.pathname
  if (parts.length > 0) {
    pageLoadTransactionName = parts[1]
  }

  // elastic
  apm = init({
    serviceName,
    serviceVersion: GIT_SHA,
    environment,
    serverUrl: window.CONFIG.APM_SERVER_URL,
    pageLoadTransactionName,
    distributedTracingOrigins: [serverUrl],
    eventsLimit: 360,
    // WARNING: ignoreTransactions accepts regular expressions -- BE VERY CAREFUL!
    // regexes can be stupidly expensive when run over long strings, to the point of crashing chrome.
    // for example when downloading a file we fetch a data url with an encoded csv, which can be MBs.
    // this doesn't appear to happen in non-chrome runtimes.
    ignoreTransactions: [
      'POST https://r.lr-intake.com/i', // elastic doesnt need to see all our logrocket telemetry
      'Unhandled promise rejection: AbortError: Fetch is aborted', // https://bugs.webkit.org/show_bug.cgi?id=215771
      'Uncaught Error: `initialFocus` refers to no known node', // comes from our modal component; benign
    ],
  })

  // logrocket
  LogRocket?.init('urnv5v/flowhub', {
    release: GIT_SHA,
    network: {
      requestSanitizer: (request) =>
        // logrocket doesnt need to see all our elastic telemetry
        (request?.url ?? '').includes('fh-elastic.apm') ? null : request,
    },
  })

  setupLogRocketReact(LogRocket)
}
