import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M6.7983,15.6 C4.7037,15.6 3,17.304 3,19.3983 C3,21.4929 4.7037,23.1966 6.7983,23.1966 C8.8929,23.1966 10.5966,21.4929 10.5966,19.3983 C10.5966,17.304 8.8929,15.6 6.7983,15.6 M6.7983,17.1 C8.0655,17.1 9.0966,18.1311 9.0966,19.3983 C9.0966,20.6655 8.0655,21.6966 6.7983,21.6966 C5.5311,21.6966 4.5,20.6655 4.5,19.3983 C4.5,18.1311 5.5311,17.1 6.7983,17.1" />
    <path d="M7.8,14.876105 L7.8273,3.75240502 C7.8285,3.33810502 7.494,3.00120502 7.0797,3.00000502 C6.6654,2.99850502 6.3285,3.33330502 6.3273,3.74760502 L6.3,14.877605 C6.5439,14.828105 6.7962,14.801705 7.0545,14.801705 C7.3098,14.801705 7.5588,14.827805 7.8,14.876105" />
    <path d="M6.00300502,24 L6.00000502,26.1276 C5.99850502,26.5419 6.33330502,26.8788 6.74760502,26.88 C7.16190502,26.8815 7.49850502,26.5467 7.50000502,26.1324 L7.50300502,24.0066 C7.26630502,24.0528 7.02240502,24.0786 6.77250502,24.0786 C6.50910502,24.0786 6.25170502,24.0513 6.00300502,24" />
    <path d="M15.1983,6.6 C13.1037,6.6 11.4,8.304 11.4,10.3983 C11.4,12.4929 13.1037,14.1966 15.1983,14.1966 C17.2929,14.1966 18.9966,12.4929 18.9966,10.3983 C18.9966,8.304 17.2929,6.6 15.1983,6.6 M15.1983,8.1 C16.4655,8.1 17.4966,9.1311 17.4966,10.3983 C17.4966,11.6655 16.4655,12.6966 15.1983,12.6966 C13.9311,12.6966 12.9,11.6655 12.9,10.3983 C12.9,9.1311 13.9311,8.1 15.1983,8.1" />
    <path d="M14.4243032,15 L14.4000032,26.1261 C14.3988032,26.5404 14.7333032,26.8773 15.1476032,26.8785 C15.5619032,26.88 15.8988032,26.5452 15.9000032,26.1309 L15.9243032,15.0039 C15.6858032,15.051 15.4398032,15.0771 15.1875032,15.0771 C14.9262032,15.0771 14.6709032,15.0504 14.4243032,15" />
    <path d="M15.9,5.87730322 L15.906,3.75240322 C15.9075,3.33840322 15.5727,3.00150322 15.1584,3.00000322 C14.7441,2.99880322 14.4075,3.33360322 14.406,3.74790322 L14.4,5.87670322 C14.6421,5.82810322 14.892,5.80200322 15.1482,5.80200322 C15.4056,5.80200322 15.657,5.82840322 15.9,5.87730322" />
    <path d="M23.2983,14.4 C21.2037,14.4 19.5,16.104 19.5,18.1983 C19.5,20.2929 21.2037,21.9966 23.2983,21.9966 C25.3929,21.9966 27.0966,20.2929 27.0966,18.1983 C27.0966,16.104 25.3929,14.4 23.2983,14.4 M23.2983,15.9 C24.5655,15.9 25.5966,16.9311 25.5966,18.1983 C25.5966,19.4655 24.5655,20.4966 23.2983,20.4966 C22.0311,20.4966 21,19.4655 21,18.1983 C21,16.9311 22.0311,15.9 23.2983,15.9" />
    <path d="M24,13.6770032 L24.0219,3.75240322 C24.0231,3.33810322 23.6886,3.00120322 23.2743,3.00000322 C22.86,2.99880322 22.5231,3.33330322 22.5219,3.74760322 L22.5,13.6767032 C22.7424,13.6281032 22.9926,13.6017032 23.2491,13.6017032 C23.5062,13.6017032 23.757,13.6281032 24,13.6770032" />
    <path d="M22.508405,22.8 L22.500005,26.1264 C22.498505,26.5407 22.833305,26.8773 23.247605,26.8788 C23.661605,26.88 23.998505,26.5455 24.000005,26.1312 L24.008405,22.8042 C23.770205,22.8513 23.524505,22.8774 23.272505,22.8774 C23.010905,22.8774 22.755305,22.8507 22.508405,22.8" />
  </React.Fragment>,
  'Adjustments'
)
