import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M14.976,25.4871 C9.1803,25.4871 4.4649,20.7717 4.4649,14.976 C4.4649,9.1803 9.1803,4.4649 14.976,4.4649 C20.7717,4.4649 25.4871,9.1803 25.4871,14.976 C25.4871,20.7717 20.7717,25.4871 14.976,25.4871 M14.976,3 C8.3724,3 3,8.3724 3,14.976 C3,21.5796 8.3724,26.952 14.976,26.952 C21.5796,26.952 26.952,21.5796 26.952,14.976 C26.952,8.3724 21.5796,3 14.976,3" />
    <path d="M21.945,14.3418 L15.5646,14.3418 L15.5646,10.3326 C15.5646,9.9285 15.2364,9.6 14.8323,9.6 C14.4285,9.6 14.1,9.9285 14.1,10.3326 L14.1,15.0741 C14.1,15.4782 14.4285,15.8067 14.8323,15.8067 L21.945,15.8067 C22.3491,15.8067 22.6776,15.4782 22.6776,15.0741 C22.6776,14.6703 22.3491,14.3418 21.945,14.3418" />
  </React.Fragment>,
  'ClockIn'
)
