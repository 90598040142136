import { ButtonFramelessProps, StyledButtonProps } from './types'
import { BrandColors } from 'style/BrandColors'
import styled, { css } from 'styled-components/macro'

const StyledButton = styled.button<StyledButtonProps>`
  flex-shrink: 0;
  font-family: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: transparent;
  border: none;
  border-radius: 0.25rem;
  color: ${BrandColors.middleRedPurple};
  cursor: pointer;
  fill: currentcolor;
  height: ${({ size, stacked }) =>
    stacked ? '100%' : size === 'small' ? '2rem' : '3rem'};
  line-height: 1;
  min-width: 3rem;
  outline: none;
  padding: ${({ size }) => (size === 'small' ? '0.5rem 1rem' : '1rem')};
  transition: box-shadow 100ms linear;
  user-select: none;

  svg {
    height: 1rem;
    width: 1rem;
    transform: scale3d(1.5, 1.5, 1.5);
    user-select: inherit;

    ${({ icon, iconOnly, iconRight, stacked }) =>
      !!icon &&
      !iconOnly &&
      (stacked
        ? 'margin-bottom: 0.75rem'
        : iconRight
        ? 'margin-left: 0.75rem'
        : 'margin-right: 0.75rem')};

    ${({ iconOnly }) =>
      iconOnly &&
      css`
        margin: 0;
      `}
  }

  div {
    align-items: center;
    display: flex;
    flex-direction: ${({ iconRight, stacked }) =>
      stacked ? 'column' : iconRight ? 'row-reverse' : 'row'};
    justify-content: center;
  }

  :disabled {
    color: ${BrandColors.pastelBlue};
    font-weight: normal;
    pointer-events: none;
  }

  :hover {
    background: ${({ whiteBg }) =>
      !!whiteBg ? BrandColors.paleGrey : BrandColors.paleGreyThree};
  }
  :active {
    background: ${BrandColors.mountainMeadow};
  }
  :focus:not(:active):not(:hover) {
    background: transparent;
    box-shadow: inset 0 0 0 3px ${BrandColors.mountainMeadow};
  }
`

/**
 * Button that only shows the frame or bounding box when touched or hovered.
 *
 * The following configurations are allowed:
 *
 *  - Text only
 *  - Text with Icon (right, left or stacked)
 *  - Icon only
 *
 */
export const ButtonFrameless = ({
  children,
  disabled = false,
  icon,
  size = 'default',
  stacked = false,
  type = 'button',
  iconRight = false,
  whiteBg = false,
  ...rest
}: ButtonFramelessProps) => (
  <StyledButton
    disabled={disabled}
    icon={!!icon}
    iconOnly={!!icon && !children}
    size={size}
    stacked={stacked}
    type={type}
    iconRight={iconRight}
    whiteBg={whiteBg}
    {...rest}
  >
    <div>
      {icon}
      {children}
    </div>
  </StyledButton>
)
