import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

const highlightColor = BrandColors.mountainMeadow

export const MenuItem = styled.div.attrs({
  type: 'button',
  role: 'menuitem',
  tabIndex: 1,
})`
  border: 2px solid transparent;

  &:hover {
    background-color: ${highlightColor};
  }

  &:focus {
    border-color: ${highlightColor};
  }

  transition: all 155ms ease;

  outline: none;
  color: ${BrandColors.middleRedPurple};
  cursor: pointer;
  list-style: none;
  padding: 0.5rem;
  text-shadow: none;
  box-sizing: border-box;
`
