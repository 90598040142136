import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M13.3470001,21.6626754 L13.3443001,21.5372754 L11.9025001,21.5690754 L11.9055001,21.6944754 C11.9571001,24.0713754 11.7495001,25.0235754 11.6493001,25.3391754 C10.8660001,25.0127754 9.38070013,23.8076754 7.71480013,22.1414754 C6.04830013,20.4758754 4.84320013,18.9902754 4.51710013,18.2072754 C4.83240013,18.1070754 5.78310013,17.8994754 8.16180013,17.9510754 L8.28690013,17.9534754 L8.31870013,16.5122754 L8.19330013,16.5092754 C4.06140013,16.4162754 3.50640013,17.0468754 3.26850013,17.3165754 C3.05760013,17.5547754 2.96640013,17.8541754 3.01110013,18.1595754 C3.19890013,19.4366754 5.11800013,21.5843754 6.69480013,23.1614754 C8.27190013,24.7382754 10.4202001,26.6576754 11.6967001,26.8448754 C11.7471001,26.8526754 11.7978001,26.8559754 11.8479001,26.8559754 C12.1020001,26.8559754 12.3411001,26.7635754 12.5400001,26.5880754 C12.8085001,26.3507754 13.4376001,25.7957754 13.3470001,21.6626754" />
    <path d="M8.70703411,19.4687885 C8.66623411,19.2800885 8.55403411,19.1186885 8.39143411,19.0139885 C8.23003411,18.9107885 8.03743411,18.8756885 7.84993411,18.9167885 L7.46863411,18.9992885 C7.28023411,19.0400885 7.11913411,19.1516885 7.01503411,19.3136885 C6.91063411,19.4756885 6.87583411,19.6685885 6.91663411,19.8566885 C6.98773411,20.1860885 7.28383411,20.4251885 7.62073411,20.4251885 C7.67233411,20.4251885 7.72393411,20.4197885 7.77373411,20.4086885 L8.15503411,20.3261885 C8.34343411,20.2853885 8.50453411,20.1734885 8.60863411,20.0117885 C8.71303411,19.8497885 8.74783411,19.6568885 8.70703411,19.4687885" />
    <path d="M10.710189,21.6305976 C10.606089,21.4682976 10.444989,21.3566976 10.256289,21.3155976 C9.86688905,21.2339976 9.48318905,21.4805976 9.39948905,21.8675976 L9.31668905,22.2482976 C9.27588905,22.4366976 9.31038905,22.6292976 9.41478905,22.7912976 C9.51858905,22.9535976 9.67968905,23.0651976 9.86808905,23.1059976 C9.91938905,23.1170976 9.97098905,23.1224976 10.021689,23.1224976 C10.357989,23.1224976 10.654089,22.8836976 10.725489,22.5542976 L10.808289,22.1738976 C10.849389,21.9854976 10.814589,21.7925976 10.710189,21.6305976" />
    <path d="M11.6051646,18.1657789 C10.2011646,16.7617789 10.5293646,14.1496789 12.3365646,12.3424789 C13.2317646,11.4469789 14.3468646,10.8874789 15.4760646,10.7665789 C16.5470646,10.6528789 17.5007646,10.9522789 18.1598646,11.6110789 C18.8186646,12.2701789 19.1186646,13.2232789 19.0040646,14.2948789 C18.8834646,15.4240789 18.3239646,16.5391789 17.4284646,17.4343789 C15.6215646,19.2418789 13.0094646,19.5697789 11.6051646,18.1657789 M15.3227646,9.33287893 C13.8671646,9.48827893 12.4445646,10.1950789 11.3168646,11.3227789 C10.1738646,12.4657789 9.46676458,13.9114789 9.32576458,15.3934789 C9.18386458,16.8847789 9.63146458,18.2314789 10.5854646,19.1854789 C11.4137646,20.0140789 12.5726646,20.4703789 13.8488646,20.4703789 C15.4904646,20.4703789 17.1668646,19.7356789 18.4481646,18.4540789 C19.5758646,17.3263789 20.2826646,15.9037789 20.4383646,14.4478789 C20.5997646,12.9340789 20.1527646,11.5645789 19.1795646,10.5916789 C18.2054646,9.61787893 16.8356646,9.16967893 15.3227646,9.33287893" />
    <path d="M26.8446888,11.6970142 C26.6571888,10.4205142 24.7380888,8.27221419 23.1609888,6.69481419 C21.5835888,5.11771419 19.4352888,3.19861419 18.1584888,3.01141419 C17.8530888,2.96581419 17.5539888,3.05791419 17.3160888,3.26851419 C17.0475888,3.50551419 16.4184888,4.06081419 16.5087888,8.19361419 L16.5114888,8.31871419 L17.9532888,8.28721419 L17.9502888,8.16181419 C17.8986888,5.78641419 18.1068888,4.83331419 18.2067888,4.51711419 C18.9900888,4.84411419 20.4756888,6.04891419 22.1412888,7.71451419 C23.8068888,9.38071419 25.0122888,10.8663142 25.3389888,11.6493142 C25.0233888,11.7495142 24.0690888,11.9583142 21.6942888,11.9055142 L21.5685888,11.9028142 L21.5370888,13.3446142 L21.6624888,13.3473142 C21.9333888,13.3533142 22.1880888,13.3557142 22.4289888,13.3557142 C25.8672888,13.3557142 26.3718888,12.7842142 26.5872888,12.5400142 C26.7981888,12.3018142 26.8893888,12.0021142 26.8446888,11.6970142" />
    <path d="M19.4684701,8.70702605 C19.5191701,8.71782605 19.5707701,8.72352605 19.6217701,8.72352605 C19.9583701,8.72352605 20.2541701,8.48442605 20.3258701,8.15502605 L20.4083701,7.77402605 C20.4494701,7.58562605 20.4143701,7.39302605 20.3102701,7.23102605 C20.2058701,7.06902605 20.0447701,6.95742605 19.8563701,6.91632605 C19.6688701,6.87612605 19.4747701,6.91062605 19.3127701,7.01472605 C19.1513701,7.11912605 19.0400701,7.28022605 18.9992701,7.46832605 L18.9167701,7.84962605 C18.8756701,8.03802605 18.9107701,8.23062605 19.0148701,8.39262605 C19.1192701,8.55462605 19.2803701,8.66622605 19.4684701,8.70702605" />
    <path d="M21.874089,10.8083963 L22.255389,10.7255963 C22.643889,10.6412963 22.891389,10.2563963 22.807089,9.86789628 C22.722789,9.47969628 22.336389,9.23279628 21.949689,9.31619628 L21.568389,9.39899628 C21.380289,9.43979628 21.219189,9.55139628 21.114789,9.71339628 C21.010389,9.87539628 20.975889,10.0682963 21.016689,10.2566963 C21.088389,10.5857963 21.384189,10.8245963 21.720789,10.8245963 C21.772689,10.8245963 21.824289,10.8191963 21.874089,10.8083963" />
  </React.Fragment>,
  'Edibles'
)
