import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export const Rec = createSvgIcon(
  <React.Fragment>
    <path d="M14.8758,17.7195 C8.3241,17.7405 6.4788,19.5489 6.0693,20.1024 L6.0195,20.1702 L6.0195,25.3356 L23.7357,25.3356 L23.7357,20.1735 L23.6841,20.1057 C23.2635,19.5516 21.3855,17.7405 14.8773,17.7195 L14.8872,17.7288 L14.8662,17.7291 L14.8758,17.7195 Z M5.2599,26.8539 C4.8408,26.8539 4.5,26.5131 4.5,26.0946 L4.5,19.9905 C4.5,19.9008 4.5156,19.8132 4.5459,19.731 C4.6338,19.4895 5.0313,18.6414 6.4782,17.8251 C8.3397,16.7736 11.1174,16.2273 14.7339,16.2021 L15.0186,16.2 L15.0612,16.206 C18.6486,16.2276 21.4128,16.7724 23.2767,17.8251 C24.7197,18.6396 25.1202,19.4892 25.2087,19.7304 C25.239,19.8141 25.2546,19.9017 25.2546,19.9905 L25.2546,26.0946 C25.2546,26.5131 24.9138,26.8539 24.495,26.8539 L5.2599,26.8539 Z" />
    <path d="M15.0084,4.5192 C12.864,4.5192 11.1192,6.2637 11.1192,8.4084 C11.1192,10.5528 12.864,12.2976 15.0084,12.2976 C17.1531,12.2976 18.8976,10.5528 18.8976,8.4084 C18.8976,6.2637 17.1531,4.5192 15.0084,4.5192 M15.0084,13.8165 C12.0261,13.8165 9.6,11.3904 9.6,8.4084 C9.6,5.4261 12.0261,3 15.0084,3 C17.9907,3 20.4171,5.4261 20.4171,8.4084 C20.4171,11.3904 17.9907,13.8165 15.0084,13.8165" />
  </React.Fragment>,
  'Rec'
)

export default Rec
