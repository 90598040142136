import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M20.3085,16.9479 L20.3085,23.0025 L9.9183,23.0025 L9.9183,16.9434 C10.1655,16.6392 11.2743,15.6315 15.114,15.6189 C18.9267,15.6315 20.0547,16.6419 20.3085,16.9479 M20.4555,15.1434 C19.2609,14.469 17.5029,14.1186 15.2301,14.1024 L15.2175,14.1009 L15.2064,14.1 L15.1953,14.1 L15.0225,14.1015 C12.7341,14.1177 10.9671,14.4681 9.7713,15.1434 C8.79,15.6975 8.5089,16.3032 8.4459,16.4757 C8.4156,16.5585 8.4,16.6464 8.4,16.7358 L8.4,23.7615 C8.4,24.1803 8.7405,24.5208 9.159,24.5208 L21.0678,24.5208 C21.4863,24.5208 21.8271,24.1803 21.8271,23.7615 L21.8271,16.7358 C21.8271,16.6467 21.8115,16.5591 21.7809,16.4757 C21.7179,16.3029 21.4356,15.6966 20.4555,15.1434" />
    <path d="M17.1564,9.9372 C17.1564,11.1057 16.206,12.0561 15.0375,12.0561 C13.869,12.0561 12.9186,11.1057 12.9186,9.9372 C12.9186,8.7687 13.869,7.8183 15.0375,7.8183 C16.206,7.8183 17.1564,8.7687 17.1564,9.9372 M15.0375,13.5747 C17.0433,13.5747 18.675,11.943 18.675,9.9372 C18.675,7.9317 17.0433,6.3 15.0375,6.3 C13.0317,6.3 11.4,7.9317 11.4,9.9372 C11.4,11.943 13.0317,13.5747 15.0375,13.5747" />
    <path d="M27.2289,15.4308 C27.1695,15.2682 26.9037,14.697 25.9848,14.178 C24.8727,13.5501 23.2371,13.224 21.123,13.2078 L20.1,13.2 L21.0375,13.6104 C21.294,13.7223 21.5109,13.8288 21.7014,13.9365 C22.1187,14.172 22.4907,14.4408 22.8084,14.7363 L22.8573,14.7816 L22.9239,14.7903 C24.924,15.0501 25.6146,15.6642 25.8048,15.8895 L25.8048,21.474 L24.0102,21.474 L24.0102,22.9419 L26.5389,22.9419 C26.9439,22.9419 27.273,22.6125 27.273,22.2078 L27.273,15.6822 C27.273,15.5955 27.2583,15.5109 27.2289,15.4308" />
    <path d="M24.3438,8.8074 C24.3438,6.9285 22.815,5.4 20.9364,5.4 C20.1519,5.4 19.3854,5.6748 18.7776,6.1734 L18.6,6.3183 L18.7608,6.4827 C18.9891,6.7158 19.1985,6.9813 19.3824,7.2714 L19.5105,7.4739 L19.6944,7.32 C20.043,7.0284 20.484,6.8679 20.9364,6.8679 C22.0056,6.8679 22.8753,7.7382 22.8753,8.8074 C22.8753,9.8766 22.0056,10.7466 20.9364,10.7466 C20.7183,10.7466 20.4996,10.7079 20.2854,10.6314 L20.0595,10.551 L20.0097,10.785 C19.9404,11.106 19.8342,11.4261 19.6932,11.7366 L19.5987,11.9457 L19.815,12.0213 C20.1816,12.1497 20.559,12.2145 20.9364,12.2145 C22.815,12.2145 24.3438,10.6863 24.3438,8.8074" />
    <path d="M4.2885,14.178 C3.3696,14.6967 3.1038,15.2682 3.0447,15.4305 C3.015,15.5106 3,15.5955 3,15.6822 L3,22.2078 C3,22.6125 3.3294,22.9419 3.7341,22.9419 L6.2631,22.9419 L6.2631,21.4737 L4.4685,21.4737 L4.4685,15.8895 C4.6584,15.6642 5.349,15.0501 7.3494,14.7903 L7.416,14.7816 L7.4655,14.7357 C7.7823,14.4408 8.1546,14.1717 8.5716,13.9362 C8.7621,13.8288 8.9793,13.722 9.2355,13.6101 L10.173,13.2 L9.15,13.2078 C7.0359,13.2237 5.4003,13.5501 4.2885,14.178" />
    <path d="M9.4074,5.4 C7.5285,5.4 6,6.9288 6,8.8074 C6,10.6863 7.5285,12.2148 9.4074,12.2148 C9.7848,12.2148 10.1622,12.1497 10.5285,12.0213 L10.7451,11.9457 L10.6506,11.7369 C10.5096,11.4264 10.4031,11.1063 10.3344,10.7853 L10.2843,10.551 L10.0584,10.6314 C9.8442,10.7079 9.6255,10.7466 9.4074,10.7466 C8.3382,10.7466 7.4682,9.8769 7.4682,8.8074 C7.4682,7.7382 8.3382,6.8682 9.4074,6.8682 C9.8598,6.8682 10.3008,7.0287 10.6494,7.32 L10.8333,7.4739 L10.9614,7.2714 C11.1453,6.9813 11.3544,6.7161 11.5833,6.4824 L11.7435,6.3186 L11.5662,6.1734 C10.9584,5.6748 10.1916,5.4 9.4074,5.4" />
  </React.Fragment>,
  'Customers'
)
