import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <g transform="translate(15.000000, 15.000000) rotate(-90.000000) translate(-15.000000, -15.000000) ">
      <path
        d="M8.19819,18.73788 C8.11659,19.06338 7.82739,19.27338 7.46139,19.27338 L3.72999,19.27338 C3.31119,19.27338 2.97039,18.93258 2.97039,18.51378 C2.97039,18.09498 3.31119,17.75448 3.72999,17.75448 L6.86829,17.75448 L10.44399,3.54678 C10.52469,3.22608 10.81929,3.01038 11.17809,3.01038 C11.54049,3.01368 11.84169,3.25548 11.92089,3.59778 L16.49379,23.36448 L19.83939,12.01608 C19.93299,11.69748 20.22489,11.46948 20.54859,11.46138 L20.54949,11.46138 C20.90199,11.46138 21.18069,11.65338 21.28479,11.94978 L23.33469,17.75448 L26.25099,17.75448 C26.66979,17.75448 27.01059,18.09498 27.01059,18.51378 C27.01059,18.93258 26.66979,19.27338 26.25099,19.27338 L22.79529,19.27338 C22.45449,19.27338 22.17999,19.09398 22.07889,18.80448 L20.62719,14.72508 L17.13309,26.48868 C17.13309,26.48868 17.00349,27.02958 16.38999,27.02958 C16.03809,27.01938 15.74319,26.77578 15.66489,26.43678 L11.12889,6.73398 L8.19819,18.73788 Z"
        transform="translate(14.990490, 15.019980) rotate(-270.000000) translate(-14.990490, -15.019980) "
      />
    </g>
  </React.Fragment>,
  'Status'
)
