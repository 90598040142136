import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M4.78820741,14.99448 L14.8548741,25.18698 L24.9212444,14.99448 L14.8548741,4.80198 L4.78820741,14.99448 Z M14.8548741,26.99748 C14.6584296,26.99748 14.4699852,26.91858 14.3310222,26.77788 L3.21694815,15.52488 C2.92776296,15.23208 2.92776296,14.75688 3.21694815,14.46408 L14.3310222,3.21108 C14.6089481,2.92968 15.1008,2.92968 15.3784296,3.21108 L26.4925037,14.46408 C26.7819852,14.75688 26.7819852,15.23208 26.4925037,15.52488 L15.3784296,26.77788 C15.2394667,26.91858 15.0513185,26.99748 14.8548741,26.99748 L14.8548741,26.99748 Z" />
    <path d="M13.9604741,9.26151 C13.9604741,8.78391 14.3429926,8.39661 14.8146963,8.39661 C15.2866963,8.39661 15.6689185,8.78391 15.6689185,9.26151 C15.6689185,9.73911 15.2866963,10.12641 14.8146963,10.12641 C14.3429926,10.12641 13.9604741,9.73911 13.9604741,9.26151" />
    <path d="M19.6156741,14.9874 C19.6156741,14.5098 19.9981926,14.1225 20.4698963,14.1225 C20.9418963,14.1225 21.3241185,14.5098 21.3241185,14.9874 C21.3241185,15.465 20.9418963,15.8523 20.4698963,15.8523 C19.9981926,15.8523 19.6156741,15.465 19.6156741,14.9874" />
    <path d="M16.804563,17.83365 C16.804563,17.35605 17.1870815,16.96875 17.6587852,16.96875 C18.1307852,16.96875 18.5130074,17.35605 18.5130074,17.83365 C18.5130074,18.31125 18.1307852,18.69855 17.6587852,18.69855 C17.1870815,18.69855 16.804563,18.31125 16.804563,17.83365" />
    <path d="M16.8115556,12.14826 C16.8115556,11.67066 17.1940741,11.28336 17.6657778,11.28336 C18.1377778,11.28336 18.52,11.67066 18.52,12.14826 C18.52,12.62586 18.1377778,13.01316 17.6657778,13.01316 C17.1940741,13.01316 16.8115556,12.62586 16.8115556,12.14826" />
    <path d="M8.30527407,14.9874 C8.30527407,14.5098 8.68779259,14.1225 9.1594963,14.1225 C9.6314963,14.1225 10.0137185,14.5098 10.0137185,14.9874 C10.0137185,15.465 9.6314963,15.8523 9.1594963,15.8523 C8.68779259,15.8523 8.30527407,15.465 8.30527407,14.9874" />
    <path d="M11.149363,12.10776 C11.149363,11.63016 11.5318815,11.24286 12.0035852,11.24286 C12.4755852,11.24286 12.8578074,11.63016 12.8578074,12.10776 C12.8578074,12.58536 12.4755852,12.97266 12.0035852,12.97266 C11.5318815,12.97266 11.149363,12.58536 11.149363,12.10776" />
    <path d="M13.9604741,20.71329 C13.9604741,20.23569 14.3429926,19.84839 14.8146963,19.84839 C15.2866963,19.84839 15.6689185,20.23569 15.6689185,20.71329 C15.6689185,21.19089 15.2866963,21.57819 14.8146963,21.57819 C14.3429926,21.57819 13.9604741,21.19089 13.9604741,20.71329" />
    <path d="M11.1563556,17.87415 C11.1563556,17.39655 11.5388741,17.00925 12.0105778,17.00925 C12.4825778,17.00925 12.8648,17.39655 12.8648,17.87415 C12.8648,18.35175 12.4825778,18.73905 12.0105778,18.73905 C11.5388741,18.73905 11.1563556,18.35175 11.1563556,17.87415" />
  </React.Fragment>,
  'TransdermalPatch'
)
