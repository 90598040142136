import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M12.9246742,16.2164075 C12.2580742,15.0653075 12.6507742,13.5914075 13.8018742,12.9248075 C14.9529742,12.2579075 16.4268742,12.6509075 17.0934742,13.8020075 C17.7603742,14.9531075 17.3676742,16.4270075 16.2162742,17.0936075 C15.0651742,17.7602075 13.5912742,17.3675075 12.9246742,16.2164075" />
    <path d="M21.0246742,21.0164075 C20.3580742,19.8653075 20.7507742,18.3914075 21.9018742,17.7248075 C23.0529742,17.0579075 24.5268742,17.4509075 25.1934742,18.6020075 C25.8603742,19.7531075 25.4676742,21.2270075 24.3162742,21.8936075 C23.1651742,22.5602075 21.6912742,22.1675075 21.0246742,21.0164075" />
    <path d="M12.9246742,25.8164075 C12.2580742,24.6653075 12.6507742,23.1914075 13.8018742,22.5248075 C14.9529742,21.8579075 16.4268742,22.2509075 17.0934742,23.4020075 C17.7603742,24.5531075 17.3676742,26.0270075 16.2162742,26.6936075 C15.0651742,27.3602075 13.5912742,26.9675075 12.9246742,25.8164075" />
    <path d="M12.6246742,6.6164075 C11.9580742,5.4653075 12.3507742,3.9914075 13.5018742,3.3248075 C14.6529742,2.6579075 16.1268742,3.0509075 16.7934742,4.2020075 C17.4603742,5.3531075 17.0676742,6.8270075 15.9162742,7.4936075 C14.7651742,8.1602075 13.2912742,7.7675075 12.6246742,6.6164075" />
    <path d="M4.52467417,11.4164075 C3.85807417,10.2653075 4.25077417,8.7914075 5.40187417,8.1248075 C6.55297417,7.4579075 8.02687417,7.8509075 8.69347417,9.0020075 C9.36037417,10.1531075 8.96767417,11.6270075 7.81627417,12.2936075 C6.66517417,12.9602075 5.19127417,12.5675075 4.52467417,11.4164075" />
    <path d="M21.0246742,11.4164075 C20.3580742,10.2653075 20.7507742,8.7914075 21.9018742,8.1248075 C23.0529742,7.4579075 24.5268742,7.8509075 25.1934742,9.0020075 C25.8603742,10.1531075 25.4676742,11.6270075 24.3162742,12.2936075 C23.1651742,12.9602075 21.6912742,12.5675075 21.0246742,11.4164075" />
    <path d="M4.52467417,21.0164075 C3.85807417,19.8653075 4.25077417,18.3914075 5.40187417,17.7248075 C6.55297417,17.0579075 8.02687417,17.4509075 8.69347417,18.6020075 C9.36037417,19.7531075 8.96767417,21.2270075 7.81627417,21.8936075 C6.66517417,22.5602075 5.19127417,22.1675075 4.52467417,21.0164075" />
  </React.Fragment>,
  'Concentrates'
)
