// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button

import { BrandColors } from 'style/BrandColors'
import {
  ButtonRoundedIconProps,
  StyledButtonProps,
  ThemeStyleRules,
} from './types'
import { Add } from 'components/Icons'
import styled, { css } from 'styled-components/macro'

const themes = {
  dark: {
    background: BrandColors.paleGrey,
    boxShadow: BrandColors.white,
    color: BrandColors.white,
  },
  light: {
    background: BrandColors.white,
    boxShadow: BrandColors.middleRedPurple,
    color: BrandColors.middleRedPurple,
  },
  white: {
    background: BrandColors.paleGrey,
    boxShadow: BrandColors.middleRedPurple,
    color: BrandColors.middleRedPurple,
  },
}

const themeStyleRules = ({ backgroundColor }: ThemeStyleRules) => {
  const { background, boxShadow, color } = themes[backgroundColor]
  return css`
    box-shadow: inset 0 0 0 1px ${boxShadow};
    color: ${color};

    :hover:not(:active) {
      background: ${background};
    }
  `
}

const Button = styled.button<StyledButtonProps>`
  ${themeStyleRules};

  background: transparent;
  border: none;
  border-radius: 1.65rem;
  cursor: pointer;

  height: 3rem;
  width: 3.625rem;
  padding: 0;
  outline: none;

  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;

  transition: box-shadow 50ms linear;
  user-select: none;

  svg {
    fill: currentcolor;
  }

  /* stylelint-disable-next-line no-descending-specificity  */
  :disabled {
    background: ${({ backgroundColor }) =>
      backgroundColor === 'dark' ? 'transparent' : BrandColors.paleGreyTwo};
    box-shadow: inset 0 0 0 1px currentcolor;
    color: ${BrandColors.pastelBlue};
    pointer-events: none;
  }
  /* stylelint-disable-next-line no-descending-specificity  */
  :active {
    background: ${BrandColors.ruby};
    box-shadow: inset 0 0 3px ${BrandColors.ruby};
    color: ${BrandColors.white};
  }

  :hover:not(:active) {
    color: ${BrandColors.ruby};
  }

  :focus:not(:active):not(:hover) {
    box-shadow: inset 0 0 0 3px ${BrandColors.ruby};
  }
`
/**
 * the icon defaults to Add (+) if no child is provided

 */
export const ButtonRoundedIcon = ({
  backgroundColor = 'light',
  children,
  disabled = false,
  type = 'button',
  ...rest
}: ButtonRoundedIconProps) => (
  <Button
    data-testid="button-rounded-icon"
    backgroundColor={backgroundColor}
    disabled={disabled}
    type={type}
    {...rest}
  >
    {children ? children : <Add />}
  </Button>
)
