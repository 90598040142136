import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M11.4429,6.0054 C11.0883,6.0054 10.8,5.6676 10.8,5.2527 C10.8,4.8375 11.0883,4.5 11.4429,4.5 L26.4732,4.5 C26.8275,4.5 27.1161,4.8375 27.1161,5.2527 C27.1161,5.6676 26.8275,6.0054 26.4732,6.0054 L11.4429,6.0054 Z" />
    <path d="M11.4429,15.6054 C11.0883,15.6054 10.8,15.2676 10.8,14.8527 C10.8,14.4378 11.0883,14.1 11.4429,14.1 L26.4732,14.1 C26.8275,14.1 27.1161,14.4378 27.1161,14.8527 C27.1161,15.2676 26.8275,15.6054 26.4732,15.6054 L11.4429,15.6054 Z" />
    <path d="M11.4429,25.5054 C11.0883,25.5054 10.8,25.1676 10.8,24.7527 C10.8,24.3378 11.0883,24 11.4429,24 L26.4732,24 C26.8275,24 27.1161,24.3378 27.1161,24.7527 C27.1161,25.1676 26.8275,25.5054 26.4732,25.5054 L11.4429,25.5054 Z" />
    <path d="M5.4768,26.8536 C4.2765,26.8536 3.3,25.8771 3.3,24.6768 C3.3,23.4765 4.2765,22.5 5.4768,22.5 C6.6771,22.5 7.6536,23.4765 7.6536,24.6768 C7.6536,25.8771 6.6771,26.8536 5.4768,26.8536" />
    <path d="M5.4768,17.2536 C4.2765,17.2536 3.3,16.2771 3.3,15.0768 C3.3,13.8765 4.2765,12.9 5.4768,12.9 C6.6771,12.9 7.6536,13.8765 7.6536,15.0768 C7.6536,16.2771 6.6771,17.2536 5.4768,17.2536" />
    <path d="M5.4768,7.3533 C4.2765,7.3533 3.3,6.3768 3.3,5.1765 C3.3,3.9765 4.2765,3 5.4768,3 C6.6771,3 7.6536,3.9765 7.6536,5.1765 C7.6536,6.3768 6.6771,7.3533 5.4768,7.3533" />
  </React.Fragment>,
  'SeeAll'
)
