import * as React from 'react'
import createSvgIcon from './createSvgIcon'
import styled from 'styled-components/macro'

// TODO: remove references to old bell, replace with this one

const NotificationIcon = createSvgIcon(
  <React.Fragment>
    <path
      d="M11.7392 18.9502H8.23923C7.53923 18.9502 6.93923 19.7502 7.33923 20.4502C7.93923 21.3502 8.93923 21.9502 10.0392 21.9502C11.1392 21.9502 12.1392 21.3502 12.6392 20.4502C13.0392 19.7502 12.5392 18.9502 11.7392 18.9502Z"
      fill="#FFC04F"
    />
    <path
      d="M19.8392 15.6502L19.4392 15.1502C17.8392 13.0502 17.0392 10.5502 17.0392 7.95023V7.25023C17.0392 3.65023 14.4392 0.450231 10.8392 0.0502315C6.63923 -0.449769 3.03923 2.85023 3.03923 6.95023V7.95023C3.03923 10.5502 2.23923 13.0502 0.63923 15.1502L0.239231 15.6502C0.0392305 15.8502 -0.0607695 16.2502 0.0392305 16.4502C0.33923 17.3502 1.13923 17.9502 2.03923 17.9502H18.0392C18.9392 17.9502 19.7392 17.3502 19.9392 16.4502C20.0392 16.1502 19.9392 15.8502 19.8392 15.6502Z"
      fill="white"
    />
  </React.Fragment>,
  'NotificationBell'
)

export default styled(NotificationIcon)`
  path {
    transform: translateX(5px) translateY(5px);
  }
`
