import { animated, useSpring } from 'react-spring'

export const AnimatedText = ({
  children,
  hide,
}: {
  children: React.ReactNode
  /**
   *  - determines if element should be hidden
   *  - also connected to spring style
   */
  hide?: boolean
}) => {
  const [style] = useSpring(() => ({
    opacity: hide ? 0 : 1,
    visibility: hide ? 'hidden' : 'visible',
  }))
  return <animated.div style={style as any}>{children}</animated.div>
}
