import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M14.9574,17.0673 C14.0613,15.918 12.6288,13.6911 12.6288,11.2014 C12.6288,8.7201 14.0703,6.4329 14.9718,5.2455 C15.9324,6.4236 17.046,8.3856 17.046,11.1459 C17.046,13.9242 15.9246,15.8895 14.9574,17.0673 M14.9388,3.0399 L14.8869,3 L14.8401,3.0462 C14.8029,3.0828 11.1,6.7815 11.1,11.2014 C11.1,15.6219 14.8035,19.2111 14.841,19.2468 L14.8878,19.2912 L14.9388,19.2519 C14.9751,19.2237 18.5751,16.3941 18.5751,11.1459 C18.5751,5.898 14.9751,3.0681 14.9388,3.0399" />
    <path d="M6.50727411,22.4742 C4.93347411,20.5572 4.59537411,17.8758 4.53807411,16.3863 C6.02967411,16.6881 8.13687411,17.4978 9.88557411,19.6278 C11.6492741,21.7755 12.0293741,24.0057 12.0293741,25.5297 C10.6070741,25.2096 8.08647411,24.3972 6.50727411,22.4742 M11.0672741,18.6576 C7.90857411,14.811 3.64887411,14.7 3.17367411,14.7 L3.11187411,14.7009 L3.04737411,14.7039 L3.04017411,14.7681 C3.03507411,14.82 2.52057411,20.0289 5.32557411,23.4441 C8.13087411,26.8605 13.2713741,27.2835 13.3229741,27.2874 L13.3871741,27.2925 L13.4012741,27.2298 C13.4120741,27.1848 14.3978741,22.7133 11.0672741,18.6576" />
    <path d="M23.8384426,22.4742 C22.2697426,24.384 19.7437426,25.2009 18.3169426,25.5246 C18.3175426,24.0021 18.6979426,21.7731 20.4601426,19.6278 C22.2082426,17.4987 24.3154426,16.6887 25.8073426,16.3863 C25.7503426,17.8755 25.4128426,20.5566 23.8384426,22.4742 M27.3055426,14.7681 L27.2983426,14.7039 L27.2338426,14.7009 L27.1720426,14.7 C26.6968426,14.7 22.4371426,14.811 19.2784426,18.6576 C15.9478426,22.7133 16.9336426,27.1848 16.9444426,27.2298 L16.9585426,27.2925 L17.0227426,27.2874 C17.0743426,27.2835 22.2148426,26.8605 25.0201426,23.4441 C27.8248426,20.0289 27.3106426,14.82 27.3055426,14.7681" />
  </React.Fragment>,
  'PlantProducts'
)
