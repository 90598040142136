import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <g fillRule="evenodd">
      <path d="M4.4688,10.0998 L25.6845,10.0998 L25.6845,4.4685 L4.4688,4.4685 L4.4688,10.0998 Z M7.4037,25.5315 L22.7493,25.5315 L22.7493,11.568 L7.4037,11.568 L7.4037,25.5315 Z M25.9275,3 L4.2255,3 C3.5499,3 3,3.5499 3,4.2258 L3,10.3425 C3,11.0184 3.5499,11.568 4.2255,11.568 L5.9352,11.568 L5.9352,25.7745 C5.9352,26.4504 6.4851,27 7.1607,27 L22.9923,27 C23.6682,27 24.2178,26.4504 24.2178,25.7745 L24.2178,11.568 L25.9275,11.568 C26.6031,11.568 27.153,11.0184 27.153,10.3425 L27.153,4.2258 C27.153,3.5499 26.6031,3 25.9275,3 L25.9275,3 Z" />
      <path d="M18.0372,15 L12.1341,15 C11.7291,15 11.4,15.3294 11.4,15.7341 C11.4,16.1391 11.7291,16.4682 12.1341,16.4682 L18.0372,16.4682 C18.4422,16.4682 18.7713,16.1391 18.7713,15.7341 C18.7713,15.3294 18.4422,15 18.0372,15" />
      <path d="M18.0372,20.4 L12.1341,20.4 C11.7291,20.4 11.4,20.7291 11.4,21.1341 C11.4,21.5391 11.7291,21.8685 12.1341,21.8685 L18.0372,21.8685 C18.4422,21.8685 18.7713,21.5391 18.7713,21.1341 C18.7713,20.7291 18.4422,20.4 18.0372,20.4" />
    </g>
  </React.Fragment>,
  'PackagedFlower'
)
