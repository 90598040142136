import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M14.7768,17.2536 C13.5765,17.2536 12.6,16.2771 12.6,15.0768 C12.6,13.8765 13.5765,12.9 14.7768,12.9 C15.9771,12.9 16.9536,13.8765 16.9536,15.0768 C16.9536,16.2771 15.9771,17.2536 14.7768,17.2536" />
    <path d="M4.57845,15.0408 C5.23935,16.3779 8.28285,21.675 14.99985,21.675 C21.65985,21.675 24.73395,16.3398 25.38555,15.0345 C24.73365,13.7004 21.71865,8.4 14.99985,8.4 C8.34285,8.4 5.24025,13.7298 4.57845,15.0408 M14.99985,23.175 C6.46335,23.175 3.09195,15.4074 3.05895,15.3288 C2.98035,15.1425 2.98035,14.9328 3.05895,14.7465 C3.09195,14.6679 6.46335,6.9 14.99985,6.9 C23.53875,6.9 26.87235,14.6709 26.90475,14.7492 C26.98185,14.934 26.98185,15.1413 26.90475,15.3258 C26.87235,15.4044 23.53875,23.175 14.99985,23.175" />
  </React.Fragment>,
  'View'
)
