import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M10.05354,14.0524859 C9.90594,14.0524859 9.75684,14.0095986 9.62634,13.9202746 C9.28644,13.6875 9.20184,13.2275704 9.43764,12.892162 C9.47244,12.8424718 10.30974,11.6614437 11.68524,10.5573169 C13.07814,9.43899296 15.08124,8.73002113 15.16584,8.70044366 C15.55464,8.56379577 15.98454,8.76433099 16.12314,9.1491338 C16.26204,9.53364085 16.05864,9.95659859 15.66864,10.0935423 C15.65004,10.1000493 13.82154,10.7495704 12.63264,11.7040352 C11.42934,12.6697394 10.67754,13.7250634 10.67004,13.7354155 C10.52394,13.9418662 10.29054,14.0524859 10.05354,14.0524859" />
    <path d="M23.69808,11.0153239 C23.20068,16.2262817 21.29448,19.0834648 19.78308,20.562338 C17.53248,22.7646761 14.98098,23.9288451 12.40458,23.9288451 C11.09958,23.9288451 9.92658,23.6271549 9.01788,23.2089296 C10.44618,22.5605915 13.20168,20.9231831 17.33688,16.8462254 C20.94528,13.2883521 22.84518,10.2374366 23.81568,8.2601831 C23.82168,9.0756338 23.79408,10.0105775 23.69808,11.0153239 M5.99988,17.5720563 C6.00348,15.741507 6.61278,12.9668451 9.49608,10.1451549 C11.30058,8.37938028 14.82978,6.27464789 21.39708,6.27464789 C22.00308,6.27464789 22.55328,6.29446479 23.02908,6.32226761 C22.44198,7.8425493 20.71398,11.4252676 16.27608,15.800662 C10.50768,21.4878169 7.66218,22.1512394 7.59228,22.1663239 C7.56588,22.1713521 7.54188,22.1808169 7.51668,22.1882113 C6.74448,21.3561972 5.99598,19.6469155 5.99988,17.5720563 M25.10448,5.03949296 C25.10448,5.03949296 23.59548,4.79577465 21.39708,4.79577465 C17.72868,4.79577465 12.14028,5.47428169 8.43948,9.09574648 C2.52138,14.8870141 4.48308,21.2958592 6.54738,23.3310845 C7.60008,24.3689577 9.75828,25.4077183 12.40458,25.4077183 C14.94678,25.4077183 17.94018,24.4497042 20.83998,21.6120423 C26.75808,15.8204789 25.10448,5.03949296 25.10448,5.03949296" />
  </React.Fragment>,
  'Seeds'
)
