import { animated } from 'react-spring'
import { BrandColors } from 'style/BrandColors'
import styled from 'styled-components/macro'

export const AnimatedMenu = styled(animated.ul)`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  max-height: 15rem;

  background: ${BrandColors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
  z-index: 1;

  border: 1px solid ${BrandColors.mountainMeadow};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: none;

  overflow-x: hidden;
  overflow-y: auto;
  /** allows smooth scrolling on tablets */
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${BrandColors.pastelBlue};
  }
`
