import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <g fillRule="evenodd">
      <path d="M11.4,19.33755 L18,19.33755 L18,14.73765 L11.4,14.73765 L11.4,19.33755 Z M22.7001,19.33755 L19.4001,19.33755 L19.4001,15.04785 C18.2745,14.72865 17.2659,14.13285 16.452,13.33755 L6.6999,13.33755 C6.3141,13.33755 6,13.65165 6,14.03775 C6,14.42355 6.3141,14.73765 6.6999,14.73765 L9.9999,14.73765 L9.9999,19.33755 L6.6999,19.33755 C6.3141,19.33755 6,19.65165 6,20.03775 C6,20.42355 6.3141,20.73765 6.6999,20.73765 L9.9999,20.73765 L9.9999,23.03775 C9.9999,23.42355 10.314,23.73765 10.7001,23.73765 C11.0859,23.73765 11.4,23.42355 11.4,23.03775 L11.4,20.73765 L18,20.73765 L18,23.03775 C18,23.42355 18.3141,23.73765 18.6999,23.73765 C19.086,23.73765 19.4001,23.42355 19.4001,23.03775 L19.4001,20.73765 L22.7001,20.73765 C23.0859,20.73765 23.4,20.42355 23.4,20.03775 C23.4,19.65165 23.0859,19.33755 22.7001,19.33755 L22.7001,19.33755 Z" />
      <path d="M8.4,5.23755 C8.4,4.78905 8.7513,4.43775 9.2001,4.43775 C9.6486,4.43775 9.9999,4.78905 9.9999,5.23755 L9.9999,8.23755 C9.9999,8.68605 9.6486,9.03765 9.2001,9.03765 C8.7513,9.03765 8.4,8.68605 8.4,8.23755 L8.4,5.23755 Z M25.2999,14.03655 L25.2999,24.33765 C25.2999,25.05465 24.717,25.63755 24,25.63755 L5.4,25.63755 C4.683,25.63755 4.1001,25.05465 4.1001,24.33765 L4.1001,8.73765 C4.1001,8.02065 4.683,7.43775 5.4,7.43775 L6.9999,7.43775 L6.9999,8.23755 C6.9999,9.45075 7.9869,10.43775 9.2001,10.43775 C10.413,10.43775 11.4,9.45075 11.4,8.23755 L11.4,7.43775 L14.4297,7.43775 C14.4957,6.95325 14.6106,6.48465 14.7711,6.03765 L11.4,6.03765 L11.4,5.23755 C11.4,4.02465 10.413,3.03765 9.2001,3.03765 C7.9869,3.03765 6.9999,4.02465 6.9999,5.23755 L6.9999,6.03765 L5.4,6.03765 C3.9114,6.03765 2.7,7.24875 2.7,8.73765 L2.7,24.33765 C2.7,25.82655 3.9114,27.03765 5.4,27.03765 L24,27.03765 C25.4889,27.03765 26.7,25.82655 26.7,24.33765 L26.7,12.71835 C26.2971,13.21995 25.8255,13.66365 25.2999,14.03655 L25.2999,14.03655 Z" />
      <path d="M15.91383,8.38128 C15.91383,5.40918 18.32313,2.99988 21.29523,2.99988 C24.26703,2.99988 26.67633,5.40918 26.67633,8.38128 C26.67633,11.35338 24.26703,13.76268 21.29523,13.76268 C18.32313,13.76268 15.91383,11.35338 15.91383,8.38128" />
      <path
        d="M24.82419,7.7268 L22.00269,7.7268 L22.00269,6.093 C22.00269,5.6841 21.66969,5.3511 21.26079,5.3511 C20.85159,5.3511 20.51889,5.6841 20.51889,6.093 L20.51889,8.4687 C20.51889,8.8779 20.85159,9.2106 21.26079,9.2106 L24.82419,9.2106 C25.23339,9.2106 25.56639,8.8779 25.56639,8.4687 C25.56639,8.0595 25.23339,7.7268 24.82419,7.7268"
        fill="rgba(255,255,255,0.9)"
      />
    </g>
  </React.Fragment>,
  'Timesheets'
)
