import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M25.0788,14.25 L6.7605,14.25 L13.5555,7.455 C13.8483,7.1619 13.8483,6.687 13.5555,6.3942 C13.2624,6.1014 12.7878,6.1014 12.495,6.3942 L4.4199,14.4696 C4.2732,14.6163 4.2,14.808 4.2,15 C4.2,15.192 4.2732,15.384 4.4199,15.5304 L12.4953,23.6058 C12.6417,23.7522 12.8337,23.8254 13.0254,23.8254 C13.2174,23.8254 13.4094,23.7522 13.5558,23.6058 C13.8486,23.3127 13.8486,22.8381 13.5558,22.545 L6.7605,15.75 L25.0788,15.75 C25.4931,15.75 25.8288,15.4143 25.8288,15 C25.8288,14.5857 25.4931,14.25 25.0788,14.25"
      transform="translate(15.014400, 15.000000) rotate(-180.000000) translate(-15.014400, -15.000000) "
    />
  </React.Fragment>,
  'ArrowNext'
)
