import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M12.17169,6.3 C13.17579,6.3 13.99269,7.1169 13.99269,8.121 C13.99269,9.1251 13.17579,9.9423 12.17169,9.9423 C11.16759,9.9423 10.35069,9.1251 10.35069,8.121 C10.35069,7.1169 11.16759,6.3 12.17169,6.3 M17.50989,6.3 C18.51429,6.3 19.33119,7.1169 19.33119,8.121 C19.33119,9.1251 18.51429,9.9423 17.50989,9.9423 C16.50609,9.9423 15.68919,9.1251 15.68919,8.121 C15.68919,7.1169 16.50609,6.3 17.50989,6.3 M15.88779,23.5773 L4.50009,23.5773 L4.50009,20.55 L14.95719,20.55 C14.95539,20.4897 14.94789,20.4306 14.94789,20.3697 C14.94789,19.4682 15.15339,18.6165 15.51099,17.85 L4.50009,17.85 L4.50009,11.7381 L12.84669,11.7381 L11.01639,13.5279 C10.72059,13.8177 10.71519,14.2926 11.00469,14.5887 C11.15169,14.7387 11.34639,14.8143 11.54079,14.8143 C11.73009,14.8143 11.91939,14.7432 12.06549,14.6004 L14.94039,11.7888 L17.71569,14.6262 C17.86239,14.7765 18.05709,14.8518 18.25179,14.8518 C18.44109,14.8518 18.63039,14.7807 18.77619,14.6379 C19.07229,14.3484 19.07769,13.8735 18.78789,13.5774 L16.98909,11.7381 L25.29249,11.7381 L25.29249,16.3059 C26.04969,17.1198 26.58249,18.1425 26.79249,19.281 L26.79249,11.5881 C26.79249,10.8438 26.18679,10.2381 25.44249,10.2381 L20.06649,10.2381 C20.54379,9.663 20.83119,8.925 20.83119,8.121 C20.83119,6.2898 19.34139,4.8 17.50989,4.8 C16.41579,4.8 15.44619,5.3343 14.84079,6.1536 C14.23539,5.3343 13.26609,4.8 12.17169,4.8 C10.34049,4.8 8.85069,6.2898 8.85069,8.121 C8.85069,8.925 9.13809,9.663 9.61509,10.2381 L4.35009,10.2381 C3.60549,10.2381 3.00009,10.8438 3.00009,11.5881 L3.00009,23.7273 C3.00009,24.4716 3.60549,25.0773 4.35009,25.0773 L17.25249,25.0773 C16.71639,24.6588 16.25469,24.1521 15.88779,23.5773" />
    <path d="M18.07428,20.02212 C18.07428,18.62802 19.20858,17.49372 20.60268,17.49372 C21.99678,17.49372 23.13078,18.62802 23.13078,20.02212 C23.13078,21.41622 21.99678,22.55052 20.60268,22.55052 C19.20858,22.55052 18.07428,21.41622 18.07428,20.02212 M26.61948,25.58682 L23.81928,22.52232 C24.35688,21.83142 24.68028,20.96532 24.68028,20.02212 C24.68028,17.77002 22.85478,15.94422 20.60268,15.94422 C18.35058,15.94422 16.52478,17.77002 16.52478,20.02212 C16.52478,22.27422 18.35058,24.10002 20.60268,24.10002 C21.35088,24.10002 22.04988,23.89482 22.65288,23.54292 L25.47558,26.63202 C25.62858,26.79942 25.83798,26.88402 26.04798,26.88402 C26.23428,26.88402 26.42178,26.81712 26.57028,26.68122 C26.88618,26.39262 26.90838,25.90272 26.61948,25.58682" />
  </React.Fragment>,
  'GiftCardInspect'
)
