import * as React from 'react'
import styled from 'styled-components/macro'
import { BrandColors as colors } from 'style/BrandColors'
import Badge from './Badge'
import { ColorOptions, IconProps } from './types'

interface SvgIconProps extends IconProps {
  children?: React.ReactNode
}

const Svg = styled.svg<{ color: ColorOptions }>`
  color: ${({ color }) => colorKey[color] || colors.graniteGray};
  display: inline-block;
  fill: ${({ color }) => colorKey[color] || colors.graniteGray};
  flex-shrink: 0;
  outline: none;
  user-select: none;
  transition: fill 255ms ease;
`

const colorKey = {
  inherit: 'inherit',
  primary: colors.mountainMeadow,
  secondary: colors.middleRedPurple,
  action: colors.hanBlue,
  error: colors.ruby,
  disabled: colors.taupeGray,
  warning: colors.mangoYellow,
}

/**
 *  Icons are used to help the user understand what they are looking at with a glance.
 *
 *  - Each Icon is wrapped in the same **SvgIcon** base component, and provides its own children.
 *  - Icons should be designed to have a viewBox of **0 0 30 30** and content should be centered.
 *  - Are non-focusable svg elements
 *  - Color can be customized by adding a css style rule targeting `svg` `fill` or by extending with `styled-components`
 */
export const SvgIcon = ({
  badge,
  children,
  color = 'inherit',
  size = '2rem',
  titleAccess,
  ...rest
}: SvgIconProps) => (
  <Svg
    aria-hidden={titleAccess ? 'false' : 'true'}
    color={color}
    focusable="false"
    height={size}
    width={size}
    viewBox="0 0 30 30"
    {...rest}
  >
    {children}
    {titleAccess ? <title>{titleAccess}</title> : null}
    {badge ? <Badge type={badge} /> : null}
  </Svg>
)

export default SvgIcon
