import styled, { css } from 'styled-components/macro'
import InputBaseComponentProps from '../inputBase.d'
import { inputBaseStyle } from './inputBaseStyle'

export interface TextInputProps {
  defaultValue?: string | number
  disabled?: boolean
  /** sets error style */
  error?: boolean
  max?: number | string
  min?: number | string
  /** name attr, required if used in a form */
  name: string
  placeholder?: string
  /** if input field is required */
  required: boolean
  /** sets valid (complete) field style */
  valid?: boolean
  width?: string
  /** type of input: text, number, phone, email, password, etc */
  type: string
}

/**
 * a styled-component Input of which many other inputs extend.
 */
const TextInput = styled.input<TextInputProps & InputBaseComponentProps>`
  ${inputBaseStyle};
  height: 3.25rem;
  width: ${({ width }) => (width ? width : null)};
  ${({ type }) =>
    type === 'number' &&
    css`
      ::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `};
`

TextInput.defaultProps = {
  disabled: false,
  error: false,
  placeholder: 'text input placeholder',
}

export { TextInput }
