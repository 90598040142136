import { lazy } from 'react'
import { safelyParseJSON } from 'utils/safelyParseJSON'
import { reloadWithoutCache } from 'utils/reloadWithoutCache'

const isChunkLoadError = (e: Error) =>
  `${e?.message}`.startsWith('ChunkLoadError')

const lsKey = 'lazyLoadFailed'

const didLazyLoadFail = () =>
  safelyParseJSON(localStorage.getItem(lsKey) || 'false')

const setLazyLoadFailed = (b: boolean) =>
  localStorage.setItem(lsKey, JSON.stringify(b))

// You can use this function to code-split out chunks of the application.
// Please *only* use this on *internal* sections of the application, that
// external users will *literally never need*. Otherwise, we run into the
// following pitfall:

//   1. user loads app
//   2. flowhub releases new version of the app
//   3. user (on the old version) navigates to a code-split portion of
//      the app whose js chunk no longer exists (we deployed over it).
export const lazyLoadComponent: typeof lazy = (importFn) =>
  // @ts-ignore
  lazy(async () => {
    try {
      const component = await importFn()
      setLazyLoadFailed(false)
      return component
    } catch (e) {
      if (!isChunkLoadError || didLazyLoadFail()) throw e
      setLazyLoadFailed(true)
      reloadWithoutCache()
    }
  })
