import { animated, config, useSpring } from 'react-spring'
import AriaModal from 'react-aria-modal'
import { CancelButton } from './CancelButton'
import styled, { css } from 'styled-components/macro'
import { BrandColors } from 'style/BrandColors'
import React from 'react'

export interface ModalProps {
  /** styles passed into CancelButton, for fine tuning */
  cancelButtonStyle?: React.CSSProperties
  /** your modal content, CancelButton, if rendered, is after children so it is accessible for a click */
  children: React.ReactNode
  /** for extending content container for modal */
  className?: string
  /** whether the modal should find an element to focus on.
   * Warning: if the modal cant find a focusable element an error will be thrown */
  focusDialog?: boolean
  /** optionally specify the modal content height.
   *  If you want to fill most of the screen, use: `100vh`
   */
  height?: string
  /** hook fired when user clicks CancelButton, also determines if CancelButton is rendered */
  onCancelClick?: () => void
  /** hook fired when modal detects an escape, or click off of modal to close */
  onExit?: () => void
  /** passed into AriaModal for a11y */
  title: string
  /** optionally specify the modal content width.
   *  If you want to fill most of the screen, use: `100vw`
   */
  width?: string
  modalContentStyle?: React.CSSProperties
}

const cardShadow = css`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`

export const Content = styled(animated.div)<{ height: string; width: string }>`
  background: ${BrandColors.white};
  box-sizing: border-box;
  ${cardShadow};
  height: ${({ height }) => height};
  /* we never want the page to scroll for a modal,
  the modal children should handle scroll */
  max-height: 96vh;
  max-width: 98vw;
  min-height: 10rem;
  min-width: 10rem;
  overflow: hidden;
  position: relative;
  width: ${({ width }) => width};
  will-change: transform;
`

/**
 *  Modal (dialog) component which uses a focus trap to disable scrolling/interactivity behind the overlay.
 *
 * Modal content has max height/width of: `96vh , 98vw` respectively
 *
 * Modal content has min height/width of `10rem`
 *
 * Ability to control how modal is cancelled, or if modal cannot be cancelled forcing user to complete action in modal
 *
 * This Modal is a wrapper for: [react-aria-modal](https://www.npmjs.com/package/react-aria-modal)
 */

export const Modal = ({
  cancelButtonStyle,
  className,
  children,
  focusDialog = true,
  height = '100%',
  onCancelClick,
  onExit,
  title,
  width = '100%',
  modalContentStyle = {},
}: ModalProps) => {
  const springStyle = useSpring({
    config: {
      ...config.stiff,
      clamp: true,
    },
    opacity: 1,
    transform: 'translateY(0%)',
    from: {
      opacity: 0,
      transform: 'translateY(-10%)',
    },
  })

  const showCancelButton =
    !!onCancelClick && typeof onCancelClick === 'function'

  return (
    <AriaModal
      focusDialog={focusDialog}
      onExit={onExit}
      titleText={title}
      underlayStyle={{ cursor: 'default' }}
      verticallyCenter
    >
      <Content
        className={className}
        data-testid="modal"
        height={height}
        style={{ ...modalContentStyle, ...springStyle }}
        width={width}
      >
        {children}
        {showCancelButton && (
          <CancelButton onClick={onCancelClick} style={cancelButtonStyle} />
        )}
      </Content>
    </AriaModal>
  )
}
