import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <g fillRule="evenodd">
      <path d="M24.4467,10.596 L5.55,10.596 L5.55,7.65 L10.2324,7.65 L19.7643,7.65 L24.4467,7.65 L24.4467,10.596 Z M10.2324,6.15 L19.7643,6.15 L19.7643,4.5 L10.2324,4.5 L10.2324,6.15 Z M7.596,25.5 L22.4007,25.5 L22.4007,12.0999 L7.596,12.0999 L7.596,25.5 Z M24.4467,6.15 L21.2643,6.15 L21.2643,4.5 C21.2643,3.675 20.5893,3 19.7643,3 L10.2324,3 C9.4074,3 8.7324,3.675 8.7324,4.5 L8.7324,6.15 L5.55,6.15 C4.725,6.15 4.05,6.825 4.05,7.65 L4.05,10.596 C4.05,11.421 4.725,12.096 5.55,12.096 L6.0966,12.096 C6.0966,12.0975 6.096,12.0987 6.096,12.1002 L6.096,25.5 C6.096,26.325 6.771,27 7.596,27 L22.4007,27 C23.2257,27 23.9007,26.325 23.9007,25.5 L23.9007,12.1002 C23.9007,12.0987 23.9001,12.0975 23.9001,12.096 L24.4467,12.096 C25.2717,12.096 25.9467,11.421 25.9467,10.596 L25.9467,7.65 C25.9467,6.825 25.2717,6.15 24.4467,6.15 L24.4467,6.15 Z" />
      <path d="M15,23.1999 C15.4143,23.1999 15.75,22.8642 15.75,22.4499 L15.75,14.85 C15.75,14.4357 15.4143,14.1 15,14.1 C14.5857,14.1 14.25,14.4357 14.25,14.85 L14.25,22.4499 C14.25,22.8642 14.5857,23.1999 15,23.1999" />
      <path d="M19.5,23.1999 C19.9143,23.1999 20.25,22.8642 20.25,22.4499 L20.25,14.85 C20.25,14.4357 19.9143,14.1 19.5,14.1 C19.0857,14.1 18.75,14.4357 18.75,14.85 L18.75,22.4499 C18.75,22.8642 19.0857,23.1999 19.5,23.1999" />
      <path d="M10.5,23.1999 C10.9143,23.1999 11.25,22.8642 11.25,22.4499 L11.25,14.85 C11.25,14.4357 10.9143,14.1 10.5,14.1 C10.0857,14.1 9.75,14.4357 9.75,14.85 L9.75,22.4499 C9.75,22.8642 10.0857,23.1999 10.5,23.1999" />
    </g>
  </React.Fragment>,
  'Trash'
)
