import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M15.10182,20.1 C15.10182,22.1946 16.80582,23.8983 18.90012,23.8983 C20.99472,23.8983 22.69842,22.1946 22.69842,20.1 C22.69842,18.0054 20.99472,16.3017 18.90012,16.3017 C16.80582,16.3017 15.10182,18.0054 15.10182,20.1 M16.60182,20.1 C16.60182,18.8328 17.63292,17.8017 18.90012,17.8017 C20.16732,17.8017 21.19842,18.8328 21.19842,20.1 C21.19842,21.3672 20.16732,22.3983 18.90012,22.3983 C17.63292,22.3983 16.60182,21.3672 16.60182,20.1" />
    <path d="M14.27619,19.35459 L3.75249,19.32729 C3.33819,19.32609 3.00129,19.66059 3.00009,20.07489 C2.99859,20.48919 3.33339,20.82609 3.74769,20.82729 L14.27769,20.85459 C14.22819,20.61069 14.20179,20.35839 14.20179,20.10009 C14.20179,19.84479 14.22789,19.59579 14.27619,19.35459" />
    <path d="M23.51988,20.86962 L26.24748,20.87262 C26.66178,20.87412 26.99868,20.53932 26.99988,20.12502 C27.00138,19.71072 26.66658,19.37412 26.25228,19.37262 L23.52648,19.36962 C23.57268,19.60632 23.59848,19.85022 23.59848,20.10012 C23.59848,20.36352 23.57118,20.62092 23.51988,20.86962" />
    <path d="M7.30182,10.09833 C7.30182,12.19293 9.00582,13.89663 11.10012,13.89663 C13.19472,13.89663 14.89842,12.19293 14.89842,10.09833 C14.89842,8.00373 13.19472,6.30003 11.10012,6.30003 C9.00582,6.30003 7.30182,8.00373 7.30182,10.09833 M8.80182,10.09833 C8.80182,8.83113 9.83292,7.80003 11.10012,7.80003 C12.36732,7.80003 13.39842,8.83113 13.39842,10.09833 C13.39842,11.36553 12.36732,12.39663 11.10012,12.39663 C9.83292,12.39663 8.80182,11.36553 8.80182,10.09833" />
    <path d="M15.72138,10.86156 L26.24748,10.88586 C26.66178,10.88706 26.99868,10.55256 26.99988,10.13826 C27.00138,9.72396 26.66658,9.38706 26.25228,9.38586 L15.72528,9.36156 C15.77238,9.60006 15.79848,9.84606 15.79848,10.09836 C15.79848,10.35966 15.77178,10.61496 15.72138,10.86156" />
    <path d="M6.47715,9.34653 L3.75225,9.34053 C3.33825,9.33903 3.00135,9.67383 2.99985,10.08813 C2.99865,10.50243 3.33345,10.83903 3.74775,10.84053 L6.47655,10.84653 C6.42795,10.60443 6.40185,10.35453 6.40185,10.09833 C6.40185,9.84093 6.42825,9.58953 6.47715,9.34653" />
  </React.Fragment>,
  'Filter'
)
