import styled from 'styled-components/macro'
import { TextInput } from '../TextInput'
import { inputBaseStyle } from 'style/inputBaseStyle'
import { BrandColors } from 'style/BrandColors'

export const Adornment = styled.div`
  display: inline-flex;
  padding: 0.4rem;
  visibility: visible;
  flex-shrink: 0;

  > button {
    height: 100%;
    padding-bottom: 0;
    padding-top: 0;
  }
`

export const StyledTextInput = styled(TextInput)`
  background: transparent;
  border: none;
  flex: 1;
  height: inherit;
  padding: 0;

  ::placeholder {
    padding-left: 0;
    padding-right: 0;
  }
`

export const Wrap = styled.div<{
  disabled?: boolean
  error?: boolean
  hasStartAdornment?: boolean
  hasEndAdornment?: boolean
}>`
  ${inputBaseStyle};
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  height: 3.25rem;
  overflow: hidden;
  padding: 0;
  padding-left: ${({ hasStartAdornment }) =>
    !hasStartAdornment ? '1rem' : '0rem'};
  padding-right: ${({ hasEndAdornment }) =>
    !hasEndAdornment ? '1rem' : '0rem'};

  /** sc-block is req bc styled-components interpolation creates warning in stylelint  */
  ${
    /* sc-block */ ({ disabled }) =>
      disabled &&
      `
      background-color: ${BrandColors.whiteSmoke};
      border-color: ${BrandColors.isabelline};
      color: ${BrandColors.pastelBlue};
      user-select: none;
  `
  }
  :focus-within {
    border-color: ${BrandColors.mountainMeadow};
  }
`

export const IconWrap = styled.div`
  display: inline-flex;
  height: inherit;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  fill: ${BrandColors.pastelBlue};
  flex-shrink: 0;

  /** sc-selector is req bc styled-components interpolation creates warning in stylelint  */
  ${/* sc-selector */ Wrap}:focus-within & {
    fill: ${BrandColors.middleRedPurple};
  }
`
