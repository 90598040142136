import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <g transform="translate(15.000000, 15.000000) rotate(-90.000000) translate(-15.000000, -15.000000) ">
      <path
        d="M25.95558,27.28242 L3.44988,27.28242 C3.19008,27.28242 2.94888,27.14802 2.81208,26.92662 C2.67528,26.70582 2.66298,26.42982 2.77908,26.19732 L14.00568,3.71472 C14.13258,3.46092 14.39208,3.30042 14.67588,3.30012 L14.67678,3.30012 C14.96058,3.30012 15.21978,3.46002 15.34728,3.71352 L26.62578,26.19612 C26.74248,26.42862 26.73048,26.70492 26.59398,26.92632 C26.45718,27.14772 26.21568,27.28242 25.95558,27.28242"
        transform="translate(14.702761, 15.291270) rotate(-270.000000) translate(-14.702761, -15.291270) "
      />
      <path
        d="M13.48509,8.52333 L13.31259,18.23073 L11.72019,18.23073 L11.56659,8.52333 L13.48509,8.52333 Z M12.52599,21.95283 C11.77779,21.95283 11.24079,21.49203 11.24079,20.76333 C11.24079,20.01513 11.77779,19.55463 12.52599,19.55463 C13.25499,19.55463 13.79199,20.01513 13.79199,20.76333 C13.79199,21.49203 13.25499,21.95283 12.52599,21.95283 L12.52599,21.95283 Z"
        fill="rgba(255,255,255,0.9)"
        transform="translate(12.516390, 15.238080) rotate(-270.000000) translate(-12.516390, -15.238080) "
      />
    </g>
  </React.Fragment>,
  'Alert'
)
