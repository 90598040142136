import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M26.1867,15.3094049 C26.1627,15.2962049 26.1336,15.2968049 26.1105,15.3118049 L24.8226,16.1266049 C24.801,16.1404049 24.7878,16.1641049 24.7878,16.1899049 L24.7878,25.3102049 C24.7878,25.3477049 24.7686,25.3666049 24.7314,25.3666049 L5.5704,25.3666049 C5.5332,25.3666049 5.5143,25.3477049 5.5143,25.3102049 L5.5143,16.1899049 C5.5143,16.1638049 5.5008,16.1398049 5.4789,16.1260049 L4.1646,15.3112049 C4.1415,15.2971049 4.1121,15.2965049 4.0884,15.3094049 C4.0647,15.3226049 4.05,15.3478049 4.05,15.3751049 L4.05,25.3102049 C4.05,26.1346049 4.7463,26.8309049 5.5704,26.8309049 L24.7053,26.8309049 C25.5294,26.8309049 26.2257,26.1346049 26.2257,25.3102049 L26.2257,15.3751049 C26.2257,15.3478049 26.2107,15.3226049 26.1867,15.3094049" />
    <path d="M25.9395,9.1707 C25.9155,9.1572 25.8864,9.1581 25.8633,9.1731 L15.5961,15.6711 L15.5961,3.375 C15.5961,3.3336 15.5625,3.3 15.5211,3.3 L14.2074,3.3 C14.166,3.3 14.1324,3.3336 14.1324,3.375 L14.1324,15.6711 L3.8652,9.1731 C3.8418,9.1581 3.8127,9.1572 3.789,9.1707 C3.765,9.1839 3.75,9.2091 3.75,9.2364 L3.75,11.0499 C3.75,11.0757 3.7632,11.0994 3.7848,11.1132 L14.8242,18.1047 C14.8365,18.1128 14.8503,18.1164 14.8644,18.1164 C14.8785,18.1164 14.8923,18.1128 14.9046,18.1047 L25.9437,11.1132 C25.9653,11.0994 25.9785,11.0757 25.9785,11.0499 L25.9785,9.2364 C25.9785,9.2091 25.9635,9.1839 25.9395,9.1707" />
  </React.Fragment>,
  'CheckIn'
)
