import LogRocket from 'logrocket'

export type TrackProperties = {
  eventName: string
  [key: string]: any
}

export const track = ({ eventName, ...properties }: TrackProperties) => {
  LogRocket.track(eventName, properties)
}
