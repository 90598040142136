import * as React from 'react'
import { AnimatedText } from './AnimatedText'
import styled from 'styled-components/macro'
import { Button } from 'components/Button'
import { Spinner } from 'components/FlowhubUIComponents'

export interface AsyncLoadingButtonProps {
  /** content displayed when not loading */
  children: React.ReactNode
  /** determines if the loader should be shown */
  loading?: boolean
}

export const StyledButton = styled(Button)<{ loading?: string }>`
  ${({ loading }) =>
    !!loading &&
    `
    outline: none;
    pointer-events: none;
  `}

  position: relative;
`
/**
 * Button for communicating an async action, which renders a loading spinner until your action is complete.
 */
export const AsyncLoadingButton = ({
  children,
  loading = false,
  ...buttonProps
}: AsyncLoadingButtonProps) => {
  return (
    <StyledButton loading={loading ? 'true' : undefined} {...buttonProps}>
      <>
        {!!loading && (
          <Spinner style={{ position: 'absolute', marginRight: 0 }} />
        )}
        <AnimatedText hide={loading}>{children}</AnimatedText>
      </>
    </StyledButton>
  )
}
