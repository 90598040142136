import * as React from 'react'
import { animated, useSpring } from 'react-spring'
import { SvgIcon } from './SvgIcon'
import { IconProps } from './types'

export function createSvgIcon(
  path: React.ReactElement[] | React.ReactElement,
  displayName: string
) {
  const Icon = ({ addStroke, ...rest }: IconProps) => {
    const springProps = useSpring({
      strokeWidth: `${addStroke || 0}px`,
      stroke: 'currentcolor',
    })

    /** check if element type is path,
     * create a new animated.path element (react-spring)
     * with the original path's props added to it */
    const animatePath = (child: React.ReactElement) => {
      /** if not a path, return original element */
      if (child.type !== 'path') return child

      return React.createElement(animated.path, {
        ...child.props,
        ...springProps,
      })
    }
    /** run animatePath on each child */
    const updatedPaths = React.Children.map(path, animatePath)

    return (
      <SvgIcon data-testid={`${displayName}-icon`} {...rest}>
        {updatedPaths}
      </SvgIcon>
    )
  }

  Icon.displayName = displayName

  return Icon
}

export default createSvgIcon
