import { DropdownExpand } from 'components/Icons'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components/macro'

export interface AnimatedTriangleProps {
  /** initiates rotate transoform animation - 180deg */
  rotate?: boolean
  /** css height and width properties, ie: 1.5rem, 100%, 2px, etc */
  size?: string
}

const Svg = styled(animated.svg)`
  fill: currentcolor;
  outline: none;
  user-select: none;
  transition: fill 255ms ease;
  transform-origin: center;
  will-change: transform;

  polygon {
    stroke: currentcolor;
  }
`
/**
 *  An animated DropdownExpand icon that animates by rotating 90deg when rotate prop changes.
 * 

 */
export const AnimatedTriangle = ({
  rotate = false,
  size = '1.5rem',
}: AnimatedTriangleProps) => {
  const springProps = useSpring({
    transform: `rotate(${rotate ? '180' : '0'}deg)`,
  })

  return (
    <Svg
      aria-hidden="true"
      focusable="false"
      height={size}
      width={size}
      style={springProps}
      viewBox="0 0 30 30"
    >
      <DropdownExpand />
    </Svg>
  )
}
