import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M13.1469395,25.431 L16.8018395,25.422 L17.2806395,22.1037 L20.0055395,20.5308 L23.1267395,21.7767 L24.9519395,18.6123 L22.3089395,16.533 L22.3089395,13.3983 L24.9525395,11.319 L23.1267395,8.1543 L20.0049395,9.4005 L17.2806395,7.8276 L16.8006395,4.5 L13.1457395,4.509 L12.6669395,7.8279 L9.94263953,9.4005 L6.82113953,8.1543 L4.99563953,11.319 L5.00253953,11.3247 L7.63893953,13.3983 L7.63893953,16.5327 L4.99563953,18.6123 L6.82143953,21.7767 L6.82953953,21.7734 L9.94203953,20.5308 L12.6666395,22.1034 L13.1469395,25.431 Z M16.9440395,26.931 L13.0032395,26.931 C12.2793395,26.931 11.6865395,26.3364 11.6547395,25.5924 L11.2863395,23.0385 L9.82263953,22.1937 L7.42683953,23.1498 C7.22103953,23.2572 6.99243953,23.3136 6.76263953,23.3136 C6.27693953,23.3136 5.82903953,23.0595 5.59323953,22.65 L3.62433953,19.2369 C3.26283953,18.6096 3.48213953,17.799 4.11003953,17.4 L6.13893953,15.8043 L6.13893953,14.127 L4.11003953,12.531 C3.48213953,12.1317 3.26283953,11.3211 3.62433953,10.6941 L5.59353953,7.2804 C5.82903953,6.8715 6.27723953,6.6174 6.76233953,6.6174 C6.99213953,6.6174 7.22073953,6.6738 7.42653953,6.7809 L9.82263953,7.7376 L11.2860395,6.8928 L11.6547395,4.3389 C11.6865395,3.5949 12.2793395,3 13.0032395,3 L16.9440395,3 C17.6679395,3 18.2607395,3.5949 18.2925395,4.3386 L18.6612395,6.8928 L20.1246395,7.7376 L22.5207395,6.7809 C22.7271395,6.6738 22.9551395,6.6174 23.1852395,6.6174 C23.6709395,6.6174 24.1188395,6.8718 24.3543395,7.281 L26.3232395,10.6941 C26.6847395,11.3217 26.4654395,12.132 25.8378395,12.531 L23.8089395,14.127 L23.8089395,15.8046 L25.8372395,17.4 C26.1240395,17.5821 26.3352395,17.8551 26.4357395,18.1752 C26.5476395,18.5322 26.5062395,18.9195 26.3229395,19.2372 L24.3540395,22.6506 C24.1182395,23.0598 23.6703395,23.3136 23.1849395,23.3136 C22.9548395,23.3136 22.7268395,23.2572 22.5207395,23.1498 L20.1252395,22.1937 L18.6612395,23.0388 L18.2925395,25.5924 C18.2607395,26.3364 17.6679395,26.931 16.9440395,26.931 L16.9440395,26.931 Z" />
    <path d="M14.904,12.6 C13.551,12.6 12.45,13.7007 12.45,15.054 C12.45,16.407 13.551,17.5077 14.904,17.5077 C16.2573,17.5077 17.3583,16.407 17.3583,15.054 C17.3583,13.7007 16.2573,12.6 14.904,12.6 M14.904,19.0077 C12.7239,19.0077 10.95,17.2341 10.95,15.054 C10.95,12.8739 12.7239,11.1 14.904,11.1 C17.0844,11.1 18.8583,12.8739 18.8583,15.054 C18.8583,17.2341 17.0844,19.0077 14.904,19.0077" />
  </React.Fragment>,
  'Manage'
)
