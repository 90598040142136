import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { ManageLoader } from './components/ManageLoader'
import { initializeApm } from './apm'
import { createBrowserHistory } from 'history'
import { lazyLoadComponent } from 'lazyLoadComponent'
import { IS_TEST } from './constants/isttest'
import { GIT_SHA } from './constants/version'
import { APP_TYPE } from './constants/whereami'

const GlobalStyle = lazyLoadComponent(() => import('style/GlobalStyle'))
const Root = lazyLoadComponent(() => import('./Root'))

const browserHistory = createBrowserHistory({
  // override default window.alert user notification when a route change is blocked
  // use <RouterPrompt /> to display custom route change confirmation modal
  getUserConfirmation: () => {},
})

initializeApm()

if (!IS_TEST) {
  console.log('config:', window.CONFIG)
  console.log('release:', GIT_SHA)
  console.log('platform:', APP_TYPE)
}

// default browser behavior is to inc/dec a numeric text field on scroll;
// we disable this behavior (MAUI-387).
document.addEventListener('wheel', (e) => {
  // @ts-ignore
  if (document.activeElement?.type !== 'number') return
  // @ts-ignore
  document.activeElement?.blur?.()
})

window.fakeScan = (str: string) => {
  for (const key of str.split('')) {
    document.dispatchEvent(new KeyboardEvent('keydown', { key }))
  }
  // @ts-ignore
  document.dispatchEvent(new KeyboardEvent('keydown', { which: 13 }))
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <>
    <React.Suspense fallback={null}>
      <GlobalStyle />
    </React.Suspense>
    <React.Suspense fallback={<ManageLoader loadingMessage="" />}>
      <Root history={browserHistory} />
    </React.Suspense>
  </>
)
