import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M10.5750742,14.0754 L14.3700742,10.2804 C14.6628742,9.9873 14.6628742,9.5124 14.3700742,9.2196 C14.0769742,8.9268 13.6023742,8.9268 13.3095742,9.2196 L8.23447418,14.295 C8.08777418,14.4417 8.01457418,14.6334 8.01457418,14.8254 C8.01457418,15.0174 8.08777418,15.2094 8.23447418,15.3558 L13.3098742,20.4312 C13.4562742,20.5776 13.6482742,20.6508 13.8399742,20.6508 C14.0319742,20.6508 14.2239742,20.5776 14.3703742,20.4312 C14.6631742,20.1381 14.6631742,19.6635 14.3703742,19.3704 L10.5750742,15.5754 L21.2642258,15.5754 C21.6785258,15.5754 22.0142258,15.2397 22.0142258,14.8254 C22.0142258,14.4111 21.6785258,14.0754 21.2642258,14.0754 L10.5750742,14.0754 Z"
      transform="translate(15.014400, 14.825400) rotate(90.000000) translate(-15.014400, -14.825400) "
    ></path>
  </React.Fragment>,
  'ArrowUp'
)
