import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M8.1195,4.5408 C10.0926,4.5408 11.6982,6.1464 11.6982,8.1198 C11.6982,10.0929 10.0926,11.6982 8.1195,11.6982 C6.1464,11.6982 4.5408,10.0929 4.5408,8.1198 C4.5408,6.1464 6.1464,4.5408 8.1195,4.5408 M8.1195,13.239 C10.9425,13.239 13.239,10.9425 13.239,8.1198 C13.239,5.2968 10.9425,3 8.1195,3 C5.2965,3 3,5.2968 3,8.1198 C3,10.9425 5.2965,13.239 8.1195,13.239" />
    <path d="M21.9195,4.5408 C23.8926,4.5408 25.4982,6.1464 25.4982,8.1198 C25.4982,10.0929 23.8926,11.6982 21.9195,11.6982 C19.9464,11.6982 18.3408,10.0929 18.3408,8.1198 C18.3408,6.1464 19.9464,4.5408 21.9195,4.5408 M21.9195,13.239 C24.7425,13.239 27.039,10.9425 27.039,8.1198 C27.039,5.2968 24.7425,3 21.9195,3 C19.0965,3 16.8,5.2968 16.8,8.1198 C16.8,10.9425 19.0965,13.239 21.9195,13.239" />
    <path d="M8.4192,18.3408 C10.3926,18.3408 11.9982,19.9464 11.9982,21.9195 C11.9982,23.8929 10.3926,25.4982 8.4192,25.4982 C6.4461,25.4982 4.8405,23.8929 4.8405,21.9195 C4.8405,19.9464 6.4461,18.3408 8.4192,18.3408 M8.4192,16.8 C5.5965,16.8 3.3,19.0968 3.3,21.9195 C3.3,24.7425 5.5965,27.039 8.4192,27.039 C11.2422,27.039 13.5387,24.7425 13.5387,21.9195 C13.5387,19.0968 11.2422,16.8 8.4192,16.8" />
    <path d="M21.9195,25.4982 C19.9464,25.4982 18.3408,23.8926 18.3408,21.9192 C18.3408,19.9461 19.9464,18.3408 21.9195,18.3408 C23.8926,18.3408 25.4982,19.9461 25.4982,21.9192 C25.4982,23.8926 23.8926,25.4982 21.9195,25.4982 M21.9195,16.8 C19.0965,16.8 16.8,19.0965 16.8,21.9192 C16.8,24.7422 19.0965,27.039 21.9195,27.039 C24.7425,27.039 27.039,24.7422 27.039,21.9192 C27.039,19.0965 24.7425,16.8 21.9195,16.8" />
  </React.Fragment>,
  'Rooms'
)
