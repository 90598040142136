import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path
      id="one"
      d="M9.76,27.74l2.33-.68a.93.93,0,0,0,.53-.35l1.48-1.86a.17.17,0,0,0,0-.19.15.15,0,0,0-.18-.06l-2.33.68a1,1,0,0,0-.53.35L9.59,27.49a.14.14,0,0,0,0,.19A.16.16,0,0,0,9.76,27.74Z"
    />
    <path
      id="two"
      d="M6.9,19.45l-5.49,0a.16.16,0,0,0-.15.12.15.15,0,0,0,.05.17l4.43,3.19a4.19,4.19,0,0,0,2.47.8l5.47,0a.17.17,0,0,0,.16-.12c0-.07,0-.12-.06-.17L9.36,20.25A4.27,4.27,0,0,0,6.9,19.45Z"
    />
    <path
      id="three"
      d="M13.26,21.8,10.63,14a4.25,4.25,0,0,0-1.54-2.08L2.36,7.15a.17.17,0,0,0-.19,0,.18.18,0,0,0-.06.17l2.64,7.79a4.19,4.19,0,0,0,1.54,2.08L13,22a.17.17,0,0,0,.19,0A.14.14,0,0,0,13.26,21.8Z"
    />
    <path
      id="four"
      d="M14.84,2.37l-3.08,9.48a4.18,4.18,0,0,0,0,2.57l3.08,9.48A.16.16,0,0,0,15,24a.16.16,0,0,0,.15-.12l3.08-9.48a4.18,4.18,0,0,0,0-2.57L15.14,2.37A.15.15,0,0,0,15,2.26.17.17,0,0,0,14.84,2.37Z"
    />
    <path
      id="five"
      d="M25.23,15.13l2.64-7.79a.16.16,0,0,0-.25-.17L20.89,12A4.24,4.24,0,0,0,19.35,14l-2.64,7.79A.16.16,0,0,0,17,22l6.73-4.78A4.07,4.07,0,0,0,25.23,15.13Z"
    />
    <path
      id="six"
      d="M20.64,20.25l-4.43,3.19a.15.15,0,0,0-.05.17.18.18,0,0,0,.15.12l5.47,0a4.26,4.26,0,0,0,2.48-.8l4.42-3.19a.14.14,0,0,0,.06-.17.17.17,0,0,0-.16-.12l-5.47,0A4.23,4.23,0,0,0,20.64,20.25Z"
    />
    <path
      id="seven"
      d="M15.88,24.83l1.48,1.86a1.07,1.07,0,0,0,.53.36l2.33.67a.15.15,0,0,0,.17,0,.17.17,0,0,0,0-.19l-1.47-1.86a1.07,1.07,0,0,0-.53-.36l-2.33-.67a.15.15,0,0,0-.18.06A.15.15,0,0,0,15.88,24.83Z"
    />
  </React.Fragment>,
  'Logo'
)
