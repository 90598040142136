import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M14.2122,26.9007 L8.9676,19.2609 L7.206,22.4226 C5.4732,20.5059 4.5,18.0213 4.5,15.4038 C4.5,10.2633 8.1762,5.9139 13.2411,5.0625 C13.6497,4.9938 13.9251,4.6068 13.8564,4.1985 C13.7877,3.7902 13.4001,3.516 12.9927,3.5829 C7.2024,4.5567 3,9.528 3,15.4038 C3,18.6084 4.2744,21.6384 6.5256,23.8821 L4.9233,26.9049 L14.2122,26.9007 Z" />
    <path d="M23.03916,6.44421 L24.70596,3.29991 L15.41706,3.30411 L20.66196,10.94391 L22.37136,7.87521 C24.38016,9.84441 25.51896,12.52101 25.51896,15.35301 C25.51896,20.49351 21.84276,24.84261 16.77786,25.69431 C16.36896,25.76301 16.09356,26.14971 16.16226,26.55831 C16.22406,26.92461 16.54146,27.18411 16.90116,27.18411 C16.94226,27.18411 16.98426,27.18081 17.02626,27.17361 C22.81626,26.20011 27.01896,21.22881 27.01896,15.35301 C27.01896,11.93901 25.57326,8.72181 23.03916,6.44421" />
  </React.Fragment>,
  'Refresh'
)
