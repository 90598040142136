import * as React from 'react'
import styled, { css } from 'styled-components/macro'
import { BrandColors } from 'style/BrandColors'

import { ButtonProps, ButtonColorTheme, StyledButtonProps } from './types'
import { track } from '../../utils/track'

export const colorTheme: { [key: string]: ButtonColorTheme } = {
  danger: {
    background: {
      active: 'transparent',
      default: 'transparent',
      hover: 'transparent',
    },
    border: {
      active: BrandColors.ruby,
      default: BrandColors.ruby,
      hover: BrandColors.ruby,
      focus: BrandColors.ruby,
    },
    color: BrandColors.ruby,
  },

  primary: {
    background: {
      active: '#E58047',
      default: BrandColors.mangoYellow,
      hover: BrandColors.sunsetOrange,
    },
    color: BrandColors.darkPurple,
  },
  secondary: {
    background: {
      active: '#6E57B7',
      default: BrandColors.darkPurple,
      hover: '#775DCA',
    },
    color: BrandColors.white,
  },
  tertiary: {
    background: {
      active: BrandColors.royal,
      default: 'transparent',
      hover: '#B694DA',
    },
    border: {
      active: BrandColors.royal,
      default: BrandColors.darkPurple,
      hover: '#B694DA',
      focus: BrandColors.darkPurple,
    },
    color: BrandColors.darkPurple,
  },
  XTREME: {
    background: {
      active: BrandColors.cyclamen,
      default: BrandColors.ruby,
      hover: BrandColors.cyclamen,
    },
    border: {
      active: BrandColors.cyclamen,
      default: BrandColors.ruby,
      hover: BrandColors.cyclamen,
      focus: BrandColors.cyclamen,
    },
    color: BrandColors.white,
  },
}

export const buttonStyles = css<StyledButtonProps>`
  background: ${({ colorPalette }) => colorPalette.background.default};
  color: ${({ colorPalette }) => colorPalette.color};
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  flex-shrink: 0;
  font-family: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0;
  border: ${({ colorPalette }) =>
    colorPalette.border
      ? `1px solid ${colorPalette.border.default}`
      : '1px solid transparent'};
  padding: ${({ size }) =>
    size === 'small'
      ? '0.25rem 0.5rem'
      : size === 'medium'
      ? '0.5rem'
      : '1rem'};
  text-align: center;
  text-transform: uppercase;
  transition: all 255ms ease;
  user-select: none;
  width: ${({ fullWidth }) => (!!fullWidth ? '100%' : 'auto')};

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    fill: currentcolor;

    > svg {
      margin-right: 0.75rem;
      height: 1rem;
      width: 1rem;
      transform: scale3d(1.5, 1.5, 1.5);
    }
  }

  :disabled {
    background: ${BrandColors.lightGray40};
    border: 1px solid ${BrandColors.lightGray40};
    color: ${BrandColors.darkGray20};
    cursor: not-allowed;
    pointer-events: none;
  }

  :focus {
    ${({ colorPalette }) =>
      colorPalette.border && `border-color: ${colorPalette.border.focus};`}
  }

  :enabled:active {
    background: ${({ colorPalette }) => colorPalette.background.active};
    box-shadow: none;
    outline: none;
    ${({ colorPalette }) =>
      colorPalette.border && `border-color: ${colorPalette.border.active};`}
  }

  :enabled:active > div {
    transform: translateY(0.5px);
  }

  :enabled:hover:not(:active),
  :focus {
    background: ${({ colorPalette }) => colorPalette.background.hover};
    box-shadow: 0 3px 8px 0 ${BrandColors.lightGray40};
    outline: none;
    ${({ colorPalette }) =>
      colorPalette.border && `border-color: ${colorPalette.border.hover};`}
  }
`

const StyledButton = styled.button<StyledButtonProps>`
  ${buttonStyles}
`

/**
 * Primary UI component for user interaction
 *
 *  Buttons are clickable elements that are used to trigger actions.
 *  They communicate calls to action to the user and allow users to interact with pages in a variety of ways.
 *
 * - Dialogs
 * - Modals
 * - Forms
 */
export const Button = ({
  color = 'tertiary',
  children,
  disabled = false,
  fullWidth = false,
  size = 'default',
  type = 'button',
  tracking,
  onClick,
  ...rest
}: ButtonProps) => {
  return (
    <StyledButton
      colorPalette={colorTheme[color]}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
      type={type}
      onClick={(e) => {
        onClick && onClick(e)
        tracking && track(tracking)
      }}
      {...rest}
    >
      <div>{children}</div>
    </StyledButton>
  )
}
