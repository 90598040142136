import {
  AnimatedBubbles,
  AnimatedSmoke,
  AnimatedStem,
  AnimatedWater,
  StyledBongLoader,
  StyledMessage,
  Wrap,
} from './Styles'

export type AvailColors = 'blue' | 'green' | 'gray' | 'ruby'

export interface BongLoaderProps {
  /** The base color of the SVG */
  color?: AvailColors
  /** The text displayed below the loader */
  loadingMessage?: string
  /** The size of loader calculated in rems*/
  size?: number
}

/**
 *  Loader with animation of someone taking a bong rip.
 *
 *  Can be used as an overlay loading or transition screen.
 *
 *
 */
export const BongLoader = ({
  color = 'green',
  loadingMessage,
  size = 13,
  ...rest
}: BongLoaderProps) => {
  const fontSize = `${size * 0.1}rem`
  return (
    <Wrap>
      <StyledBongLoader
        color={color}
        viewBox="0 0 160 160"
        height={`${size}rem`}
        width={`${size}rem`}
        {...rest}
      >
        <g id="base_green" data-name="base green">
          <path
            className="cls-1"
            d="M80,3a77,77,0,1,0,77,77A77,77,0,0,0,80,3Z"
            transform="translate(0 0)"
          />
        </g>
        <AnimatedSmoke id="smoke">
          <g className="cls-2">
            <path
              className="cls-3"
              d="M74.75,9.41c.49,2.67-1.59,4.76-2.69,7.06-.74,1.56-1.72,3.15-1.13,4.81s1.77,3.47,2.59,5.23A14.25,14.25,0,0,1,74.75,30c.47,2.7-1.61,4.82-2.74,7.16-.73,1.5-1.67,3.08-1.09,4.7.63,1.8,1.75,3.49,2.55,5.26a14.65,14.65,0,0,1,1.28,3.7c.4,2.61-1.65,4.66-2.72,6.93-.72,1.51-1.68,3.08-1.11,4.7.63,1.8,1.74,3.49,2.55,5.25a15.09,15.09,0,0,1,1.28,3.44c.49,2.71-1.57,4.84-2.7,7.18-.72,1.51-1.69,3.06-1.14,4.69.62,1.81,1.71,3.5,2.5,5.28a15.45,15.45,0,0,1,1.34,3.79c.38,2.6-1.63,4.66-2.73,6.93-.72,1.47-1.65,3-1.12,4.58a23.08,23.08,0,0,0,2.48,5.28c1.91,2.91,1.71,5.66,0,8.57a59.22,59.22,0,0,0-2.62,5.74h2.59c-.35-2,1.36-3.5,2.09-5.23a41,41,0,0,0,1.79-4.32c.55-1.93-1-3.49-1.74-5.18-.54-1.19-1.4-2.3-1.87-3.51-1.08-2.83.34-5.31,1.77-7.82a17.54,17.54,0,0,0,1.84-4.54c.44-1.77-1-3.23-1.75-4.8-.54-1.2-1.4-2.3-1.86-3.52-1.08-2.83.36-5.3,1.8-7.8.9-1.59,2.26-3.18,1.81-5a23,23,0,0,0-1.8-4.32c-.56-1.19-1.37-2.32-1.83-3.53-1.07-2.8.42-5.23,1.77-7.71a11.25,11.25,0,0,0,1.78-4.77c0-1.51-1-3.06-1.66-4.56C75,45.32,74.1,44.21,73.64,43c-1.06-2.83.4-5.3,1.83-7.8a10.17,10.17,0,0,0,1.71-4.68c0-1.57-1-3.15-1.7-4.69-.53-1.2-1.38-2.31-1.84-3.53-1.07-2.79.36-5.24,1.78-7.7.91-1.58,2.27-3.16,1.84-5ZM73.23,120c-.31.71-.61,1.42-.91,2.14A5.92,5.92,0,0,1,73.23,120Z"
              transform="translate(0 0)"
            />
          </g>
          <g>
            <path
              className="cls-3"
              d="M81.34,16.88a22,22,0,0,1,1.2,5.39c.2,3.78-2.83,6.67-4.63,9.91-1.21,2.16-2.79,4.38-2.26,6.75.61,2.62,1.92,5.09,2.82,7.66a20.74,20.74,0,0,1,1.35,5c.34,3.92-3,6.92-5.07,10.26-1.34,2.15-3.08,4.36-2.52,6.72.63,2.63,2,5.12,3,7.71a19.7,19.7,0,0,1,1.52,5.51c.21,3.78-3.37,6.68-5.55,9.91-1.44,2.1-3.26,4.26-2.67,6.57A26.88,26.88,0,0,0,71.7,106c2.75,4.27,2,8.24-1.49,12.37-2.21,2.61-3.8,5.45-5.71,8.18l1.91,0,.79,0h.71l1.2,0h.63l.87,0,1.82,0c-.26-2.92,3-5,4.56-7.46a55.76,55.76,0,0,0,3.76-6.18c1.22-2.75-1.18-5.07-2.22-7.53-.73-1.74-2-3.37-2.59-5.14-1.35-4.12,1.38-7.65,4.08-11.21a25.85,25.85,0,0,0,3.58-6.5c.9-2.53-1.23-4.69-2.14-7-.68-1.75-1.86-3.38-2.39-5.15-1.23-4.12,1.31-7.64,3.79-11.19,1.54-2.26,3.72-4.51,3.28-7.2a33.15,33.15,0,0,0-2.09-6.29c-.65-1.75-1.66-3.4-2.15-5.17-1.12-4.08,1.25-7.53,3.39-11.06,1.29-2.17,2.7-4.51,2.9-6.82s-1-4.46-1.7-6.65c-.57-1.75-1.58-3.37-2-5.14ZM67.43,125.07a9.09,9.09,0,0,1,2-3Zm5-2.48c-.5.76-1,1.52-1.47,2.28A8.5,8.5,0,0,1,72.39,122.59Z"
              transform="translate(0 0)"
            />
            <path
              className="cls-3"
              d="M88.2,11.14c.47,1.77,1.5,3.39,2.09,5.14.75,2.19,2,4.46,1.8,6.65s-1.56,4.66-2.82,6.82c-2.09,3.53-4.42,7-3.25,11.06.52,1.77,1.54,3.42,2.22,5.17a34.19,34.19,0,0,1,2.17,6.29c.48,2.69-1.68,4.94-3.19,7.2-2.43,3.56-4.92,7.07-3.64,11.2.56,1.76,1.75,3.39,2.46,5.14.94,2.29,3.09,4.45,2.22,7a25,25,0,0,1-3.49,6.49c-2.65,3.56-5.34,7.09-3.94,11.22.61,1.76,1.91,3.39,2.66,5.14,1.08,2.45,3.51,4.77,2.32,7.53a55.72,55.72,0,0,1-3.68,6.17c-1.52,2.48-4.77,4.54-4.46,7.47l-2.7,0a98.42,98.42,0,0,1,5.5-8.19c3.34-4.13,4-8.1,1.17-12.37a27.79,27.79,0,0,1-3.41-7.71c-.64-2.32,1.12-4.47,2.5-6.57C82.83,88.7,86.34,85.8,86,82a20.11,20.11,0,0,0-1.66-5.52c-1-2.58-2.47-5.07-3.17-7.7-.62-2.37,1-4.57,2.34-6.73,2-3.33,5.25-6.33,4.8-10.26a21.31,21.31,0,0,0-1.47-5c-1-2.57-2.35-5-3-7.66-.59-2.36.93-4.59,2.09-6.75,1.71-3.24,4.67-6.13,4.37-9.91A22.68,22.68,0,0,0,89,17.09c-.86-2.59-2.13-5.06-2.75-7.68Z"
              transform="translate(0 0)"
            />
          </g>
        </AnimatedSmoke>
        <AnimatedWater id="flowing_water" data-name="flowing water">
          <path
            className="cls-4"
            d="M143.28,108.61s.37-5.37.37-7.54a15.84,15.84,0,0,0-11.29-.31c-16.42,5.49-20.88-4.89-38.43-3.63-7.21.52-12.84,4-21,3.38-15.7-1.24-21.76-7.68-31.23-5.79-1.1.22-7.58,3-8.58,3.32,2,8,2.42,15.89,15.26,31.46h78.69S136.56,129.5,143.28,108.61Z"
            transform="translate(0 0)"
          />
        </AnimatedWater>
        <g id="background_mask" data-name="background mask">
          <path
            className="cls-5"
            d="M80,3A77,77,0,1,1,3,80,77.08,77.08,0,0,1,80,3m0-3a80,80,0,1,0,80,80A80,80,0,0,0,80,0Z"
            transform="translate(0 0)"
          />
          <path
            className="cls-6"
            d="M80,3a77,77,0,1,0,77,77A77,77,0,0,0,80,3ZM92.87,130.5c4,7,8,6,11.5,7.5s2.5,4.5,2.5,4.5c-9.87,2.5-16.37,3-26.37,3a99.15,99.15,0,0,1-25.5-3s-1-3,2.5-4.5,7.5-.5,11.5-7.5v0a32.25,32.25,0,0,1,.3-60.57A16.63,16.63,0,0,1,66.5,67a4.15,4.15,0,0,1,1-4c1-1,2-2,.5-3.5s-2.5-2.5-2-4S68,54,68,52V19s4.5-2,12.5-2S92,19,92,19V52c0,2,1.5,2,2,3.5s-.5,2.5-2,4-.5,2.5.5,3.5a4.15,4.15,0,0,1,1,4,15,15,0,0,1-2.67,2.78,32.25,32.25,0,0,1,1.75,60.27C92.67,130.21,92.78,130.34,92.87,130.5Z"
            transform="translate(0 0)"
          />
        </g>
        <AnimatedStem id="spout_straw" data-name="spout&amp;straw">
          <g id="spout_straw" data-name="spout&amp;straw">
            <g>
              <g>
                <path
                  className="cls-3"
                  d="M90.59,107.39h-.08l-.78-.78c-.2-.38.72-2.55,1.23-3.06l13-13.37,4.71-3.2L110,88.3l-3.6,4.92L93.74,105.88a7.76,7.76,0,0,1-3.15,1.51Z"
                  transform="translate(0 0)"
                />
                <path
                  className="cls-1"
                  d="M108.58,87.49l.85.85L106.06,93,93.46,105.6A8.11,8.11,0,0,1,90.68,107l-.56-.56a6.64,6.64,0,0,1,1.13-2.61l12.93-13.33,4.4-3m.1-1-5,3.41-13,13.39c-.52.53-1.74,3.11-1.22,3.63l.8.81a.49.49,0,0,0,.34.1A8.15,8.15,0,0,0,94,106.17l12.64-12.69,3.82-5.22-1.81-1.81Z"
                  transform="translate(0 0)"
                />
              </g>
              <path
                className="cls-1"
                d="M116.53,84.28l-2-1.92-.15-.15c-.2-.2-.4-.38-.57-.54l-1.28-1.25a.41.41,0,0,0-.57,0,.4.4,0,0,0,0,.57l.17.17a7.32,7.32,0,0,0-2.22,1.63,2.44,2.44,0,0,0-.31,2.79l-1.15.26-.35.35a.8.8,0,0,0,0,1.13l1.37,1.36a.81.81,0,0,0,1.15,0l.34-.33.26-1.15A2.44,2.44,0,0,0,114,86.9a9.19,9.19,0,0,0,1.69-2.27l.24.23a.38.38,0,0,0,.28.11.42.42,0,0,0,.29-.12A.41.41,0,0,0,116.53,84.28Z"
                transform="translate(0 0)"
              />
            </g>
          </g>
        </AnimatedStem>
        <g id="bowl">
          <path
            className="cls-1"
            d="M89.25,68.93,87.9,72.71a28.3,28.3,0,1,1-17.34.69l-1-3.88a32.33,32.33,0,1,0,19.7-.59Z"
            transform="translate(0 0)"
          />
        </g>
        <AnimatedBubbles id="bubbles">
          <circle id="bub-2" className="cls-7" cx="64.46" cy="112.43" r="3" />
          <circle
            id="bub-9"
            className="cls-7"
            cx="95.22"
            cy="116.65"
            r="2.65"
          />
          <circle id="bub-3" className="cls-7" cx="67.5" cy="103.93" r="1.57" />
          <circle id="bub-1" className="cls-7" cx="59.5" cy="105.93" r="2" />
          <circle
            id="bub-10"
            className="cls-7"
            cx="99.12"
            cy="109.18"
            r="1.25"
          />
          <circle id="bub-8" className="cls-7" cx="89.87" cy="112.43" r="1.5" />
          <circle id="bub-7" className="cls-7" cx="87.15" cy="120.79" r="1.5" />
          <circle
            id="bub-12"
            className="cls-7"
            cx="87.15"
            cy="120.79"
            r="1.5"
          />
          <circle id="bub-11" className="cls-7" cx="100.37" cy="111" r="1.5" />
          <circle id="bub-4" className="cls-7" cx="73.01" cy="122.29" r="2" />
          <circle id="bub-5" className="cls-7" cx="76.17" cy="106.5" r="4.16" />
          <circle id="bub-6" className="cls-7" cx="78.15" cy="115.79" r="1.5" />
        </AnimatedBubbles>
      </StyledBongLoader>
      {loadingMessage && (
        <StyledMessage color={color} {...rest} style={{ fontSize }}>
          {loadingMessage}
        </StyledMessage>
      )}
    </Wrap>
  )
}
