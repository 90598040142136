import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <polygon
      transform="translate(15.000000, 15.000000) rotate(-180.000000) translate(-15.000000, -15.000000) "
      points="15 17.5 19.5 12.5 10.5 12.5"
    />
  </React.Fragment>,
  'DropdownCollapse'
)
